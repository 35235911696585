import { isRejectedWithValue } from "@reduxjs/toolkit";
import { setError } from "./error";
import type {
	Middleware,
	MiddlewareAPI,
	PayloadAction,
} from "@reduxjs/toolkit";

type ErrorDetail = {
	type: string;
	loc: string[];
	msg: string;
	input: string;
	url: string;
};

type ErrorPayloadDetail = {
	detail: string | ErrorDetail[];
};

type ErrorPayload = {
	data?: ErrorPayloadDetail;
	error?: string;
	status: number;
};

/**
 * Set an error state that will be picked up by the toast
 * as a global capture of any error state from RTK Query.
 */
export const rtkQueryErrorsMiddleware: Middleware =
	(api: MiddlewareAPI) => (next) => (action: unknown) => {
		if (isRejectedWithValue(action)) {
			const {
				payload: { data, error, status },
			} = action as PayloadAction<ErrorPayload>;
			
			let msg: string = "Unknown error";
			
			if (data) {
				// Handle the main API error responses.
				const { detail } = data;
				
				msg =
					typeof detail === "string"
						? detail
						: detail.map((d) => d.msg).join(", ");
			} else if (error) {
				// If RTK Query bombs, can't get to an endpoint
				// or there is some other transport layer issue.
				msg = error;
			}
			
			next(setError(`${msg}: ${status}`));
		}
		
		return next(action);
	};
