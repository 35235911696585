import { useState } from "react";
import { Grid } from "@mui/material";
import { METRICS } from "consts";
import { ChartContainer, GridContainer, SelectField } from "components";

import type { ReactNode } from "react";
import type { Metrics } from "types";

const DEFAULT_METRIC = "grossProfit";

type StyledMetrics = {
	lineId: string;
	metricsEvents?: Array<Metrics> | null;
	lineColor?: string;
};

type MetricsChartContainerProps = {
	metrics?: Array<StyledMetrics> | null;
};

const MetricsChartContainer = ({
	metrics,
}: MetricsChartContainerProps): ReactNode => {
	const [metricName, setMetricName] = useState<string>(DEFAULT_METRIC);
	
	return (
		<Grid container item direction="column" spacing={1}>
			<Grid item>
				<SelectField
					id="metrics"
					modal
					options={METRICS.map((metric) => ({
						label: metric.label,
						value: metric.name,
					}))}
					name="metrics"
					value={metricName}
					label="Metric Name"
					onChange={(e) => setMetricName(e.target.value as string)}
				/>
			</Grid>
			<GridContainer>
				<ChartContainer />
			</GridContainer>
		</Grid>
	);
};

export default MetricsChartContainer;
