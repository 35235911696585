import { styled } from "@mui/system";
import {
	InputBase,
	MenuItem,
	type MenuItemProps,
	Select,
	type SelectProps,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { InputFieldHOC } from "..";
import type { ReactNode } from "react";
import type { Typography } from "@mui/material/styles/createTypography";
import type { SelectOptionObject } from "types";

interface SelectMenuItemProps extends MenuItemProps {
	modal?: boolean;
}

export interface SelectFieldProps extends SelectProps {
	label?: string;
	id: string;
	options?: Array<SelectOptionObject>;
	modal?: boolean;
	error?: boolean;
	children?: JSX.Element[];
	endAdornment?: ReactNode;
	helperText?: string | false;
	fullWidth?: boolean;
	margin?: "dense" | "none" | undefined;
}

export const SelectMenuItem = styled(({ ...props }: SelectMenuItemProps) => {
	const { modal, ...rest } = props;
	
	return <MenuItem {...rest} />;
})(({ theme, modal }) => ({
	...(theme.typography as Typography).body1,
	color: theme.palette.text.primary,
	backgroundColor: modal
		? theme.palette.input.dark
		: theme.palette.input.main,
	"&:hover, &.Mui-selected, &.Mui-selected:hover": {
		backgroundColor: theme.palette.input.hover,
		color: theme.palette.common.white,
	},
}));

const SelectField = ({
	label,
	id,
	options,
	modal,
	helperText,
	children,
	fullWidth,
	endAdornment,
	margin,
	error,
	...rest
}: SelectFieldProps): ReactNode => {
	return (
		<InputFieldHOC
			id={id}
			modal={modal}
			helperText={helperText}
			margin={margin}
			fullWidth={fullWidth}
			label={label}
			error={error}
		>
			<Select
				{...rest}
				id={id}
				input={<InputBase />}
				variant="outlined" // This avoids an unwanted button focus style
				IconComponent={KeyboardArrowDownIcon}
			>
				{options &&
					options.map((option, idx) => (
						<SelectMenuItem
							defaultValue={undefined}
							key={idx}
							value={option.value}
							modal={modal}
						>
							{option.label}
						</SelectMenuItem>
					))}
				{children}
			</Select>
		</InputFieldHOC>
	);
};

export default SelectField;
