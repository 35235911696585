import {
	type ReactNode,
	useCallback,
	useEffect,
	useState,
} from "react";
import { Code as CodeIcon } from "@mui/icons-material";

import {
	BigCard,
	CreateScriptForm,
	Loading,
	ScriptInfo,
	ScriptsIndexTable,
} from "components";
import {
	useGetScriptsQuery,
} from "services";
import type {
	ContainerProps,
	Script,
} from "types";

const ScriptsContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	// TODO: present UI errors for all async calls once approach decided
	
	// STATE
	const [isCreating, setIsCreating] = useState(false);
	const [selectedScript, setSelectedScript] = useState<Script | null>(null);
	
	// CONTEXT & OTHER HOOKS
	const { data: scripts, isLoading } = useGetScriptsQuery();
	
	const toggleCreate = useCallback((): void => {
		setIsCreating(!isCreating);
	}, [isCreating]);
	
	useEffect(() => {
		setBreadcrumbs([{ text: "Scripts", url: "/scripts" }]);
		setHeaderActions({
			create: [
				{
					label: "Create new script",
					onClick: toggleCreate,
					createIcon: true,
				},
			],
		});
	}, [setBreadcrumbs, setHeaderActions, toggleCreate]);
	
	return (
		<>
			{isLoading && <Loading message="Loading scripts..." />}
			{scripts?.length === 0 && !isLoading && (
				<BigCard
					ctaLinkText="Create one now"
					handleClick={toggleCreate}
					icon={CodeIcon}
					message="You don't have any scripts."
				/>
			)}
			{scripts && scripts?.length > 0 && !isLoading && (
				<ScriptsIndexTable
					scripts={scripts}
					handleShowScript={(script: Script) =>
						setSelectedScript(script)
					}
				/>
			)}
			<CreateScriptForm
				show={isCreating}
				handleFormClose={toggleCreate}
			/>
			<ScriptInfo
				onClose={() => setSelectedScript(null)}
				script={selectedScript || {} as Script}
				show={!!selectedScript}
			/>
		</>
	);
};

export default ScriptsContainer;
