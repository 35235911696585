export const SOCKET = {
	ERRORS: {
		NO_EVENT_DATA: "No event data received",
	},
	EVENTS: {
		REGISTER: "register",
		UNREGISTER: "unregister",
	},
	EVENT_TYPES: {
		CLIENT: "WebSocketClientEvent",
		SERVER: "WebSocketServerEvent", // all server events are the same
	},
	SERVICES: {
		EXECUTION: "execution",
		BACKTEST: "backtest",
		TICKER_REQUEST: "ticker_request",
		EXECUTION_METADATA: "execution_metadata",
		CHART: "chart",
	},
};
