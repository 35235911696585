import { type ReactNode, useState } from "react";
import { Grid } from "@mui/material";

import { Nav } from "components";
import { AppContentHeader } from "containers";
import { default as AppContent } from "../AppContent/AppContent";
import type { Breadcrumb, HeaderActions } from "types";

const Layout = (): ReactNode => {
	const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
	const [headerActions, setHeaderActions] = useState<HeaderActions>();
	
	return (
		<Grid
			container
			alignItems="stretch"
			sx={{ height: "100%" }}
			wrap="nowrap"
		>
			<Nav />
			<Grid
				item
				container
				direction="column"
				xs
				sx={{ overflowX: "hidden" }}
			>
				<AppContentHeader
					breadcrumbs={breadcrumbs}
					headerActions={headerActions}
				/>
				<AppContent
					setBreadcrumbs={setBreadcrumbs}
					setHeaderActions={setHeaderActions}
				/>
			</Grid>
		</Grid>
	);
};

export default Layout;
