import { type ReactNode, useEffect, useState } from "react";
import { uniqueId } from "lodash";
import { Box, Grid, Typography } from "@mui/material";

import KeyValueFieldPair from "./KeyValueFieldPair";

import type { KeyValuePair, KeyValuePairError } from "types";

interface ExtendedKeyValuePair extends KeyValuePair {
	id: string;
}

type KeyValuePairFormProps = {
	errors: Array<KeyValuePairError>;
	pairs: Array<KeyValuePair>;
	onUpdatePairs: (updated: Array<KeyValuePair>) => void;
};

const KeyValuePairForm = ({
	errors,
	pairs,
	onUpdatePairs,
}: KeyValuePairFormProps): ReactNode => {
	const generateId = () => `kvp-${uniqueId()}`;
	
	const handleAddPair = (): void => {
		const updated = [...pairs, { id: generateId(), key: "", value: "" }];
		
		onUpdatePairs(updated);
	};
	
	const handleDeletePair = (id: string): void => {
		const index = kvps.findIndex((kvp) => kvp.id === id);
		const updated = [...pairs];
		
		updated.splice(index, 1);
		onUpdatePairs(updated);
	};
	
	const handleUpdatePair = (id: string, key: string, value: string) => {
		const index = kvps.findIndex((kvp) => kvp.id === id);
		const updated = [...pairs];
		
		updated.splice(index, 1, { id, key, value });
		onUpdatePairs(updated);
	};
	
	// STATE
	const [kvps, setKvps] = useState<ExtendedKeyValuePair[]>([]);
	
	useEffect(() => {
		// if no pairs, ensure always an empty one to play with
		const noIds = pairs.length > 0 ? pairs : [{ key: "", value: "" }];
		const withIds: ExtendedKeyValuePair[] = noIds.map((pair) => {
			return {
				...pair,
				id: pair?.id || generateId(),
			};
		});
		
		setKvps(withIds);
	}, [pairs]);
	
	return (
		<Box
			component="div"
			height="100%"
			width="100%"
			sx={{
				overflowX: "hidden",
				overflowY: "auto",
				scrollbarGutter: "stable",
			}}
		>
			<Grid container columnSpacing={2} mb={1}>
				<Grid item md={5}>
					<Typography variant="h5" color="text.secondary">
						Key
					</Typography>
				</Grid>
				<Grid item md={5}>
					<Typography variant="h5" color="text.secondary">
						Value
					</Typography>
				</Grid>
				<Grid item md={2}>
					<Box component="div"></Box>
				</Grid>
			</Grid>
			<Box component="div">
				{kvps.map((kvp, idx, arr) => {
					const showAddButton = idx === arr.length - 1;
					const showDeleteButton =
						arr.length > 1 || !!kvp.key || !!kvp.value;
					
					return (
						<KeyValueFieldPair
							errors={errors[idx]}
							key={kvp.id}
							id={kvp.id}
							initialKey={kvp.key}
							initialValue={kvp.value}
							onAddPair={handleAddPair}
							onDeletePair={handleDeletePair}
							onUpdatePair={handleUpdatePair}
							showAddButton={showAddButton}
							showDeleteButton={showDeleteButton}
						/>
					);
				})}
			</Box>
		</Box>
	);
};

export default KeyValuePairForm;
