import dayjs, { Dayjs, ManipulateType } from "dayjs";
import utc from "dayjs/plugin/utc";

const INVALID_DATE_STRING = "Invalid Date";

type TimePosition = "before" | "after";

type GetWrappedDate = number | string | null | Date | Dayjs;

// Add in UTC extensions to DayJs
dayjs.extend(utc);

// will save
const _getWrappedDate = (input: GetWrappedDate = null) => {
	if (!input) return dayjs();
	switch (typeof input) {
		case "number":
			return dayjs.unix(input);
		case "string":
		case "object":
			return dayjs(input);
		default:
			return dayjs();
	}
};

export const formatDateTime = (
	input: GetWrappedDate,
	timePosition: TimePosition = "before"
): string => {
	if (!input) return "N/A";
	
	const d = _getWrappedDate(input);
	
	if (!d.isValid()) {
		return INVALID_DATE_STRING;
	}
	const timeFormat =
		timePosition === "after"
			? "DD MMM YYYY [at] h:mma"
			: "h:mma on DD/MM/YY";
	
	return d.format(timeFormat);
};

export const formatDateTimeWithSeconds = (input: GetWrappedDate): string => {
	const d = _getWrappedDate(input);
	
	return d.isValid()
		? d.format("hh:mm:ssa on DD/MM/YY")
		: INVALID_DATE_STRING;
};

export const formatDate = (input: GetWrappedDate): string => {
	const d = _getWrappedDate(input);
	
	return d.isValid() ? d.format("DD MMM, YYYY") : INVALID_DATE_STRING;
};

export const getJSDate = (input: GetWrappedDate): Dayjs => {
	const d = _getWrappedDate(input);
	
	return d;
};

export const dateIsAfter = (input: GetWrappedDate, comparator: GetWrappedDate): boolean => {
	return _getWrappedDate(input).isAfter(_getWrappedDate(comparator));
};

export const getJSTimestamp = (input: GetWrappedDate): number => {
	const d = _getWrappedDate(input).valueOf();
	
	return d;
};

export const getUnixTimestamp = (input: GetWrappedDate): number => {
	const d = _getWrappedDate(input);
	
	return d.unix();
};

export const getISOString = (input: GetWrappedDate): string => {
	return _getWrappedDate(input).toISOString();
};

export const getFormattedDate = (input: GetWrappedDate, format: string): string => {
	const d = _getWrappedDate(input).format(format);
	
	return d;
};

export const getYear = (input: GetWrappedDate): number => {
	const d = _getWrappedDate(input).get("year");
	
	return d;
};

export const getNow = (): number => {
	const d = _getWrappedDate();
	
	return d.unix();
};

export const getPreviousDateTime = (value: number, frame: ManipulateType | undefined): number => {
	const d = _getWrappedDate();
	
	return d.subtract(value, frame).unix();
};

// Gives a consistent approach location for getting seconds.
export const toSeconds = (ms: number): number => ms / 1000;

// Returns the timestamp rounded to midnight on that "day"
export const getDayTimestamp = (timestamp: number): number => {
	return dayjs.unix(timestamp).utc().startOf("day").unix();
};
