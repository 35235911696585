import { Grid, IconButton, Typography } from "@mui/material";
import { Fullscreen as FullscreenIcon } from "@mui/icons-material";
import { ChartContainer } from "components";
import { fetchQueryData } from "services";
import { formatDateTime } from "utils";
import {
	AggLevelType,
	type QueryResults,
} from "types";
import type { Dispatch, SetStateAction } from "react";

export default function TrendChart({
	queryResults,
	thumbnail = false,
	showAxis = false,
	showModal = null,
	setQuerySymbol = null,
}: {
	queryResults: QueryResults;
	thumbnail?: boolean;
	showAxis?: boolean;
	showModal?: Dispatch<SetStateAction<boolean>> | null;
	setQuerySymbol?: Dispatch<SetStateAction<string[]>>| null;
}) {
	return (
		<>
			<Grid
				container
				direction="row"
				justifyContent="space-evenly"
				alignItems="stretch"
				gap={6}
				pb={6}
			>
				{Object.keys(queryResults).map((key) =>
					Object.keys(queryResults[key]).map((agg, _, arr) => {
						const { mints, maxts } = queryResults[key][agg];
						
						return (
							<Grid
								item
								key={`${key}_${agg}`}
								sm={12}
								md={arr.length === 1 ? 12 : 3}
							>
								<Grid container direction="column">
									<Grid item height="40vh">
										<ChartContainer
											aggLevel={agg as AggLevelType}
											fetcher={
												async(
													mints: number,
													maxts: number
												) => fetchQueryData(mints, maxts, queryResults[key][agg])
											}
											mints={mints}
											maxts={maxts}
											axis={{
												bottom: { enabled: showAxis, timestamps: showAxis },
												right: { enabled: showAxis, timestamps: showAxis },
											}}
										/>
									</Grid>
									{thumbnail && showModal && setQuerySymbol && (
										<Grid
											item
											container
											wrap="nowrap"
										>
											<Grid
												container
												item
												xs={11}
												justifyContent="space-between"
												alignItems="center"
											>
												<Typography color="text.secondary" width="min">
													{formatDateTime(
														queryResults[key][agg].mints, "after"
													)} ({key} - {agg}s)
												</Typography>
												<IconButton
													onClick={() => {
														showModal(true);
														setQuerySymbol([key, agg]);
													}}
													edge="end"
												>
													<FullscreenIcon sx={{ color: "text.secondary" }} />
												</IconButton>
											</Grid>
											<Grid item xs={1} />
										</Grid>
									)}
								</Grid>
							</Grid>
						);
					}))}
			</Grid>
		</>
	);
}