import { api } from ".";
import type { MarketHour, MarketHoursRequest } from "types";

export const markets = api
	.enhanceEndpoints({
		addTagTypes: ["market_hours"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getMarketHours: build.query<MarketHour[], MarketHoursRequest>({
				query({ tsFrom, tsTo }: MarketHoursRequest) {
					return {
						url: "/market_hours",
						method: "GET",
						params: {
							tsFrom,
							tsTo,
						},
					};
				},
				providesTags: ["market_hours"],
			}),
		}),
	});

export const { useGetMarketHoursQuery } = markets;
