import { Dispatch, SetStateAction } from "react";

import { ChartContainer, Modal } from "components";
import { getFormattedDate } from "utils";
import { fetchQueryData } from "services";
import { AggLevelType, QueryResults } from "types";
import { Button, Grid } from "@mui/material";

interface Props {
	handleClose: Dispatch<SetStateAction<boolean>>;
	queryId: string;
	queryResults: QueryResults;
	querySymbol: string[];
	show: boolean;
};

export default function ShowTrendModal({
	handleClose,
	queryId,
	queryResults,
	querySymbol,
	show,
}: Props) {
	const [symbol, agg] = querySymbol;
	
	if (!symbol || !agg) return;
	
	const data = queryResults[symbol][agg];
	
	return (
		<Modal
			title={`Historic match on ${getFormattedDate(data.mints, "DD MMM YYYY HH:mm::ss")}`}
			handleClose={() => handleClose(false)}
			show={show}
			size="xl"
			dynamicTitle={
				<Button
					href={`/sql/${queryId}?symbol=${symbol}&agg=${agg}`}
					variant="outlined"
					color="outline"
				>
					Open in new tab
				</Button>
			}
		>
			<Grid container height="80vh">
				<ChartContainer
					aggLevel={agg as AggLevelType}
					fetcher={
						async(mints: number, maxts: number) => fetchQueryData(mints, maxts, data)
					}
					mints={data.mints}
					maxts={data.maxts}
				/>
			</Grid>
		</Modal>
	);
}