import { type ReactNode, useState } from "react";
import { useFormik } from "formik";

import { useCreateKeyMutation } from "services";
import { Alert, Modal, SelectField, TextField } from "components";
import { SelectChangeEvent } from "@mui/material";
import { CreateProviderKeyFormSchema } from "./CreateProviderKeyForm.schema";

import type { CreateProviderKeyRequest, Provider, ProviderId } from "types";

const FORM_INITIAL_STATE = {
	providerId: "" as ProviderId,
	name: "",
	apiKey: "",
} as CreateProviderKeyRequest;

type CreateProviderKeyFormProps = {
	handleFormClose: () => void;
	providers: Array<Provider>;
	show: boolean;
};

const CreateProviderKeyForm = ({
	handleFormClose,
	providers,
	show,
}: CreateProviderKeyFormProps): ReactNode => {
	const [formError, setFormError] = useState<string>("");
	const [createKey, { isLoading }] = useCreateKeyMutation();
	
	const handleFormSubmit = (form: CreateProviderKeyRequest) => {
		createKey(form)
			.unwrap()
			.then(() => handleFormClose())
			.catch(e => setFormError(e.message));
	};
	
	const generateKeyName = (e: SelectChangeEvent<unknown>): string => {
		const providerId = e.target.value;
		const provider = providers.find(p => p.providerId === providerId);
		
		return `My ${provider?.providerName || "PROVIDER"} key`;
	};
	
	const generateKeyPlaceholder = (providerId: ProviderId): string => {
		const provider = providers.find(p => p.providerId === providerId);
		
		return `Paste the key from ${provider?.providerName || "PROVIDER"} here...`;
	};
	
	const formik = useFormik({
		initialValues: FORM_INITIAL_STATE,
		validationSchema: CreateProviderKeyFormSchema,
		onSubmit: handleFormSubmit,
		validateOnChange: true,
	});
	
	return (
		<Modal
			title="Create a new key"
			handleClose={handleFormClose}
			show={show}
			onSave={formik.handleSubmit}
			saveButtonText="Create"
			isValid={formik.dirty && formik.isValid}
			isSubmitting={isLoading}
		>
			{formError && <Alert>{formError}</Alert>}
			<form onSubmit={formik.handleSubmit} className="create-user-key__form">
				<SelectField
					id="providerId"
					modal
					options={providers.map(provider => ({
						label: provider.providerName,
						value: provider.providerId,
					}))}
					name="providerId"
					label="Trading Platform"
					onChange={e => {
						formik.setFieldValue("name", generateKeyName(e));
						formik.handleChange(e);
					}}
					value={formik.values.providerId}
				/>
				{formik.values.providerId && (
					<>
						<TextField
							modal
							id="name"
							name="name"
							label="Key name"
							placeholder="Enter a name to identify your key..."
							value={formik.values.name}
							onChange={formik.handleChange}
							error={formik.touched.name && Boolean(formik.errors.name)}
							helperText={formik.touched.name && formik.errors.name}
						/>
						<TextField
							modal
							id="apiKey"
							name="apiKey"
							label="API key"
							placeholder={generateKeyPlaceholder(formik.values.providerId)}
							value={formik.values.apiKey}
							onChange={formik.handleChange}
							error={formik.touched.apiKey && Boolean(formik.errors.apiKey)}
							helperText={formik.touched.apiKey && formik.errors.apiKey}
						/>
					</>
				)}
			</form>
		</Modal>
	);
};

export default CreateProviderKeyForm;
