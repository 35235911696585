import { api } from ".";
import type { BrokerAnnotations, BrokerOrders } from "types";

export const broker = api
	.enhanceEndpoints({
		addTagTypes: ["orders", "order_annotations"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getBrokerOrders: build.query<BrokerOrders, string>({
				query(accountId: string) {
					return {
						url: `/brokers/${accountId}/orders`,
						method: "GET",
					};
				},
				providesTags: ["orders"],
			}),
			getBrokerOrderAnnotations: build.query<BrokerAnnotations, string>({
				query(accountId: string) {
					return {
						url: `/brokers/${accountId}/orders?format=ANNOTATION`,
						method: "GET",
					};
				},
				providesTags: ["order_annotations"],
			}),
		}),
	});

export const {
	useLazyGetBrokerOrdersQuery,
	useLazyGetBrokerOrderAnnotationsQuery,
} = broker;
