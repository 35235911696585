import {
	type Dispatch,
	type MutableRefObject,
	type ReactNode,
	type SetStateAction,
	Suspense,
	useEffect,
	useRef,
} from "react";
import { Canvas } from "@react-three/fiber";
import { ChartCamera, ChartObjects } from "components";
import { OrbitControls } from "@react-three/drei";
import { MOUSE, TOUCH } from "three";
import { useAppSelector } from "hooks";
import { selectRotation } from "store";
import { CrossHairs, GridLines } from "../helpers";
import type { OrbitControls as OrbitControlsImpl } from "three-stdlib";
import type { WebGLRenderer } from "three";
import type {
	ChartData,
	ChartPos,
	MousePos,
} from "types";

type ChartProps = {
	cmds: ChartData[],
	mousePos?: MousePos,
	setMousePos: Dispatch<SetStateAction<MousePos | undefined>>,
	chartPos: MutableRefObject<ChartPos>,
	maxXSteps: number,
	maxYSteps: number,
};

const Chart = ({
	cmds,
	mousePos,
	setMousePos,
	chartPos,
	maxXSteps,
	maxYSteps,
}: ChartProps): ReactNode => {
	const camRef = useRef();
	const orbitalRef = useRef<OrbitControlsImpl>(null);
	const glRef = useRef<WebGLRenderer | null>(null);
	const rotation = useAppSelector<boolean>(selectRotation);
	
	// This helps us claw back memory that's been allocated to WebGl instance,
	// and ensures the context of the canvas is never picked up again by
	// re-rendering a canvas component by a return to the same implementation.
	useEffect(() => {
		return () => {
			const gl = glRef.current;
			
			if (!gl) return;
			
			gl.dispose();
		};
	}, []);
	
	return (
		<Canvas
			frameloop="demand"
			onMouseMove={(e) => setMousePos(e.nativeEvent as MousePos)}
			onWheel={(e) => setMousePos(e.nativeEvent as any)}
			raycaster={{ params: { Line: { threshold: 5 }, Mesh: {}, LOD: {}, Sprite: {}, Points: { threshold: 1 } } }}
			style={{ position: "absolute", zIndex: 10 }}
			onCreated={({ gl }) => {
				glRef.current = gl;
			}}
		>
			<ChartCamera
				camRef={camRef}
				chartPos={chartPos}
				orbitalRef={orbitalRef}
			/>
			<directionalLight position={[0, 2, 10]} intensity={0.35} castShadow />
			<ambientLight intensity={0.3} castShadow />
			<Suspense fallback={null}>
				<CrossHairs />
				<ChartObjects cmds={cmds} chartPos={chartPos} />
				<GridLines chartPos={chartPos} maxXSteps={maxXSteps} maxYSteps={maxYSteps} />
			</Suspense>
			<OrbitControls
				ref={orbitalRef}
				mouseButtons={{
					LEFT: MOUSE.PAN,
					MIDDLE: MOUSE.MIDDLE,
					RIGHT: MOUSE.DOLLY,
				}}
				touches={{
					ONE: TOUCH.PAN,
					TWO: TOUCH.PAN,
				}}
				keys={{
					LEFT: "ArrowLeft",
					UP: "ArrowUp",
					RIGHT: "ArrowRight",
					BOTTOM: "ArrowDown",
				}}
				enableDamping={false}
				dampingFactor={0}
				enableRotate={rotation}
				autoRotate={false}
			/>
		</Canvas>
	);
};

export default Chart;
