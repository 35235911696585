import { FontLoader, TextGeometry } from "three-stdlib";
import { useLoader, useThree } from "@react-three/fiber";
import { CHART_OBJECT_DEFAULTS } from "consts";
import { MeshBasicMaterial } from "three";
import { type ReactNode } from "react";
import { calcX, calcY } from "../helpers";
import type { ChartAnnotationObject, ScaleFactor } from "types";

const BASE_SCALE = 1;

const Annotation = ({
	annotation,
	scaleFactor,
	autoScale=true,
}: {
	annotation: ChartAnnotationObject;
	scaleFactor: ScaleFactor;
	autoScale?: boolean,
}): ReactNode => {
	const zoom = useThree((state) => state.camera.zoom);
	const adjustedScale = BASE_SCALE / Math.log(zoom + 1);
	
	const {
		font,
		fontSize,
		borderWidth,
		borderColor,
		color,
	} = {
		...CHART_OBJECT_DEFAULTS.style,
		...annotation.style,
	};
	
	const loadedFont = useLoader(FontLoader, font);
	
	const textGeometry = new TextGeometry(annotation.text, {
		font: loadedFont,
		size: fontSize,
		height: 10,
		curveSegments: 3,
		bevelThickness: 1,
		bevelSize: borderWidth,
		bevelEnabled: borderWidth > 0,
	});
	
	const materialBackFront = new MeshBasicMaterial({
		color: color,
	});
	
	// If there is a border we'll set a border colour for the sides, else we'll keep it the same colour as the front
	const materialSide = new MeshBasicMaterial({
		color: borderWidth > 0 ? borderColor : color,
	});
	
	return (
		<group
			position={[
				calcX(annotation.dtTo(), scaleFactor),
				calcY(annotation.position.y, scaleFactor),
				annotation.position.z ?? 10,
			]}
			rotation={[
				annotation?.rotation?.x ?? CHART_OBJECT_DEFAULTS.rotation.x,
				annotation?.rotation?.y ?? CHART_OBJECT_DEFAULTS.rotation.y,
				annotation?.rotation?.z ?? CHART_OBJECT_DEFAULTS.rotation.z,
			]}
			// If autoScale, then adjust the font size to keep it consistent. Like with city names on a map
			{...(autoScale ? {
				scale: [
					adjustedScale,
					adjustedScale,
					1,
				],
			} : {})}
		>
			<mesh
				key={annotation.objId}
				geometry={textGeometry}
				material={[ materialBackFront, materialSide ]}
			/>
		</group>
	);
};

export default Annotation;
