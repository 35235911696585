import { api } from ".";
import type { Metrics, MetricsList } from "types";

export const metrics = api
	.enhanceEndpoints({
		addTagTypes: ["metrics"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getLatestMetrics: build.query<Metrics, string>({
				query(brokerAccountId: string) {
					return {
						url: `/metrics/${brokerAccountId}/latest`,
						method: "GET",
					};
				},
				providesTags: ["metrics"],
			}),
			getListMetrics: build.query<Metrics[], string>({
				query(brokerAccountId: string) {
					return {
						url: `/metrics/${brokerAccountId}`,
						method: "GET",
					};
				},
				transformResponse: (response: MetricsList) => response.metrics,
			}),
		}),
	});

export const {
	useGetLatestMetricsQuery,
	useGetListMetricsQuery,
	useLazyGetListMetricsQuery,
} = metrics;
