import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "store";
import type { ReactNode } from "react";

export const ReduxProvider = ({ children }: { children?: ReactNode }) => {
	return (
		<PersistGate loading={null} persistor={persistor}>
			<Provider store={store}>{children}</Provider>
		</PersistGate>
	);
};
