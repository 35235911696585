import { InputAdornment, InputBase, type InputBaseProps } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { InputFieldHOC } from "..";

import type { ReactNode } from "react";

export interface TextFieldProps {
	label?: string;
	id: string;
	modal?: boolean;
	error?: boolean;
	helperText?: string | false;
	InputProps?: Object;
	endAdornment?: ReactNode;
	fullWidth?: boolean;
	margin?: "dense" | "none";
}

const TextField = ({
	label,
	id,
	modal = false,
	error,
	helperText,
	InputProps,
	endAdornment,
	fullWidth = true,
	margin,
	...rest
}: TextFieldProps & InputBaseProps): ReactNode => {
	return (
		<InputFieldHOC
			id={id}
			modal={modal}
			helperText={helperText}
			margin={margin}
			fullWidth={fullWidth}
			label={label}
			error={error}
		>
			<InputBase
				{...rest}
				id={id}
				error={error}
				endAdornment={
					(error && (
						<InputAdornment
							sx={(theme) => ({
								color:
									theme.palette.mode === "light"
										? "error.main"
										: "inherit",
							})}
							position="end"
						>
							<ClearIcon />
						</InputAdornment>
					)) ||
					(endAdornment && endAdornment)
				}
				{...InputProps}
			/>
		</InputFieldHOC>
	);
};

export default TextField;
