import { styled } from "@mui/system";

import { Typography } from "@mui/material/styles/createTypography";
import type { SvgIconComponent } from "@mui/icons-material";
import type { ReactNode } from "react";
import type { PillColor, PillVariant } from "types";

type PillComponentProps = {
	color?: PillColor;
	variant?: PillVariant;
	table?: boolean;
};

const PillComponent = styled("div", {
	shouldForwardProp: (prop: string) =>
		!["color", "variant", "table", "sx"].includes(prop),
	name: "Pill",
	slot: "Root",
	overridesResolver: (props, styles) => [styles.root, styles[props.color]],
})<PillComponentProps>(({ theme, table }) => ({
	...(theme.typography as Typography)[table ? "h6" : "h5"],
	lineHeight: 1,
	display: "inline-flex",
	justifyContent: "center",
	alignItems: "center",
	whiteSpace: "nowrap",
	padding: table
		? `${theme.spacing(0.5)} ${theme.spacing(1)}`
		: `${theme.spacing(0.5)} ${theme.spacing(2)} ${theme.spacing(
			0.5
		)} ${theme.spacing(1.5)}`,
	height: table ? theme.spacing(2.75) : theme.spacing(3.5),
	borderRadius: theme.shape.borderRadius,
	"& .MuiSvgIcon-root": {
		marginRight: theme.spacing(0.5),
	},
}));

type PillProps = {
	text: string;
	color?: PillColor;
	variant?: PillVariant;
	icon?: SvgIconComponent;
	table?: boolean;
};

const defaultProps = {
	color: "primary",
	table: false,
};

const Pill = ({ text, color, variant, icon, table }: PillProps): ReactNode => {
	const Icon = icon;
	
	return (
		<PillComponent color={color} variant={variant} table={table}>
			{Icon && <Icon fontSize={table ? "small" : "medium"} />}
			{text}
		</PillComponent>
	);
};

Pill.defaultProps = defaultProps;

export default Pill;
