import { useNavigate } from "react-router-dom";
import { Button, Paper } from "@mui/material";
import { Visibility as EyeIcon, Edit as PencilIcon } from "@mui/icons-material";

import { Loading, MetaDataTable, Modal, Pill, Table } from "components";
import { formatDateTime } from "utils";
import { useGetVersionsQuery } from "services";

import type { ReactNode } from "react";
import type { Script, ScriptVersion } from "types";

type ScriptInfoProps = {
	onClose: () => void;
	script: Script;
	show: boolean;
};

interface TableScriptVersion extends Omit<ScriptVersion, "status" | "action"> {
	status: JSX.Element;
	action: JSX.Element;
}

const ScriptInfo = ({
	onClose,
	script,
	show,
}: ScriptInfoProps): ReactNode => {
	const { versionsWithDraft, isLoading: isLoadingVersions } = useGetVersionsQuery(script.scriptId, {
		skip: !script?.scriptId,
		selectFromResult: ({ data, isLoading }) => {
			
			const sortedVersions = [...data?.versions ? data?.versions : []].sort((a, b) => {
				return a.committedAt < b.committedAt ? 1 : -1;
			});
			
			return {
				isLoading,
				versionsWithDraft: sortedVersions ? [
					{ version: sortedVersions[0]?.version + 1 || 1 },
					...sortedVersions,
				] : [],
			};
		},
	});
	
	const handleOpenCodeEditor = (
		readOnly: boolean,
		selectedVersion?:
		| ScriptVersion
		| {
			version: number;
			  }
		| null
	): void => {
		const selectedVersionId = selectedVersion?.version || null;
		const url =
			selectedVersionId && readOnly
				? `/scripts/${script.scriptId}/version/${selectedVersionId}/view`
				: `./${script.scriptId}/edit`;
		
		navigate(url, { replace: false });
	};
	
	// CONTEXT & OTHER HOOKS
	const navigate = useNavigate();
	
	return (
		<Modal
			title="Viewing"
			dynamicTitle={script.name}
			handleClose={onClose}
			show={show}
			size="md"
		>
			{isLoadingVersions && (
				<Loading message="Loading versions..." spinnerSize={60} />
			)}
			{!isLoadingVersions && (
				<Paper
					elevation={0}
					sx={{
						width: "100%",
						overflow: "hidden",
						backgroundColor: "transparent",
						"& .MuiTableCell-root": {
							borderWidth: 2,
							borderColor: "text.secondary",
						},
					}}
				>
					<Table<TableScriptVersion>
						height={200}
						isModal
						getRowId={(row: TableScriptVersion) => row.commitSHA || row.version}
						columns={[
							{
								id: "version",
								label: "Version",
								minWidth: 170,
								mapper: (row: TableScriptVersion) =>
									row.version,
							},
							{
								id: "status",
								label: "Status",
								minWidth: 170,
								mapper: (row: TableScriptVersion) => row.status,
							},
							{
								id: "action",
								label: "Action",
								minWidth: 170,
								align: "right",
								mapper: (row: TableScriptVersion) => row.action,
							},
						]}
						rows={versionsWithDraft.map((version, id) => ({
							...(version as ScriptVersion),
							status: (
								<Pill
									text={id === 0 ? "Draft" : "Published"}
									color={id === 0 ? "primary" : "success"}
									variant={id === 0 ? "outlined" : undefined}
									table
								/>
							),
							action: (
								<Button
									endIcon={
										id === 0 ? <PencilIcon /> : <EyeIcon />
									}
									onClick={
										id === 0
											? () =>
												handleOpenCodeEditor(
													false,
													null
												)
											: () =>
												handleOpenCodeEditor(
													true,
													version
												)
									}
									variant="text"
								>
									{id === 0 ? "Edit draft" : "View version"}
								</Button>
							),
						}))}
					/>
				</Paper>
			)}
			<MetaDataTable<Script>
				title={null}
				colSpan={6}
				metadata={script}
				dataToShow={[
					{ field: "description", headerName: "Description" },
					{
						field: "createdAt",
						headerName: "Created at",
						valueFormatter: (value) =>
							formatDateTime(value as Script["createdAt"]),
					},
					{
						field: "lastEditedAt",
						headerName: "Last edited",
						valueFormatter: (value) =>
							formatDateTime(value as Script["lastEditedAt"]),
					},
				]}
			/>
		</Modal>
	);
};

export default ScriptInfo;
