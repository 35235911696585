import { type ReactNode, useEffect } from "react";
import { OrthographicCamera } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectRotation, selectScaleY, setScaleFactor } from "store";
import { calculateScaleFactor, setXAxisOffsets } from ".";
import type { ChartCameraProps } from "types";

export const ChartCamera = ({
	camRef,
	chartPos,
	orbitalRef,
}: ChartCameraProps): ReactNode => {
	const dispatch = useAppDispatch();
	
	const camRefCurrent = camRef?.current;
	const orbitalRefCurrent = orbitalRef?.current;
	
	// Gets the current rotation enable/disable - and gives
	// us something to allow a "reset" to trigger an update.
	const rotation = useAppSelector<boolean>(selectRotation);
	const scaleY = useAppSelector<boolean>(selectScaleY);
	const { invalidate } = useThree();
	
	// We have two sets of widths/heights here, we have the viewport as
	// it exists in user space (pixels in the view), and what
	// we have relative to the camera frustum - i.e. what happens
	// to the view when it's zoomed in and out. Size is the
	// former, and viewport is the latter.
	const sWidth = useThree((state) => state.size.width);
	const sHeight = useThree((state) => state.size.height);
	const vWidth = useThree((state) => state.viewport.width);
	const vHeight = useThree((state) => state.viewport.height);
	
	const zoom = useThree((state) => state.camera.zoom);
	const x = useThree((state) => state.camera.position.x);
	const y = useThree((state) => state.camera.position.y);
	
	// This keeps us safe from recalculations, subscribing only to what
	// we want to listen to above, it means that we don't get asked to
	// do expensive calculations on every frame render.
	useEffect(() => {
		const {
			minX,
			maxX,
			minY,
			maxY,
			x: prevX,
			y: prevY,
			zoom: prevZoom,
		} = chartPos.current;
		
		// Calculate and update the window onto the data, here
		// we will calculate the deltas of what the user is doing
		// at any one time in regards their position on the
		// timeline, and up/down pan position.
		const deltaX = x - prevX;
		const deltaY = y - prevY;
		const centerX = (minX + maxX) / 2;
		const centerY = (minY + maxY) / 2;
		
		// Calculate new range based on zoom, initial load, the
		// range will be what we get, if we have zoomed, we
		// need to check this changed and re-calc.
		let rangeX = maxX - minX;
		let rangeY = maxY - minY;
		
		if (prevZoom !== 0) {
			rangeX = rangeX * (prevZoom / zoom);
			rangeY = rangeY * (prevZoom / zoom);
		}
		
		// Calculate new shift x/y values
		const shiftX = (deltaX / vWidth) * rangeX;
		const shiftY = (deltaY / vHeight) * rangeY;
		
		const halfRangeX = rangeX / 2;
		const halfRangeY = rangeY / 2;
		
		// Updating these will tell other components that the window
		// the user is looking at has moved so that they can update
		// accordingly.
		chartPos.current.minX = centerX - halfRangeX + shiftX;
		chartPos.current.maxX = centerX + halfRangeX + shiftX;
		chartPos.current.minY = centerY - halfRangeY + shiftY;
		chartPos.current.maxY = centerY + halfRangeY + shiftY;
		
		// Update general properties
		chartPos.current.width = sWidth;
		chartPos.current.vWidth = vWidth;
		chartPos.current.height = sHeight;
		chartPos.current.zoom = zoom;
		chartPos.current.x = x;
		chartPos.current.y = y;
		
		// Work out any offsets on the x axis as the camera moves.
		setXAxisOffsets(chartPos);
	}, [sWidth, sHeight, vWidth, vHeight, x, y, zoom, chartPos]);
	
	// This allows us to reset the camera when rotation is turned off
	// by the user. At the moment, we do return the user back to the
	// centre of the first window - which is probably not that good.
	// The question here, with this kind of chart data, how often
	// are users going to use "rotation"?
	useEffect(() => {
		if (!camRefCurrent || !orbitalRefCurrent || rotation) return;
		
		camRefCurrent.rotation.set(0, 0, 0);
		camRefCurrent.position.set(0, 0, 10);
		camRefCurrent.zoom = 1;
		
		camRefCurrent.updateProjectionMatrix();
		
		orbitalRefCurrent.target.set(0, 0, 0);
		orbitalRefCurrent.update();
		
		invalidate();
	}, [rotation, camRefCurrent, orbitalRefCurrent, invalidate]);
	
	// We use this to re-center the camera after a scaleY call has
	// been made, i.e. the calculations have been done, so it's
	// therefore safe to move.
	useEffect(() => {
		if (!camRefCurrent || !orbitalRefCurrent || scaleY) return;
		
		camRefCurrent.position.setY(0);
		camRefCurrent.updateProjectionMatrix();
		
		orbitalRefCurrent.target.setY(0);
		orbitalRefCurrent.update();
	}, [scaleY, camRefCurrent, orbitalRefCurrent]);
	
	// If the height or width change, we need to recalculate the
	// normalisation ScaleFactor so that everything can be updated.
	useEffect(() => {
		const { minY, maxY } = chartPos.current;
		
		if (!minY || !maxY) return;
		
		const scaleFactor = calculateScaleFactor(chartPos);
		
		if (scaleFactor) dispatch(setScaleFactor(scaleFactor));
	}, [sHeight, sWidth, chartPos, dispatch]);
	
	return (
		<OrthographicCamera // Ref: https://threejs.org/docs/#api/en/cameras/OrthographicCamera
			makeDefault
			ref={camRef}
			// Define a viewing frustum: https://en.wikipedia.org/wiki/Viewing_frustum, To prevent
			// clipping during rotation, we set the frustum length to match the viewport width.
			near={Math.floor((sWidth / 2) * -1)}
			far={Math.ceil(sWidth / 2)}
		/>
	);
};

export default ChartCamera;
