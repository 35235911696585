import { type ReactNode, createContext, useCallback } from "react";
import { useErrorHandler } from "react-error-boundary";

type ErrorProviderProps = {
	children?: ReactNode;
};

export interface ErrorContextValue {
	showErrorCard: (errorMessage: string) => void;
	showErrorToast: (errorMessage: string) => void;
}

export const ErrorContext = createContext<ErrorContextValue | undefined>(
	undefined
);

export function ErrorProvider({ children }: ErrorProviderProps): ReactNode {
	const propagateErrorToBoundary = useErrorHandler();
	
	const showErrorCard = useCallback(
		(errorMessage: string) => {
			propagateErrorToBoundary(errorMessage);
		},
		[propagateErrorToBoundary]
	);
	
	const showErrorToast = (errorMessage: string) => {
		console.log("DEPRECATED");
	};
	
	return (
		<ErrorContext.Provider value={{ showErrorCard, showErrorToast }}>
			{children}
		</ErrorContext.Provider>
	);
}
