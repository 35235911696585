import { type ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import { Box } from "@mui/material";

import { BigCard } from "components";
import { isDevelopment } from "utils";

type ErrorBoundaryProps = {
	children?: ReactNode | ReactNode[];
};

const ErrorBoundary = ({ children }: ErrorBoundaryProps): ReactNode => {
	const handleError = (
		error: Error,
		errorInfo: {
			componentStack: string;
		}
	) => {
		let msg = error.message;
		
		msg += isDevelopment() ? `\n\n${errorInfo.componentStack}` : "";
		setErrorMessage(msg);
	};
	
	const handleReset = () => errorMessage && setErrorMessage(null);
	
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	// Grab pathname and pass as key to ReactErrorProvider -
	// if it changes, react-error-boundary will re-render "happy path"
	// component tree
	const { pathname } = useLocation();
	
	return (
		<ReactErrorBoundary
			key={pathname}
			fallbackRender={({ error, resetErrorBoundary }) => {
				// an uncaught Error will be an object, whereas we
				// invoke setErrorCard by passing a string
				const msg = typeof error === "string" ? error : error.message;
				
				return (
					<Box component="div" p={4}>
						<BigCard
							ctaLinkText="Retry"
							handleClick={resetErrorBoundary}
							icon={ErrorIcon}
							message={msg}
						/>
					</Box>
				);
			}}
			onError={handleError}
			onReset={handleReset}
		>
			{children}
		</ReactErrorBoundary>
	);
};

export default ErrorBoundary;
