import { ChangeEvent, type ReactNode, useState } from "react";
import { Grid, IconButton, Stack } from "@mui/material";
import PlusIcon from "@mui/icons-material/AddCircleOutlineRounded";
import MinusIcon from "@mui/icons-material/RemoveCircleOutlineRounded";

import { TextField } from "components";
import { escapeWhitespace } from "utils";

import type { KeyValuePairError } from "types";

type KeyValueFieldPairProps = {
	errors: KeyValuePairError;
	id: string;
	initialKey: string;
	initialValue: string;
	onAddPair: () => void;
	onDeletePair: (id: string) => void;
	onUpdatePair: (id: string, key: string, value: string) => void;
	showAddButton: boolean;
	showDeleteButton: boolean;
};

const KeyValueFieldPair = ({
	errors,
	id,
	initialKey,
	initialValue,
	onAddPair,
	onDeletePair,
	onUpdatePair,
	showAddButton,
	showDeleteButton,
}: KeyValueFieldPairProps): ReactNode => {
	const handleKeyChanged = (e: ChangeEvent<HTMLInputElement>): void => {
		setCurrentKey(escapeWhitespace(e.currentTarget.value).toUpperCase());
	};
	
	const handleValueChanged = (e: ChangeEvent<HTMLInputElement>): void => {
		setCurrentValue(e.currentTarget.value);
	};
	
	const handleFieldBlurred = (): void => {
		onUpdatePair(id, currentKey, currentValue);
	};
	
	// STATE
	const [currentKey, setCurrentKey] = useState(initialKey);
	const [currentValue, setCurrentValue] = useState(initialValue);
	
	const fieldErrors = errors || {};
	const keyFieldId = `${id}-key`;
	const valueFieldId = `${id}-value`;
	// NB the " " passed to the helperText of TextField is required to prevent
	// misalignment when only one field has an error
	// https://mui.com/material-ui/react-text-field/#helper-text
	const keyErrorText = fieldErrors.key || " ";
	const valueErrorText = fieldErrors.value || " ";
	
	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="flex-start"
			columnSpacing={2}
			flexWrap="nowrap"
			id={id}
		>
			<Grid item md={5}>
				<TextField
					id={keyFieldId}
					error={!!fieldErrors.key}
					helperText={keyErrorText}
					name={keyFieldId}
					onBlur={handleFieldBlurred}
					onChange={handleKeyChanged}
					value={currentKey}
					margin="none"
				/>
			</Grid>
			<Grid item md={5}>
				<TextField
					id={valueFieldId}
					error={!!fieldErrors.value}
					helperText={valueErrorText}
					name={valueFieldId}
					onBlur={handleFieldBlurred}
					onChange={handleValueChanged}
					value={currentValue}
					margin="none"
				/>
			</Grid>
			<Grid item md={2}>
				<Stack direction="row" spacing={1}>
					{showDeleteButton && (
						<IconButton
							aria-label="Remove this key-value pair from run parameters"
							color="error"
							onClick={() => onDeletePair(id)}
							size="small"
						>
							<MinusIcon fontSize="inherit" />
						</IconButton>
					)}
					{showAddButton && (
						<IconButton
							aria-label="Add a new key-value pair to run parameters"
							color="success"
							onClick={onAddPair}
							size="small"
						>
							<PlusIcon fontSize="inherit" />
						</IconButton>
					)}
				</Stack>
			</Grid>
		</Grid>
	);
};

export default KeyValueFieldPair;
