import { DataGrid } from "@mui/x-data-grid";
import { MoreHoriz } from "@mui/icons-material";

import {
	SelectField,
	type SelectFieldProps,
	TextField,
	type TextFieldProps,
} from "components";
import Pagination from "../Pagination/Pagination";
import { SelectMenuItem } from "../../controls/SelectField/SelectField";
import DataTableToolbar from "./DataTableToolbar";
import DataTableFilterPanel from "./DataTableFilterPanel";

import type {
	DataGridProps,
	GridColDef,
	GridValidRowModel,
} from "@mui/x-data-grid";
import type { ToolbarAction } from "./DataTableToolbar";
import type { ReactNode, RefAttributes } from "react";

type DataTableProps = {
	columns: GridColDef[];
	rows: Array<GridValidRowModel>;
	toolbarActions?: Array<ToolbarAction>;
	search?: boolean;
	filter?: boolean;
	hideFooter?: boolean;
};

const defaultProps = {
	toolbarActions: [],
	search: true,
	filter: true,
	hideFooter: true,
};

const LargeMoreHoriz = () => <MoreHoriz />;

const DataTable = ({
	columns,
	rows,
	toolbarActions,
	search,
	filter,
	hideFooter,
	...rest
}: DataTableProps &
DataGridProps &
RefAttributes<HTMLDivElement>): ReactNode => (
	<DataGrid
		{...rest}
		slots={{
			toolbar: DataTableToolbar,
			filterPanel: DataTableFilterPanel,
			baseTextField: TextField,
			baseSelect: SelectField,
			baseSelectOption: SelectMenuItem,
			moreActionsIcon: LargeMoreHoriz,
			pagination: Pagination,
		}}
		slotProps={{
			toolbar: {
				actions: toolbarActions,
				search,
				filter,
			},
			baseTextField: {
				id: "textInput",
				margin: "none",
				modal: true,
			} as TextFieldProps,
			baseSelect: {
				id: "tableSelect",
				label: undefined,
				margin: "none",
				native: false,
				modal: true,
			} as SelectFieldProps,
		}}
		localeText={{
			toolbarFilters: "Add filter",
		}}
		columns={columns}
		rows={rows}
		disableRowSelectionOnClick={true}
		hideFooter={hideFooter}
		rowHeight={48}
		sx={{
			display: "grid",
			gridTemplateRows: "auto 1fr auto", // This solves an issue with the responsiveness of the MUI DataGrid
			border: "none",
			"& .MuiDataGrid-columnHeaderTitle": {
				typography: "h6",
				color: "text.secondary",
			},
			"& .MuiDataGrid-menuIcon": {
				// Column header filter menu
				display: "none",
			},
			"& .MuiDataGrid-columnSeparator": {
				// Column header separator
				display: "none",
			},
			"& .MuiDataGrid-columnHeaders, & .MuiDataGrid-footerContainer, & .MuiDataGrid-cell":
				{
					borderWidth: "2px",
					borderColor: "table.main",
				},
			[`& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within,
			& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within`]:
				{
					outline: "none",
				},
			"& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered": {
				backgroundColor: "table.hover",
				transition: "background 0.3s ease-in",
			},
			"& .MuiDataGrid-virtualScroller": {
				overflowX: "hidden",
				// There is a bug with the calculated width always being a pixel more, adding a scrollbar
			},
		}}
	/>
);

DataTable.defaultProps = defaultProps;

export default DataTable;
