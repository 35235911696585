import { FormGroup, FormLabel, Stack } from "@mui/material";
import { getIn } from "formik";

import { TextField } from "components";

import type { FormikProps } from "formik";
import type { ReactNode } from "react";

type BrokerConfigFormProps<T> = {
	formik: FormikProps<T>;
};

const FormikNumericField = <T, >({
	label,
	path,
	formik,
}: {
	label: string;
	path: string;
	formik: FormikProps<T>;
}) => {
	return (
		<TextField
			id={label}
			modal
			label={label}
			name={path}
			InputProps={{
				inputMode: "numeric",
				pattern: "[0-9]*",
			}}
			value={getIn(formik.values, path)}
			onChange={formik.handleChange}
			onBlur={formik.handleBlur}
			error={getIn(formik.touched, path) && getIn(formik.errors, path)}
			helperText={
				getIn(formik.touched, path) && getIn(formik.errors, path)
			}
		/>
	);
};

const BrokerConfigForm = <T, >({
	formik,
}: BrokerConfigFormProps<T>): ReactNode => {
	return (
		<Stack sx={{ p: 2 }}>
			<FormGroup>
				<FormLabel component="legend" sx={{ mb: 2 }}>
					Broker configuration
				</FormLabel>
				{FormikNumericField<T>({
					formik,
					label: "Inital balance",
					path: "config.brokerConfig.startingBalance",
				})}
				<FormLabel component="legend" sx={{ my: 2 }}>
					Broker fees
				</FormLabel>
				{FormikNumericField<T>({
					formik,
					label: "$ per share",
					path: "config.brokerConfig.fees.pricePerShare",
				})}
				{FormikNumericField<T>({
					formik,
					label: "$ per order",
					path: "config.brokerConfig.fees.pricePerOrder",
				})}
				{FormikNumericField<T>({
					formik,
					label: "% per order",
					path: "config.brokerConfig.fees.percentPerOrder",
				})}
				{FormikNumericField<T>({
					formik,
					label: "Max per order",
					path: "config.brokerConfig.fees.orderMax",
				})}
				{FormikNumericField<T>({
					formik,
					label: "Min per order",
					path: "config.brokerConfig.fees.orderMin",
				})}
				<FormLabel component="legend" sx={{ my: 2 }}>
					Slippage factor
				</FormLabel>
				{FormikNumericField<T>({
					formik,
					label: "Price offset",
					path: "config.brokerConfig.slippage.priceOffset",
				})}
			</FormGroup>
		</Stack>
	);
};

export default BrokerConfigForm;
