import { useState } from "react";
import { Delete as DeleteIcon, Key as KeyIcon } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";

import {
	DataTable,
	Modal,
	Pill,
	ProviderKeyInfo,
	ProviderLogo,
} from "components";
import { useRevokeKeyMutation } from "services";
import { dateIsAfter, formatDateTime } from "utils";

import type { ReactNode } from "react";
import type { ProviderKey } from "types";

type ProviderKeyIndexTableProps = {
	providerKeys: Array<ProviderKey>;
};

const ProviderKeyIndexTable = ({
	providerKeys,
}: ProviderKeyIndexTableProps): ReactNode => {
	const [revokeKey, { isLoading }] = useRevokeKeyMutation();
	const [isViewingKey, setIsViewingKey] = useState<ProviderKey | null>(null);
	const [isRevokingKey, setIsRevokingKey] = useState<ProviderKey | null>(null);
	
	const handleRevoke = (key: ProviderKey | null) => {
		if (!key) return;
		revokeKey(key)
			.unwrap()
			.then(() => {
				setIsViewingKey(null);
				setIsRevokingKey(null);
			});
	};
	
	return (
		<>
			<DataTable
				getRowId={(row: ProviderKey) => row.keyId}
				columns={[
					{
						field: "Provider",
						flex: 3,
						renderCell: ({ row }: { row: ProviderKey }) => (
							<ProviderLogo providerId={row.providerId} />
						),
					},
					{
						field: "name",
						headerName: "Key Name",
						flex: 9,
					},
					{
						field: "expiresAt",
						headerName: "Expires",
						type: "dateTime",
						valueFormatter: ({ value }: { value: number | Date }) =>
							formatDateTime(value),
						valueGetter: ({ row }: { row: ProviderKey }) =>
							row.expiresAt && new Date(row.expiresAt * 1000),
						flex: 2,
					},
					{
						field: "Status",
						flex: 2,
						renderCell: ({ row }: { row: ProviderKey }) => {
							const isExpired = dateIsAfter(
								row.expiresAt,
								Date.now()
							);
							
							return (
								<Pill
									table
									text={
										row.revokedAt
											? "Revoked"
											: isExpired
												? "Expired"
												: "Active"
									}
									color={
										row.revokedAt || isExpired
											? "error"
											: "success"
									}
								/>
							);
						},
					},
					{
						field: "actions",
						type: "actions",
						getActions: ({ row }: { row: ProviderKey }) => [
							<GridActionsCellItem
								icon={<KeyIcon />}
								onClick={() => setIsViewingKey(row)}
								label="View key"
								showInMenu
							/>,
							<GridActionsCellItem
								icon={<DeleteIcon />}
								onClick={() => setIsRevokingKey(row)}
								label="Revoke key"
								showInMenu
							/>,
						],
					},
				]}
				rows={providerKeys}
			/>
			{isViewingKey && (
				<div>
					<ProviderKeyInfo
						handleRevokeKey={() => setIsRevokingKey(isViewingKey)}
						onClose={() => setIsViewingKey(null)}
						providerKey={isViewingKey}
						show
					/>
				</div>
			)}
			<Modal
				title="Revoke key"
				handleClose={() => setIsRevokingKey(null)}
				show={isRevokingKey !== null}
				onNegative={() => handleRevoke(isRevokingKey)}
				negativeButtonText="Revoke"
				isSubmitting={isLoading}
			>
				Do you wish to revoke <b>{isRevokingKey?.name}</b>?
			</Modal>
		</>
	);
};

export default ProviderKeyIndexTable;
