import { Annotation } from "components";
import type { ReactNode } from "react";
import type { ChartAnnotationObject, ScaleFactor } from "types";

const Annotations = ({
	annotations,
	scaleFactor,
}: {
	annotations: ChartAnnotationObject[],
	scaleFactor: ScaleFactor,
}): ReactNode => {
	return (
		<>
			{annotations.map((annotation) => (
				<Annotation
					key={annotation.objId}
					annotation={annotation}
					scaleFactor={scaleFactor}
				/>
			))}
		</>
	);
};

export default Annotations;
