import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const { REACT_APP_API_PROTOCOL, REACT_APP_API_IP, REACT_APP_API_PORT } =
	process.env;

if (!REACT_APP_API_PROTOCOL || !REACT_APP_API_IP || !REACT_APP_API_PORT) {
	throw new Error(
		"Missing required REACT_APP_API_PROTOCOL, REACT_APP_API_IP, or REACT_APP_API_PORT ENV variable(s)"
	);
}

export const api = createApi({
	reducerPath: "api",
	baseQuery: fetchBaseQuery({
		baseUrl: `${REACT_APP_API_PROTOCOL}://${REACT_APP_API_IP}:${REACT_APP_API_PORT}`,
	}),
	endpoints: () => ({}),
});
