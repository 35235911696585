import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type ScaleFactor } from "types";
import { RootState } from ".";

export interface ChartState {
	rotation: boolean;
	requestScaleY: boolean;
	scaleFactor: ScaleFactor | void;
}

const initialState: ChartState = {
	rotation: false,
	requestScaleY: false,
	scaleFactor: undefined,
};

export const chartSlice = createSlice({
	name: "chart",
	initialState,
	reducers: {
		toggleRotation: (state): void => {
			state.rotation = !state.rotation;
		},
		disableRotation: (state): void => {
			state.rotation = false;
		},
		triggerRequestScaleY: (state): void => {
			state.requestScaleY = true;
		},
		resetRequestScaleY: (state): void => {
			state.requestScaleY = false;
		},
		setScaleFactor: (state, action: PayloadAction<ScaleFactor>): void => {
			const { payload } = action;
			
			state.scaleFactor = payload;
		},
		resetScaleFactor: (state): void => {
			state.scaleFactor = undefined;
		},
	},
});

export const {
	disableRotation,
	resetRequestScaleY,
	resetScaleFactor,
	setScaleFactor,
	toggleRotation,
	triggerRequestScaleY,
} = chartSlice.actions;

export const selectRotation = (state: RootState): boolean =>
	state.chart.rotation;
export const selectScaleY = (state: RootState): boolean =>
	state.chart.requestScaleY;
export const selectScaleFactor = (state: RootState): ScaleFactor =>
	state.chart.scaleFactor;

export default chartSlice.reducer;
