import { CHART_OBJECT_DEFAULTS } from "consts";
import { type ReactNode } from "react";
import { calcX, calcY } from "../helpers";
import type { ChartBoxObject, ScaleFactor } from "types";

const Boxes = ({
	boxes,
	scaleFactor,
}: {
	boxes: ChartBoxObject[];
	scaleFactor: ScaleFactor;
}): ReactNode => {
	return (
		<>
			{boxes.map((box) => {
				const { opacity, color } = {
					...CHART_OBJECT_DEFAULTS.style,
					...box.style,
				};
				
				return (
					<mesh
						key={box.objId}
						scale={[
							box?.scale?.x ?? CHART_OBJECT_DEFAULTS.scale.x,
							box?.scale?.y ?? CHART_OBJECT_DEFAULTS.scale.y,
							box?.scale?.z ?? CHART_OBJECT_DEFAULTS.scale.z,
						]}
						position={[
							calcX(box.dtTo(), scaleFactor),
							calcY(box.startPoint.y, scaleFactor),
							box.startPoint.z || 0,
						]}
						rotation={[
							box?.rotation?.x ?? CHART_OBJECT_DEFAULTS.rotation.x,
							box?.rotation?.y ?? CHART_OBJECT_DEFAULTS.rotation.y,
							box?.rotation?.z ?? CHART_OBJECT_DEFAULTS.rotation.z,
						]}
					>
						<boxGeometry
							args={[
								box.width,
								box.height,
								box.depth,
							]}
						/>
						<meshStandardMaterial
							color={color}
							opacity={opacity}
							transparent={opacity < 1}
						/>
					</mesh>
				);
			})}
		</>
	);
};

export default Boxes;