import {
	type ReactNode,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Code as CodeIcon,
	ListAlt as ListAltIcon,
	Timeline as TimelineIcon,
} from "@mui/icons-material";
import { Grid, SelectChangeEvent, Typography } from "@mui/material";
import { CHARTS, CONSOLE, EVENTS } from "consts";
import { MetricsType, RunEventType } from "types";
import {
	ChartContainer,
	CodeEditor,
	Console,
	CreateVersionForm,
	Loading,
	PromptBeforeNavigate,
	RunEventsTable,
	ScriptRunner,
	ScriptVersionsModal,
	SelectField,
	TabButtons,
} from "components";
import { formatDateTime } from "utils";
import {
	useCreateDraftMutation,
	useGetCodeForVersionQuery,
	useGetDraftInstanceQuery,
	useGetLatestCodeQuery,
	useGetRunsQuery,
	useGetScriptQuery,
	useGetVersionsQuery,
	useLazyGetRunEventsQuery,
	useStartDraftInstanceMutation,
	useUpdateInstanceRunStateMutation,
} from "services";
import { AggLevelType } from "types";
import type {
	Code,
	ContainerProps,
	FetchedData,
	Fetcher,
	RunParams,
	ScriptId,
	ScriptVersionId,
} from "types";

const ScriptEditorContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	// STATE
	const [currentCode, setCurrentCode] = useState<Code>("");
	const [originalCode, setOriginalCode] = useState<Code>("");
	const [isCreatingVersion, setIsCreatingVersion] = useState(false);
	const [runParams, setRunParams] = useState<RunParams | null>(null);
	const [showVersions, setShowVersions] = useState(false);
	const [runId, setRunId] = useState<string>("");
	const [currentTab, setCurrentTab] = useState(CHARTS);
	const [aggLevel, setAggLevel] = useState<AggLevelType>();
	const [mints, setMints] = useState<number>(0);
	const [maxts, setMaxts] = useState<number>(0);
	
	// OTHER HOOKS
	const { scriptId, versionId } = useParams();
	const navigate = useNavigate();
	
	// RTK QUERY
	const { data: script, isLoading: isLoadingScript } = useGetScriptQuery(
		scriptId as ScriptId
	);
	
	const { data: draftInstance } = useGetDraftInstanceQuery(
		{
			scriptId: scriptId as ScriptId,
			runId,
		}
	);
	
	const { runs } = useGetRunsQuery(draftInstance?.instanceId ?? "", {
		skip: !draftInstance,
		selectFromResult: ({ data }) => ({
			runs: [...data?.instanceRuns ?? []].reverse(),
		}),
	});
	
	const { selectedVersion, versions, isLoadingVersions } = useGetVersionsQuery(
		scriptId as ScriptId,
		{
			skip: !versionId,
			selectFromResult: ({ data, isLoading }) => {
				return {
					versions: data?.versions ?? [],
					selectedVersion:
						versionId && data
							? data?.versions.find((v) => v.version === +versionId)!
							: null,
					isLoadingVersions: isLoading,
				};
			},
		}
	);
	
	// When we load this component, we need to get some basic info. What
	// we are trying to get here is a rough idea of what we are trying
	// to show - i.e. total rows at an aggregate of what. We will also
	// skip and refresh if the draft instance is running. This has to be
	// disconnected because using the skip on a normal query is out of.
	// Also, the metadata object here will form the handling of events
	// coming in the chart from the harvester.
	const [fetchMetadata, { data: metadata, isLoading: isLoadingEvents }] = useLazyGetRunEventsQuery();
	
	// This will setup a fetcher with so that the ChartContainer
	// can then fetch data according to it's min/max data points.
	const [fetch] = useLazyGetRunEventsQuery();
	const fetchRunEvents: Fetcher = async(mints: number, maxts: number): Promise<FetchedData> => {
		const  { events, minY, maxY, minLow, maxVol } = await fetch({
			msgtype: [RunEventType.CHART],
			metrics: [MetricsType.MIN_LOW_VOLUME],
			instanceId: draftInstance?.instanceId ?? "",
			runId: runId ?? "",
			tsFrom: mints,
			tsTo: maxts,
		}).unwrap();
		
		return {
			data: events ?? [],
			minY: minY ?? 0,
			maxY: maxY ?? 0,
			minLow: minLow ?? 0,
			maxVol: maxVol ?? 0,
		};
	};
	
	// Code, latest and perhaps a version that is being loading
	const { latestCode, isLoadingLatest } = useGetLatestCodeQuery(
		scriptId as ScriptId,
		{
			selectFromResult: ({ data, isLoading }) => ({
				latestCode: data?.code ?? "",
				isLoadingLatest: isLoading,
			}),
		}
	);
	
	const { codeForVersion, isLoadingCodeForVersion } = useGetCodeForVersionQuery(
		selectedVersion,
		{
			skip: !selectedVersion,
			selectFromResult: ({ data, isLoading }) => ({
				codeForVersion: data?.code ?? "",
				isLoadingCodeForVersion: isLoading,
			}),
		}
	);
	
	// Controls, all the starting and stopping actions.
	const [startDraftInstance, { data: runningDraft, isLoading: isWaiting }] = useStartDraftInstanceMutation();
	const [updateInstanceRunState] = useUpdateInstanceRunStateMutation();
	const [triggerCreateDraft, { isLoading: isSubmittingDraft }] = useCreateDraftMutation();
	
	const isLoading =
		isLoadingScript ||
		isLoadingVersions ||
		isLoadingCodeForVersion ||
		isLoadingLatest ||
		isLoadingEvents;
	const isExecuting: boolean = draftInstance
		? draftInstance.state !== "NOT_RUNNING"
		: false;
	const isEditing = !versionId;
	const hasUnsavedChanges = currentCode !== latestCode && isEditing;
	const showSaveVersionButton = currentCode !== originalCode;
	
	// HANDLERS
	const toggleCreateVersion = useCallback(() => {
		setIsCreatingVersion(!isCreatingVersion);
	}, [isCreatingVersion]);
	
	const createDraft = useCallback(
		() => triggerCreateDraft({ scriptId: scriptId ?? "", code: currentCode }),
		[scriptId, currentCode, triggerCreateDraft]
	);
	
	const startDraft = () =>
		startDraftInstance({
			scriptId: scriptId ?? "",
			draftCode: currentCode,
			params: runParams || {},
		});
	
	const stopDraft = () =>
		updateInstanceRunState({
			instanceId: draftInstance?.instanceId ?? "",
			action: "stop",
		});
	
	const handleCodeChanged = (newCode: Code): void => {
		setCurrentCode(newCode);
	};
	
	const handleRunHistoryChange = (e: SelectChangeEvent<unknown>) => {
		setRunId(e.target.value as string);
		
		// Reset the min/max ts so that even if they are similar, it
		// will cause the chart to re-fetch.
		setMints(0);
		setMaxts(0);
	};
	
	const handleOpenCodeEditor = (
		readOnly: boolean,
		selectedVersionId?: ScriptVersionId | null
	): void => {
		if (script) {
			const url =
				selectedVersionId && readOnly
					? `/scripts/${script.scriptId}/version/${selectedVersionId}/view`
					: `/scripts/${script.scriptId}/edit`;
			
			navigate(url, { replace: false });
		}
	};
	
	// REACT HOOKS
	useEffect(() => {
		setBreadcrumbs([
			{ text: "Scripts", url: "/scripts" },
			{ text: `${isEditing ? "Edit" : "View"} script` },
		]);
	}, [setBreadcrumbs, isEditing]);
	
	useEffect(() => {
		if (!isEditing) return;
		setHeaderActions({
			create: [
				{
					label: "Commit",
					onClick: toggleCreateVersion,
					disabled: !showSaveVersionButton,
				},
				{
					label: "Save",
					loading: isSubmittingDraft,
					disabled: !hasUnsavedChanges,
					onClick: createDraft,
				},
			],
		});
	}, [
		setHeaderActions,
		hasUnsavedChanges,
		isEditing,
		isSubmittingDraft,
		showSaveVersionButton,
		toggleCreateVersion,
		createDraft,
	]);
	
	useEffect(() => {
		if (isEditing) return;
		setHeaderActions({
			actions: [
				{
					label: "Edit history",
					onClick: () => setShowVersions(true),
				},
			],
		});
	}, [setHeaderActions, setShowVersions, isEditing]);
	
	useEffect(() => {
		// This should only ever set the run ID when we load, have loaded
		// the script, and it has the latest run.
		if (draftInstance && runId === "") {
			setRunId(draftInstance.latestRun?.runId ?? "");
		}
	}, [draftInstance, runId]);
	
	useEffect(() => {
		if (runs && runs.length && runId && runParams === null) {
			setRunParams(runs.find(run => run.runId === runId)?.params || {});
		}
	}, [runs, runId, runParams]);
	
	useEffect(() => {
		// Set the "current code" that will be shown in the code editor
		setCurrentCode(codeForVersion || latestCode);
		setOriginalCode((prev) => prev === "" ? latestCode : prev);
	}, [latestCode, codeForVersion]);
	
	// We have to do this because if the run is the same aggLevel,
	// and the same min/max timestamp, we still need to reset the
	// chart and fetch the events.
	useEffect(() => {
		const { tsFrom, tsTo, aggLevel } = metadata ?? {};
		
		if (!tsFrom || !tsTo) return;
		
		setAggLevel(aggLevel);
		setMints(tsFrom);
		setMaxts(tsTo);
	}, [metadata, setAggLevel, setMints, setMaxts]);
	
	// This allows for the update on the runId when we run a script
	// so that we re-register the socket updates with a new request.
	useEffect(() => {
		if (!runningDraft || !runningDraft.latestRun) return;
		
		const { latestRun: { runId } } = runningDraft;
		
		setRunId(runId);
	}, [runningDraft]);
	
	// This fetches the metadata when the run ID is changed.
	useEffect(() => {
		if (!draftInstance?.instanceId || !runId || isExecuting) return;
		
		fetchMetadata({
			msgtype: [RunEventType.CHART],
			metrics: [MetricsType.AGG_LEVEL, MetricsType.MIN_MAX_TIMESTAMPS],
			instanceId: draftInstance.instanceId,
			runId: runId,
			limit: 0,
		});
	}, [fetchMetadata, draftInstance, runId, isExecuting]);
	
	return (
		<>
			{isLoading && <Loading message="Loading..." />}
			{!isLoading && script && (
				<>
					<Grid
						container
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item md={8}>
							<Typography variant="h1">{script?.name}</Typography>
						</Grid>
						{runs.length > 0 && (
							<Grid item md={4}>
								<SelectField
									id="runHistory"
									options={runs.map((run) => ({
										label: formatDateTime(
											run.createdAt,
											"after"
										),
										value: run.runId,
									}))}
									name="runHistory"
									label="Run history"
									onChange={handleRunHistoryChange}
									value={runId ? runId : ""}
								/>
							</Grid>
						)}
					</Grid>
					<TabButtons
						currentTab={currentTab}
						handleTabChange={(newTab) => setCurrentTab(newTab)}
						tabs={[
							{
								label: CHARTS,
								icon: TimelineIcon,
							},
							{
								label: CONSOLE,
								icon: CodeIcon,
							},
							{
								label: EVENTS,
								icon: ListAltIcon,
							},
						]}
					/>
					<Grid container item height="70vh">
						{currentTab === "Charts" && (
							<ChartContainer
								fetcher={fetchRunEvents}
								aggLevel={aggLevel}
								mints={mints}
								maxts={maxts}
							/>
						)}
						{currentTab === CONSOLE && (
							<Console
								instanceId={draftInstance?.instanceId}
								runId={runId}
							/>
						)}
						{currentTab === EVENTS && (
							<RunEventsTable
								instanceId={draftInstance?.instanceId}
								runId={runId}
							/>
						)}
					</Grid>
					<Grid
						container
						justifyContent="space-around"
						alignItems="center"
						columnSpacing={4}
						py={4}
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={8}
							sx={{ height: "70vh" }}
						>
							<CodeEditor
								currentCode={currentCode}
								handleUpdateCode={handleCodeChanged}
								readOnly={!isEditing}
								script={script}
								version={versionId}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={4}
							sx={{ height: "80vh" }}
						>
							{isEditing && <ScriptRunner
								isExecuting={isExecuting}
								runParams={runParams || {}}
								start={async() => {
									await createDraft();
									startDraft();
								}}
								stop={stopDraft}
								updateRunParams={setRunParams}
								isWaiting={isWaiting}
								codeChanges={hasUnsavedChanges}
							/>}
						</Grid>
					</Grid>
					<CreateVersionForm
						handleFormClose={toggleCreateVersion}
						code={currentCode}
						scriptId={script.scriptId}
						show={isCreatingVersion}
					/>
					<ScriptVersionsModal
						isLoadingVersions={isLoadingVersions}
						onClose={() => setShowVersions(false)}
						handelShowVersion={(versionId) =>
							handleOpenCodeEditor(
								versionId !== null,
								versionId
							)
						}
						script={script}
						show={showVersions}
						versions={versions}
						currentVersion={Number(versionId)}
					/>
					<PromptBeforeNavigate
						promptBeforeRedirect={hasUnsavedChanges}
						handleSave={createDraft}
						saveButtonLoading={isSubmittingDraft}
					/>
				</>
			)}
		</>
	);
};

export default ScriptEditorContainer;
