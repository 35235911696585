import { type ReactNode, useState } from "react";
import AceEditor from "react-ace";
import {
	Grid,
	Switch,
	useTheme,
} from "@mui/material";
import LightMode from "@mui/icons-material/LightMode";
import DarkMode from "@mui/icons-material/DarkMode";
import "ace-builds/src-noconflict/theme-dracula"; //dark
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-clouds"; // light, gray gutter, yellow highlight

import "./SqlEditor.css";

function SqlEditor({
	query,
	setQuery,
}: {
	query: string,
	setQuery: (query: string) => void,
}): ReactNode {
	const theme = useTheme();
	const toggleDarkMode = () => setIsDarkMode(!isDarkMode);
	
	const [isDarkMode, setIsDarkMode] = useState(
		theme.palette.mode === "dark" ? true : false
	);
	
	const editorOptions = {
		fontSize: 20,
	};
	
	return (
		<Grid container direction="column" gap={2} pb={5}>
			<Grid item>
				<AceEditor
					editorProps={{ $blockScrolling: true }}
					onChange={(newVal, e) => setQuery(newVal)}
					mode="mysql"
					setOptions={editorOptions}
					style={{
						border: "1px solid hsla(0, 0%, 84%, 1)",
						width: "100%",
						height: "25vh",
						zIndex: 0,
						resize: "vertical",
						overflow: "auto",
					}}
					theme={isDarkMode ? "dracula" : "clouds"}
					value={query}
				/>
			</Grid>
			<Grid
				container
				item
				justifyContent="flex-end"
				alignItems="center"
				py={3}
			>
				<Grid item>
					<LightMode />
				</Grid>
				<Grid item>
					<Switch
						checked={isDarkMode}
						color="primary"
						onChange={toggleDarkMode}
					/>
				</Grid>
				<Grid item>
					<DarkMode />
				</Grid>
			</Grid>
		</Grid>
	);
}

export default SqlEditor;
