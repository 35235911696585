import { GridFilterPanel } from "@mui/x-data-grid";
import type { ReactNode } from "react";

const DataTableFilterPanel = ({
	rest,
	children,
}: {
	rest: any;
	children: ReactNode | ReactNode[];
}): ReactNode => (
	<GridFilterPanel
		{...rest}
		sx={(theme) => ({
			padding: theme.spacing(2),
			backgroundColor: theme.palette.background.popper,
			"& .MuiFormControl-root:not(:last-child)": {
				marginRight: theme.spacing(1),
			},
		})}
	>
		{children}
	</GridFilterPanel>
);

export default DataTableFilterPanel;
