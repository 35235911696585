import { Grid } from "@mui/material";
import { ReactNode } from "react";

interface Props {
	children: ReactNode;
}

const GridContainer = ({ children }: Props) => {
	return (
		<Grid container item sx={{ height: "calc(90vh - 8.8rem)" }}>
			{children}
		</Grid>
	);
};

export default GridContainer;