import { api } from ".";

import type { BrokerOrder } from "types";

export const orders = api
	.enhanceEndpoints({
		addTagTypes: ["orders"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getOrders: build.query<BrokerOrder[], void>({
				query() {
					return {
						url: "/orders",
						method: "GET",
					};
				},
				providesTags: ["orders"],
			}),
		}),
	});

export const {
	useGetOrdersQuery,
} = orders;