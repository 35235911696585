import { ReactNode, useEffect, useState } from "react";
import AceEditor from "react-ace";
import { Box, Grid, Switch } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-clouds"; // light, gray gutter, yellow highlight
import "ace-builds/src-noconflict/theme-dracula"; //dark

import { useAppSelector } from "hooks";
import { THEME_DARK, selectUserTheme } from "store";
import "./CodeEditor.css";

import type { Code, Script } from "types";
import type { PaletteMode } from "@mui/material";

type CodeEditorProps = {
	currentCode: string;
	handleUpdateCode: (newCode: Code) => void;
	readOnly: boolean;
	script?: Script;
	version?: string;
};

const defaultProps = { readOnly: true };

function CodeEditor({
	currentCode,
	handleUpdateCode,
	readOnly,
	script,
	version,
}: CodeEditorProps): ReactNode {
	const themeMode = useAppSelector<PaletteMode>(selectUserTheme);
	
	const handleCodeChanged = (newCode: string, e: any) => {
		!readOnly && handleUpdateCode(newCode);
	};
	
	const toggleDarkMode = () => setIsDarkMode(!isDarkMode);
	
	const [isDarkMode, setIsDarkMode] = useState(false);
	
	useEffect(() => {
		setIsDarkMode(themeMode === THEME_DARK);
	}, [themeMode]);
	
	// react-ace docs
	// https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
	const editorOptions = {
		fontSize: 20,
		readOnly,
	};
	
	// NB percentage works for width but NOT height
	const editorStyle = {
		width: "100%",
		height: "100%",
		zIndex: 0,
	};
	
	const theme = isDarkMode ? "dracula" : "clouds";
	
	return (
		<Box component="div" data-testid="ace-editor" sx={{ height: "100%" }}>
			<AceEditor
				editorProps={{ $blockScrolling: true }}
				onChange={(newVal, e) => handleCodeChanged(newVal, e)}
				mode="python"
				setOptions={editorOptions}
				style={editorStyle}
				theme={theme}
				value={currentCode}
			/>
			<Grid
				container
				justifyContent="flex-end"
				alignItems="center"
				pt={3}
			>
				<Grid item>
					<LightModeIcon />
				</Grid>
				<Grid item>
					<Switch
						checked={isDarkMode}
						color="primary"
						onChange={toggleDarkMode}
					/>
				</Grid>
				<DarkModeIcon />
			</Grid>
		</Box>
	);
}

CodeEditor.defaultProps = defaultProps;

export default CodeEditor;
