import { type ReactNode, useContext, useState } from "react";
import { useFormik } from "formik";
import {
	Alert,
	DateTimeField,
	Modal,
	SelectField,
} from "components";
import { useCreateHistoricalDataRequestMutation } from "services";
import { ToastContext, type ToastContextType } from "context";
import { getUnixTimestamp } from "utils";
import { RequestAssetDataFormSchema } from "./RequestAssetDataForm.schema";
import type { AssetDataResolution, AssetIntervals } from "types";
import type { Dayjs } from "dayjs";

export interface FormInitialState {
	dateFrom: Dayjs | null;
	dateTo: Dayjs | null;
	resolution: AssetDataResolution;
}

const FORM_INITIAL_STATE: FormInitialState = {
	dateFrom: null,
	dateTo: null,
	resolution: "day",
};

const RESOLUTIONS = [
	{
		value: "second",
		label: "Seconds",
	},
	{
		value: "minute",
		label: "Minutes",
	},
	{
		value: "hour",
		label: "Hours",
	},
	{
		value: "day",
		label: "Days",
	},
];

type RequestAssetDataFormProps = {
	handleFormClose: () => void;
	initialState: FormInitialState | null;
	selectedAsset?: AssetIntervals;
	show: boolean;
};

const RequestAssetDataForm = ({
	handleFormClose,
	initialState,
	selectedAsset,
	show,
}: RequestAssetDataFormProps): ReactNode => {
	const { createToast } = useContext(ToastContext) as ToastContextType;
	const [error, setError] = useState<string>("");
	const [createHistoricalData, { isLoading }] =
		useCreateHistoricalDataRequestMutation();
	
	const handleFormSubmit = (form: FormInitialState) => {
		const { dateFrom, dateTo, resolution } = form;
		
		if (getUnixTimestamp(dateFrom) >= getUnixTimestamp(dateTo)) {
			createToast("Date from chosen is greater than date to.");
			
			return;
		}
		
		createHistoricalData({
			symbol: selectedAsset?.ticker ?? "",
			tsFrom: getUnixTimestamp(dateFrom),
			tsTo: getUnixTimestamp(dateTo),
			resolution: resolution,
		})
			.unwrap()
			.then(() => handleFormClose())
			.catch((e) => setError(e.message));
	};
	
	const formik = useFormik({
		initialValues: { ...FORM_INITIAL_STATE, ...(initialState || {}) },
		validationSchema: RequestAssetDataFormSchema,
		onSubmit: handleFormSubmit,
		validateOnChange: true,
		enableReinitialize: true,
	});
	
	return (
		<Modal
			title="Request historical data for"
			dynamicTitle={selectedAsset?.name || "unknown"}
			handleClose={handleFormClose}
			show={show}
			onSave={formik.handleSubmit}
			saveButtonText="Populate"
			isValid={formik.isValid}
			isSubmitting={isLoading}
		>
			{error && <Alert>{error}</Alert>}
			<form onSubmit={formik.handleSubmit}>
				<DateTimeField
					label="From date and time"
					value={formik.values.dateFrom}
					onChange={(value) => {
						formik.setFieldValue("dateFrom", value);
					}}
					modal={true}
				/>
				<DateTimeField
					label="To date and time"
					value={formik.values.dateTo}
					onChange={(value) => {
						formik.setFieldValue("dateTo", value);
					}}
					modal={true}
				/>
				<SelectField
					id="resolution"
					modal
					options={RESOLUTIONS}
					name="resolution"
					value={formik.values.resolution}
					label="Resolution"
					onChange={formik.handleChange}
					error={
						formik.touched.resolution &&
						Boolean(formik.errors.resolution)
					}
					helperText={
						formik.touched.resolution && formik.errors.resolution
					}
				/>
			</form>
		</Modal>
	);
};

export default RequestAssetDataForm;
