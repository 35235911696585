import { type ReactNode, useState } from "react";
import { useFormik } from "formik";
import { Alert, Modal, SelectField } from "components";
import { useCreateRealtimeDataRequestMutation } from "services";
import { RequestHarvesterFormSchema } from "./RequestHarvesterForm.schema";
import type { AssetDataResolution, AssetIntervals } from "types";

export interface RequestHarvesterFormState {
	resolution: AssetDataResolution;
}

const FORM_INITIAL_STATE: RequestHarvesterFormState = {
	resolution: "" as AssetDataResolution,
};

const RESOLUTIONS = [
	{
		value: "tick",
		label: "Trades",
	},
	{
		value: "second",
		label: "Seconds",
	},
	{
		value: "minute",
		label: "Minutes",
	},
];

type RequestHarvesterFormProps = {
	handleFormClose: () => void;
	selectedAsset?: AssetIntervals;
	show: boolean;
};

const RequestHarvesterForm = ({
	handleFormClose,
	selectedAsset,
	show,
}: RequestHarvesterFormProps): ReactNode => {
	const [error, setError] = useState<string>("");
	const [createRealtimeDataRequest, { isLoading }] =
		useCreateRealtimeDataRequestMutation();
	
	const handleFormSubmit = (form: RequestHarvesterFormState) => {
		const { resolution } = form;
		
		createRealtimeDataRequest({
			symbol: selectedAsset?.ticker ?? "",
			resolution: resolution,
		})
			.unwrap()
			.then(() => handleFormClose())
			.catch((e) => setError(e.message));
	};
	
	const formik = useFormik({
		initialValues: FORM_INITIAL_STATE,
		validationSchema: RequestHarvesterFormSchema,
		onSubmit: handleFormSubmit,
		validateOnChange: true,
	});
	
	return (
		<Modal
			title="Request harvester for"
			dynamicTitle={selectedAsset?.name || "unknown"}
			handleClose={handleFormClose}
			show={show}
			onSave={formik.handleSubmit}
			saveButtonText="Create"
			isValid={formik.isValid}
			isSubmitting={isLoading}
		>
			{error && <Alert>{error}</Alert>}
			<form onSubmit={formik.handleSubmit}>
				<SelectField
					id="resolution"
					options={RESOLUTIONS}
					name="resolution"
					value={formik.values.resolution}
					label="Resolution"
					onChange={formik.handleChange}
					error={
						formik.touched.resolution &&
						Boolean(formik.errors.resolution)
					}
					helperText={
						formik.touched.resolution && formik.errors.resolution
					}
				/>
			</form>
		</Modal>
	);
};

export default RequestHarvesterForm;
