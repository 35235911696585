
import { DataTable, Loading } from "components";
import { formatDateTimeWithSeconds } from "utils";

import type { ReactNode } from "react";
import type { BrokerEvent } from "types";

type BrokerOrderTableProps = {
	events: BrokerEvent[],
	isLoading: boolean,
};

const BrokerOrderTable = ({
	events,
	isLoading,
}: BrokerOrderTableProps): ReactNode => {
	return (
		<>
			{isLoading && <Loading message="Getting orders..." />}
			{!isLoading && (
				<DataTable
					getRowId={(row: BrokerEvent) => row.event_id}
					columns={
						[
							{
								field: "timestamp",
								flex: 12,
								valueGetter: ({ row }) => row.order.timestamp,
								valueFormatter: ({ value }) =>
									formatDateTimeWithSeconds(value),
							},
							{
								field: "msgtype",
								flex: 8,
								valueGetter: ({ row }) => row.msgtype,
							},
							{
								field: "symbol",
								flex: 4,
								valueGetter: ({ row }) => row.order.symbol,
							},
							{
								field: "op",
								flex: 4,
								valueGetter: ({ row }) => row.order.op,
							},
							{
								field: "units",
								flex: 8,
								valueGetter: ({ row }) => row.order.units,
							},
							{
								field: "unit_price",
								flex: 8,
								valueGetter: ({ row }) => row.order.unitPrice,
							},
							{
								field: "fee",
								flex: 8,
								valueGetter: ({ row }) => row.order.fee,
							},
							{
								field: "req_timestamp",
								flex: 12,
								valueGetter: ({ row }) => row.order.reqTimestamp,
								valueFormatter: ({ value }) =>
									formatDateTimeWithSeconds(value),
							},
							{
								field: "req_id",
								flex: 8,
								valueGetter: ({ row }) => row.order.reqId,
							},
							{
								field: "error",
								flex: 8,
								valueGetter: ({ row }) => row.error,
							},
						]
					}
					rows={events || []}
					filter={false}
					search={false}
				/>
			)}
		</>
	);
};

export default BrokerOrderTable;
