import * as Yup from "yup";

const FIELD_MAX_LENGTH = 50;

export const CreateProviderKeyFormSchema = Yup.object({
	providerId: Yup.string().required("Select a trading platform"),
	name: Yup.string()
		.max(FIELD_MAX_LENGTH, `Maximum length of ${FIELD_MAX_LENGTH} exceeded`)
		.required("A name for the new key is required"),
	apiKey: Yup.string().required(
		"An API key from your trading platform is required"
	),
});
