import { Polygon } from ".";
import type { ReactNode } from "react";
import type { ChartPolygonObject, ScaledObject } from "types";

interface PolygonProps extends ScaledObject {
	polygons: ChartPolygonObject[],
}

const Polygons = ({
	polygons,
	scaleFactor,
}: PolygonProps): ReactNode => {
	return (
		<>
			{polygons.map((polygon) => (
				<Polygon scaleFactor={scaleFactor} {...polygon} key={polygon.objId} />
			))}
		</>
	);
};

export default Polygons;
