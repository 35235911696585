import { InstanceState, PillColor, PillVariant } from "types";

type InstanceStateToStatusMap = {
	[K in InstanceState]: {
		text: string;
		color?: PillColor;
		variant?: PillVariant;
	};
};

export const instanceStateToStatusMap: InstanceStateToStatusMap = {
	RUNNING: {
		text: "Running",
		color: "success",
	},
	NOT_RUNNING: {
		text: "Inactive",
		variant: "outlined",
	},
	PENDING: {
		text: "Pending",
		color: "primary",
	},
	STOPPING: {
		text: "Stopping",
		color: "error",
	},
	ARCHIVED: {
		text: "Archived",
		variant: "outlined",
	},
};
