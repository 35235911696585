import {
	type ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { TrendingUp as TrendingUpIcon } from "@mui/icons-material";
import {
	Box,
	Drawer,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ListIcon from "@mui/icons-material/List";
import CloseIcon from "@mui/icons-material/Close";

import { useLazyGetAssetsQuery } from "services";
import AssetDrawerItem from "components/assets/AssetDrawerItem/AssetDrawerItem";
import BigCard from "components/common/display/BigCard/BigCard";
import { LazyLoadingList, Loading, TextField } from "components";
import { AssetsContext, AssetsContextValue } from "context";
import { debounce } from "utils";

import type { AssetIntervals, GetAssetsParams } from "types";

const VIEWS = {
	FAVORITE: "FAVORITE",
	ALL: "ALL",
};

type AssetDrawerProps = {
	handleAssetClicked: (asset: AssetIntervals) => void,
	isOpen: boolean,
	onClose: () => void,
};

const defaultProps = { isOpen: false };

const AssetDrawer = ({
	handleAssetClicked,
	isOpen,
	onClose,
}: AssetDrawerProps): ReactNode => {
	const [trigger, { data, isLoading, isFetching }] = useLazyGetAssetsQuery();
	
	// TODO: remove? augment items instead?
	const isAssetFavorite = (asset: AssetIntervals): boolean => {
		const match = favouriteAssets.find((f) => f.ticker === asset.ticker);
		
		return !!match;
	};
	
	const filterFavorites = (
		f: Array<AssetIntervals>
	): Array<AssetIntervals> => {
		const comparator = filterText.toLowerCase();
		
		return f.filter((asset) => {
			const { name, ticker } = asset;
			const matched =
				name.toLowerCase().indexOf(comparator) === 0 ||
				ticker.toLowerCase().indexOf(comparator) === 0;
			
			return matched;
		});
	};
	
	const handleChangeActiveView = (view: string): void => {
		activeView !== view && setActiveView(view);
	};
	
	const handleUpdateFilterText = (text: string): void => setFilterText(text);
	
	const handleUpdateSearchTerm = (searchTerm: string): void => {
		setSearchTerm(searchTerm);
		setAssets([]);
		trigger({ search: searchTerm, offset: 0 } as GetAssetsParams);
	};
	
	const handleAddFavorite = (asset: AssetIntervals): void =>
		addFavorite(asset);
	
	const handleRemoveFavorite = (asset: AssetIntervals): void =>
		removeFavorite(asset);
	
	const handleLoadNextAssets = () => {
		trigger({ search: searchTerm, offset: assets.length } as GetAssetsParams);
	};
	
	const renderAssetDrawerItem = (asset: AssetIntervals): ReactNode => {
		return (
			<AssetDrawerItem
				asset={asset}
				handleAddFavorite={handleAddFavorite}
				handleAssetClicked={handleAssetClicked}
				handleRemoveFavorite={handleRemoveFavorite}
				isFavorite={isAssetFavorite(asset)}
			/>
		);
	};
	
	const [activeView, setActiveView] = useState(VIEWS.FAVORITE);
	const [filterText, setFilterText] = useState<string>("");
	const [assets, setAssets] = useState<AssetIntervals[]>([]);
	// TODO: are we doing anything with favouriteAssets or relying on API
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [openSearchBox, setOpenSearchBox] = useState<boolean>(false);
	
	const {
		favouriteAssets,
		addFavorite,
		removeFavorite,
		recentlyViewed,
		toggleAssetDrawer,
	} = useContext(AssetsContext) as AssetsContextValue;
	const theme = useTheme();
	
	const filteredFavorites = filterFavorites(favouriteAssets);
	
	// debounce and memoise to stop unnecessary re-renders / API calls
	const debouncedUpdateFilterText: () => void = debounce(
		handleUpdateFilterText,
		200
	);
	
	const debouncedUpdateSearchTerm: () => void = debounce(
		handleUpdateSearchTerm,
		300
	);
	
	const memoisedUpdateFilterText: (value: string) => void = useCallback(
		debouncedUpdateFilterText,
		[filterText, debouncedUpdateFilterText]
	);
	
	const memoisedUpdateSearchTerm: (value: string) => void = useCallback(
		debouncedUpdateSearchTerm,
		[debouncedUpdateSearchTerm]
	);
	
	useEffect(() => {
		if (isOpen) {
			setOpenSearchBox(false);
		}
	}, [isOpen]);
	
	useEffect(() => {
		if (activeView !== VIEWS.ALL) return;
		trigger(); // Starts the initial request.
	}, [activeView, trigger]);
	
	useEffect(() => {
		if (!data) return;
		const { tickers } = data;
		
		// Adds the next "page" of assets to the scroller.
		setAssets((prev) => [...prev, ...tickers]);
	}, [data, setAssets]);
	
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					width: "30vw",
					background: theme.palette.background.drawer,
				},
			}}
		>
			<Stack spacing={3} sx={{ height: "100%", px: 2, pt: 3, pb: 2 }}>
				{openSearchBox ? (
					<Box component="div">
						<TextField
							id="searchbox"
							placeholder="Search..."
							margin="none"
							onChange={(e) => {
								if (activeView === VIEWS.FAVORITE) {
									return memoisedUpdateFilterText(
										e.currentTarget.value
									);
								}
								if (activeView === VIEWS.ALL) {
									return memoisedUpdateSearchTerm(
										e.currentTarget.value
									);
								}
							}}
							endAdornment={
								<InputAdornment
									sx={(theme) => ({
										paddingRight: theme.spacing(1),
										cursor: "pointer",
										color:
											theme.palette.mode === "light"
												? theme.palette.info.main
												: theme.palette.text.primary,
									})}
									onClick={() => {
										setOpenSearchBox(false);
										if (activeView === VIEWS.ALL) {
											setAssets([]);
											trigger();
										}
									}}
									position="end"
								>
									<CloseIcon />
								</InputAdornment>
							}
						/>
					</Box>
				) : (
					<Grid container alignItems="center" spacing={2}>
						<Grid item sx={{ marginRight: "auto" }}>
							<Typography variant="h3" ml={1}>
								Stocks
							</Typography>
						</Grid>
						<Grid item>
							<IconButton
								aria-label="Open asset drawer"
								onClick={() => toggleAssetDrawer()}
								sx={(theme) => ({
									color:
										theme.palette.mode === "light"
											? theme.palette.info.main
											: theme.palette.text.primary,
								})}
							>
								<ListIcon />
							</IconButton>
						</Grid>
						<Grid item>
							<IconButton
								onClick={() => setOpenSearchBox(true)}
								sx={(theme) => ({
									color:
										theme.palette.mode === "light"
											? theme.palette.info.main
											: theme.palette.text.primary,
								})}
							>
								<SearchIcon />
							</IconButton>
						</Grid>
					</Grid>
				)}
				<Stack spacing={2} sx={{ height: "100%" }}>
					{isLoading && (
						<Box component="div">
							<Loading message="Getting assets..." />
						</Box>
					)}
					{!isLoading &&
						activeView === VIEWS.FAVORITE &&
						filteredFavorites.length === 0 && (
						<BigCard
							icon={TrendingUpIcon}
							message="None of your favourite assets match the current filters"
						/>
					)}
					{!isLoading && activeView === VIEWS.FAVORITE && (
						<>
							{recentlyViewed.length > 0 && (
								<Stack rowGap={2} mb={4}>
									<Typography
										mb={1}
										ml={1}
										variant="h5"
										color="text.secondary"
									>
										Recenly viewed
									</Typography>
									{recentlyViewed.map((asset, idx) => (
										<AssetDrawerItem
											asset={asset}
											handleAddFavorite={
												handleAddFavorite
											}
											handleAssetClicked={
												handleAssetClicked
											}
											handleRemoveFavorite={
												handleRemoveFavorite
											}
											isFavorite={isAssetFavorite(asset)}
											key={`asset-drawer-item-${idx}`}
										/>
									))}
								</Stack>
							)}
							{filteredFavorites.length > 0 && (
								<Stack rowGap={2}>
									<Typography
										mb={1}
										ml={1}
										variant="h5"
										color="text.secondary"
									>
										Bookmarked
									</Typography>
									{filteredFavorites.map((asset, idx) => (
										<AssetDrawerItem
											asset={asset}
											handleAddFavorite={
												handleAddFavorite
											}
											handleAssetClicked={
												handleAssetClicked
											}
											handleRemoveFavorite={
												handleRemoveFavorite
											}
											isFavorite={isAssetFavorite(asset)}
											key={`asset-drawer-item-${idx}`}
										/>
									))}
								</Stack>
							)}
						</>
					)}
					{!isLoading &&
						activeView === VIEWS.ALL &&
						!isFetching &&
						assets.length === 0 && (
						<BigCard
							icon={() => (
								<TrendingUpIcon fontSize="inherit" />
							)}
							message="No stocks found matching search term"
						/>
					)}
					{!isLoading &&
						activeView === VIEWS.ALL &&
						assets.length > 0 && (
						<Stack
							rowGap={2}
							sx={{ height: "100%", overflowY: "hidden" }}
						>
							<Typography mb={1} ml={1} variant="h5">
								All Stocks
							</Typography>
							<LazyLoadingList
								handleLoadNextItems={handleLoadNextAssets}
								isLoadingNextItems={isLoading}
								items={assets}
								renderItemComponent={renderAssetDrawerItem}
							/>
						</Stack>
					)}
				</Stack>
				<Link
					sx={(theme) => ({
						color:
							theme.palette.mode === "light"
								? theme.palette.text.secondary
								: theme.palette.text.primary,
						textDecorationColor:
							theme.palette.mode === "light"
								? theme.palette.text.secondary
								: theme.palette.text.primary,
						marginTop: "auto",
						cursor: "pointer",
					})}
					onClick={() =>
						handleChangeActiveView(
							activeView === VIEWS.FAVORITE
								? VIEWS.ALL
								: VIEWS.FAVORITE
						)
					}
				>
					{`Show ${
						activeView === VIEWS.FAVORITE
							? " all stocks"
							: " favourites"
					}`}
				</Link>
			</Stack>
		</Drawer>
	);
};

AssetDrawer.defaultProps = defaultProps;

export default AssetDrawer;
