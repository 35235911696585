import { type ReactNode, useContext, useEffect, useState } from "react";
// UNSAFE_NavigationContext is only being used until React Router adds a documented block method back in
import {
	type Navigator as RouterNavigator,
	UNSAFE_NavigationContext,  // eslint-disable-line camelcase
} from "react-router-dom";

import { Modal } from "components";
import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";

type PromptBeforeNavigateProps = {
	promptBeforeRedirect: boolean;
	handleSave: () => Promise<{ data: any; } | { error: FetchBaseQueryError | SerializedError; } | void>,
	saveButtonLoading: boolean;
};

const PromptBeforeNavigate = ({
	promptBeforeRedirect,
	handleSave,
	saveButtonLoading,
}: PromptBeforeNavigateProps): ReactNode => {
	const { navigator } = useContext(UNSAFE_NavigationContext);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [confirmResolver, setConfirmResolver] = useState<
	((value: boolean) => void) | null
	>(null);
	
	const confirmExit = () => {
		return new Promise((resolve, reject) => {
			setShowConfirmModal(true);
			setConfirmResolver(() => resolve);
		});
	};
	
	// Show confirmation modal if user navigates using ReactRouter
	useEffect(() => {
		if (!promptBeforeRedirect || !navigator) return;
		
		const push = navigator.push;
		
		navigator.push = async(
			...args: Parameters<RouterNavigator["push"]>
		) => {
			if (await confirmExit()) push(...args);
		};
		
		return () => {
			navigator.push = push;
		};
	}, [navigator, promptBeforeRedirect]);
	
	// Show browser confirmation message if the user navigates to a off-site URL or refreshes page
	useEffect(() => {
		if (promptBeforeRedirect) {
			window.onbeforeunload = () => "You have unsaved changes";
		}
		
		return () => {
			window.onbeforeunload = null;
		};
	}, [promptBeforeRedirect]);
	
	return (
		<Modal
			title="You have unsaved changes"
			handleClose={() => {
				if (confirmResolver) {
					setShowConfirmModal(false);
					confirmResolver(false);
				}
			}}
			show={showConfirmModal}
			onSave={async() => {
				if (confirmResolver) {
					await handleSave();
					setShowConfirmModal(false);
					confirmResolver(true);
				}
			}}
			saveButtonText="Save"
			onNegative={async() => {
				if (confirmResolver) {
					setShowConfirmModal(false);
					confirmResolver(true);
				}
			}}
			negativeButtonText="Don't save"
			isSubmitting={saveButtonLoading}
		>
			Would you like to save these changes?
		</Modal>
	);
};

export default PromptBeforeNavigate;
