import { Box } from "@mui/material";
import { Description as DescriptionIcon } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";

import { DataTable, Loading, Modal } from "components";
import { formatDateTime } from "utils";

import type { ReactNode } from "react";
import type { Script, ScriptVersion, ScriptVersionId } from "types";

type ScriptVersionsModalProps = {
	isLoadingVersions: boolean;
	onClose: () => void;
	handelShowVersion: (version: ScriptVersionId | null) => void;
	script: Script;
	show: boolean;
	versions?: Array<ScriptVersion> | null;
	currentVersion: ScriptVersionId;
};

const ScriptVersionsModal = ({
	isLoadingVersions,
	onClose,
	handelShowVersion,
	script,
	show,
	versions,
	currentVersion,
}: ScriptVersionsModalProps): ReactNode => (
	<Modal
		title="Edit history for"
		dynamicTitle={script.name}
		handleClose={onClose}
		show={show}
	>
		{isLoadingVersions && (
			<Loading message="Loading versions..." spinnerSize={60} />
		)}
		{!isLoadingVersions && versions && (
			<Box component="div" sx={{ height: 400 }}>
				<DataTable
					getRowId={(row: ScriptVersion) => row.version}
					columns={[
						{
							field: "Name",
							headerName: "",
							type: "dateTime",
							valueFormatter: ({ value }) =>
								value
									? formatDateTime(value, "after")
									: "Current version",
							valueGetter: ({ row }) =>
								row.version !== currentVersion &&
								new Date(row.committedAt * 1000),
							flex: 5,
							sortable: false,
						},
						{
							field: "actions",
							type: "actions",
							getActions: ({ row }) => [
								<GridActionsCellItem
									icon={<DescriptionIcon />}
									onClick={() =>
										handelShowVersion(row.version)
									}
									label="Show version"
									showInMenu
								/>,
							],
						},
					]}
					rows={versions}
					filter={false}
					search={false}
				/>
			</Box>
		)}
	</Modal>
);

export default ScriptVersionsModal;
