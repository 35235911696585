
import { CHART_OBJECT_DEFAULTS } from "consts";
import { calcX, calcY } from "../helpers";
import type { ReactNode } from "react";
import type { ChartDotObject, ScaleFactor } from "types";

const Points = ({
	dots,
	scaleFactor,
}: {
	dots: ChartDotObject[];
	scaleFactor: ScaleFactor;
}): ReactNode => {
	return (
		<>
			{dots.map((dot) => {
				const {
					color,
					opacity,
				} = {
					...CHART_OBJECT_DEFAULTS.style,
					...dot.style,
				};
				
				return (
					<mesh
						key={dot.objId}
						position={[
							calcX(dot.position.x, scaleFactor),
							calcY(dot.position.y, scaleFactor),
							dot.position.z || 10,
						]}
					>
						<sphereGeometry
							args={[
								dot.size ?? 2,
							]}
						/>
						<meshStandardMaterial
							metalness={0.5}
							color={color}
							opacity={opacity}
							transparent={opacity < 1}
						/>
					</mesh>
				);
			})}
		</>
	);
};

export default Points;
