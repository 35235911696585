import * as THREE from "three";
import { CHART_OBJECT_DEFAULTS } from "consts";
import { calcX, calcY } from "../helpers";
import type { ReactNode } from "react";
import type { ChartPolygonObject, ScaledObject } from "types";

type PolygonProps = ChartPolygonObject & ScaledObject;

const Polygon = ({
	objId,
	scale = CHART_OBJECT_DEFAULTS.scale,
	rotation = CHART_OBJECT_DEFAULTS.rotation,
	points,
	style,
	scaleFactor,
}: PolygonProps): ReactNode => {
	const poly = new THREE.Shape();
	const firstPoint = points[0];
	
	const {
		color,
		opacity,
	} = {
		...CHART_OBJECT_DEFAULTS.style,
		...style,
	};
	
	poly.moveTo(calcX(firstPoint.x, scaleFactor), calcY(firstPoint.y, scaleFactor));
	points.forEach((point) => {
		poly.lineTo(calcX(point.x, scaleFactor), calcY(point.y, scaleFactor));
	});
	poly.lineTo(calcX(firstPoint.x, scaleFactor), calcY(firstPoint.y, scaleFactor));
	poly.autoClose = true;
	
	const geometry = new THREE.ExtrudeGeometry(
		poly,
		{
			steps: 2,
			depth: 5,
			bevelEnabled: true,
			bevelThickness: 1,
			bevelSize: 1,
			bevelOffset: 0,
			bevelSegments: 1,
		}
	);
	
	return (
		<>
			<group
				key={objId}
				scale={[scale.x, scale.y, scale.z]}
				rotation={[rotation.x, rotation.y, rotation.z]}
			>
				<mesh geometry={geometry}>
					<meshPhongMaterial
						color={color}
						opacity={opacity}
						transparent={opacity < 1}
					/>
				</mesh>
			</group>
		</>
	);
};

export default Polygon;
