import { useEffect, useMemo, useRef } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { useAppSelector } from "hooks";
import { selectRotation } from "store";
import { CHART_OBJECT_CANDLE_DEFAULTS } from "consts";
import { calcX as CalcX } from "../helpers";
import type { ReactNode } from "react";
import type { CandleStyle, ChartCandleObject, ScaleFactor } from "types";

type CandlesProps = {
	candles: Array<ChartCandleObject>,
	scaleFactor: ScaleFactor,
	minvol: number,
	maxvol: number,
	height: number,
	style?: CandleStyle,
};

const Volumes = ({
	candles,
	scaleFactor,
	minvol,
	maxvol,
	height,
	style = CHART_OBJECT_CANDLE_DEFAULTS.style,
}: CandlesProps): ReactNode => {
	const ref = useRef<THREE.Group<THREE.Object3DEventMap>>(null);
	const volumesRef = useRef<THREE.InstancedMesh>(null);
	const rotation = useAppSelector<boolean>(selectRotation);
	
	// Sets up the geo mesh, the reason for memoizing them it to
	// prevent Three loosing reference to the objects as it updates
	// a frame during the render loop - this is just a memory optimisation.
	const geometry = useMemo(() => new THREE.BoxGeometry(1, 1, 1), []);
	const material = useMemo(() => new THREE.MeshStandardMaterial({ vertexColors: true }), []);
	
	// Make ref'ing this a little simpler.
	const candleCount = candles.length;
	
	useEffect(() => {
		const volumesMesh = volumesRef.current;
		
		if (!volumesMesh || rotation) return;
		
		// A dump object that we use to build up the geometry, then copy
		// to the instance mesh once it's been built up.
		const dummy = new THREE.Object3D();
		
		const calcX = (x: number) => CalcX(x, scaleFactor);
		
		// Create the two colours we need, and something to hold the vertex colours
		// in to push to the buffered material.
		const green = new THREE.Color(style.positiveCandleColor);
		const red = new THREE.Color(style.negativeCandleColor);
		const volumeColors = new Float32Array(candleCount * 3);
		
		const scaleVolume = (volume: number) => {
			return Math.pow((volume - minvol) / (maxvol - minvol), 0.5);
		};
		
		candles.forEach((candle, i) => {
			const { o, c, v } = candle.candle;
			
			const from = candle.dtFrom();
			const to = candle.dtTo();
			
			// Calculate the body of the candle
			const width = (calcX(to) - calcX(from)) * 0.35; // Allow some gaps between volumes
			const scaledHeight = height * scaleVolume(v) * 0.1; // 10% max of the height of the viewport
			
			dummy.position.set(calcX((from + to) / 2), scaledHeight / 2, 0);
			dummy.scale.set(width, scaledHeight, 1);
			dummy.updateMatrix();
			
			volumesMesh.setMatrixAt(i, dummy.matrix);
			
			// Apply the volume colour
			(c >= o ? green : red).toArray(volumeColors, i * 3);
		});
		
		// Apply color attributes to geometries
		volumesMesh.geometry.setAttribute("color", new THREE.InstancedBufferAttribute(volumeColors, 3));
		
		// Mark mesh for update
		volumesMesh.instanceMatrix.needsUpdate = true;
		
		// Ensure the renderer knows the object has been updated
		volumesMesh.geometry.attributes.color.needsUpdate = true;
	}, [candles, rotation, minvol, maxvol, scaleFactor, style, height, candleCount]);
	
	useFrame((state) => {
		const { camera } = state;
		
		if (ref.current && camera instanceof THREE.OrthographicCamera) {
			
			// Calculate the visible height in world units, and then
			// the bottom edge.
			const vFov = (camera.top - camera.bottom) / camera.zoom;
			
			// Position the group at the bottom edge, and "scale"
			// the y axis so it never "grows" when we zoom in.
			ref.current.position.y = camera.position.y - vFov / 2;
			ref.current.scale.y = 1 / camera.zoom;
		}
	});
	
	if (rotation) return <></>;
	
	return (
		<>
			<group ref={ref}>
				<instancedMesh
					ref={volumesRef}
					args={[geometry, material, candleCount]}
				/>
			</group>
		</>
	);
};

export default Volumes;
