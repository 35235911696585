import { Box, Tab, Tabs } from "@mui/material";
import { Tooltip } from "..";

import type { ReactNode, SyntheticEvent } from "react";
import type { SvgIconComponent } from "@mui/icons-material";

type TabProps = {
	label: string;
	icon: SvgIconComponent;
};

type TabButtonsProps = {
	tabs: Array<TabProps>;
	currentTab: any;
	handleTabChange: (newTab: string) => void | Promise<void>;
};

const TabButtons = ({
	tabs,
	currentTab,
	handleTabChange,
}: TabButtonsProps): ReactNode => {
	return (
		<Box
			component="div"
			sx={(theme) => ({
				borderBottom: 2,
				borderColor: theme.palette.tabs.main,
			})}
		>
			<Tabs
				value={currentTab}
				onChange={(event: SyntheticEvent, newTab: any) => {
					handleTabChange(newTab);
				}}
				sx={(theme) => ({
					mb: -0.25,
					"& .MuiTabs-indicator": {
						backgroundColor: theme.palette.text.primary,
					},
				})}
			>
				{tabs.map((tab, idx) => {
					const { label, icon } = tab;
					const Icon = icon;
					
					return (
						<Tooltip
							key={idx}
							title={label}
							placement="top"
							value={label}
						>
							<Tab
								icon={<Icon />}
								aria-label={"Tab " + label}
								value={label}
								disableRipple
								sx={(theme) => ({
									minWidth: "unset",
									"&.Mui-selected": {
										color: theme.palette.text.primary,
									},
								})}
							/>
						</Tooltip>
					);
				})}
			</Tabs>
		</Box>
	);
};

export default TabButtons;
