import { type ReactNode, useContext, useState } from "react";
import { Alert, Slide, Snackbar, Typography } from "@mui/material";

import { ToastContext, type ToastContextType } from "context";

type ToastProps = {
	id: number;
	type?: "error" | "warning" | "info" | "success";
	value: string;
};

const defaultProps = { type: "error" };

const Toast = ({ id, type, value }: ToastProps): ReactNode => {
	const [open, setOpen] = useState(true);
	
	const { removeToast } = useContext(ToastContext) as ToastContextType;
	
	const handleClose = () => {
		setOpen(false);
		removeToast(id);
	};
	
	return (
		<Slide direction="down" in={open} mountOnEnter unmountOnExit>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={open}
				autoHideDuration={9000}
				onClose={handleClose}
				onClick={handleClose}
				className="toast"
				sx={{ cursor: "pointer" }}
			>
				<Alert variant="filled" severity={type}>
					<Typography variant="h5">{value}</Typography>
				</Alert>
			</Snackbar>
		</Slide>
	);
};

Toast.defaultProps = defaultProps;

export default Toast;
