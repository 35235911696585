import * as Yup from "yup";

const FIELD_MAX_LENGTH = 50;

export const CreateScriptFormSchema = Yup.object({
	name: Yup.string()
		.max(FIELD_MAX_LENGTH, `Maximum length of ${FIELD_MAX_LENGTH} exceeded`)
		.required("A script name is required"),
	description: Yup.string()
		.max(FIELD_MAX_LENGTH, `Maximum length of ${FIELD_MAX_LENGTH} exceeded`)
		.required("A description is required"),
});
