import { Grid, Typography } from "@mui/material";
import type { ReactNode } from "react";

type MetaDataTableRow<T> = {
	field: keyof T,
	headerName: string,
	valueFormatter?: (value: T[keyof T]) => string,
};

type MetaDataTableProps<T> = {
	title: string | null,
	metadata: T,
	dataToShow: MetaDataTableRow<T>[],
	colSpan: number,
};

const defaultProps: MetaDataTableProps<any> = {
	title: "Metadata",
	colSpan: 3,
	metadata: {},
	dataToShow: [],
};

/**
 * Renders the values of selected keys in an Object.
 * @param {string} title Defaults as Metadata if none passed.
 * @param {T} metadata Metadata to show. Has to be of type Run or Metadata.
 * @param {Array<{title: string, field: string, formatter?: Function}>} dataToShow Array of Objects with data title,
 *   field to render and formatter function if needed.
 * @param {number} colSpan space for columns defaults to 3.
 */

const MetaDataTable = <T, >({
	title,
	metadata,
	dataToShow,
	colSpan,
}: MetaDataTableProps<T>): ReactNode => (
	<>
		{title && <Typography variant="h1">{title}</Typography>}
		<Grid
			container
			justifyContent="space-between"
			spacing={2}
			mt={4}
			mb={4}
		>
			{dataToShow &&
				dataToShow.map((data, idx) => (
					<Grid item xs={12} sm={colSpan} key={idx}>
						<Typography variant="h6" color="text.secondary" pb={1}>
							{data.headerName}
						</Typography>
						<Typography>
							{((data?.valueFormatter
								? data.valueFormatter(metadata[data.field])
								: metadata[data.field]) as ReactNode) || "--"}
						</Typography>
					</Grid>
				))}
		</Grid>
	</>
);

MetaDataTable.defaultProps = defaultProps;

export default MetaDataTable;
