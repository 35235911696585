import { useEffect, useState } from "react";
import {
	Code as CodeIcon,
	ListAlt as ListAltIcon,
	Timeline as TimelineIcon,
} from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

import { CHARTS, CONSOLE, EVENTS } from "consts";
import {
	ChartContainer,
	Console,
	GridContainer,
	RunEventsTable,
	SelectField,
	TabButtons,
} from "components";
import { useGetRunEventsQuery, useLazyGetRunEventsQuery } from "services";
import { AggLevelType, MetricsType, RunEventType } from "types";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import type { FetchedData, Fetcher, Run } from "types";

interface PropsSelectedField {
	selectedRun?: string;
	setSelectedRun: Dispatch<SetStateAction<string | undefined>>;
	comparedRuns: Run[];
}

interface PropsChartField {
	instanceId: string;
	runId: string;
}

interface TabsContentProps {
	comparedRuns: Run[];
	children: (props: {
		instanceId: string,
		runId: string,
	}) => ReactNode;
	fixedHeight?: boolean;
}

const SelectedRunField = ({
	comparedRuns,
	selectedRun,
	setSelectedRun,
}: PropsSelectedField) => {
	useEffect(() => {
		if (!selectedRun) {
			setSelectedRun(comparedRuns[0] && comparedRuns[0].runId);
		}
	}, [comparedRuns, setSelectedRun, selectedRun]);
	
	if (!selectedRun) return null;
	
	return (
		<SelectField
			id="selectedRun"
			options={comparedRuns.map((run) => ({
				label: run.runId,
				value: run.runId,
			}))}
			name="selectedRun"
			defaultValue={selectedRun}
			label="Selected Run"
			onChange={(e) => setSelectedRun(e.target.value as string)}
		/>
	);
};

const ChartField = ({ instanceId, runId }: PropsChartField) => {
	const { aggLevel, mints, maxts } = useGetRunEventsQuery(
		{
			msgtype: [RunEventType.CHART],
			metrics: [MetricsType.AGG_LEVEL, MetricsType.MIN_MAX_TIMESTAMPS],
			instanceId: instanceId,
			runId: runId,
			limit: 0,
		},
		{
			selectFromResult: ({ data }) => {
				return {
					aggLevel: data?.aggLevel ?? AggLevelType.HOUR,
					mints: data?.tsFrom ?? 0,
					maxts: data?.tsTo ?? 0,
				};
			},
		}
	);
	
	const [fetch] = useLazyGetRunEventsQuery();
	const fetchRunEvents: Fetcher = async(mints: number, maxts: number): Promise<FetchedData> => {
		const  { events, minY, maxY, minLow, maxVol } = await fetch({
			msgtype: [RunEventType.CHART],
			metrics: [MetricsType.MIN_LOW_VOLUME],
			instanceId: instanceId,
			runId: runId,
			tsFrom: mints,
			tsTo: maxts,
		}).unwrap();
		
		return {
			data: events ?? [],
			minY: minY ?? 0,
			maxY: maxY ?? 0,
			minLow: minLow ?? 0,
			maxVol: maxVol ?? 0,
		};
	};
	
	return (
		<ChartContainer
			fetcher={fetchRunEvents}
			aggLevel={aggLevel}
			mints={mints}
			maxts={maxts}
		/>
	);
};

const TabContent = ({
	comparedRuns,
	children,
	fixedHeight,
}: TabsContentProps) => {
	if (comparedRuns.length === 1) {
		return (
			<>
				{children({
					instanceId: comparedRuns[0].instanceId,
					runId: comparedRuns[0].runId,
				})}
			</>
		);
	};
	
	return (
		<Grid container item spacing={4} height="100%">
			{comparedRuns.map((run) => (
				<Grid
					item
					width="100%"
					sx={{
						flexBasis: comparedRuns.length === 1 ? "100%" : "50%",
						...(fixedHeight ? { height: "100%" } : {}),
					}}
					key={run.runId}
				>
					{children({
						instanceId: run.instanceId,
						runId: run.runId,
					})}
				</Grid>
			))}
		</Grid>
	);
};

const ScriptOutput = ({
	selectedRun,
	setSelectedRun,
	comparedRuns,
}: PropsSelectedField): ReactNode => {
	const [currentOutputTab, setCurrentOutputTab] = useState(CHARTS);
	
	return (
		<Grid container item direction="column">
			<Grid item>
				<Typography variant="h2">Output</Typography>
			</Grid>
			<Grid item>
				<TabButtons
					currentTab={currentOutputTab}
					handleTabChange={(newTab) => setCurrentOutputTab(newTab)}
					tabs={[
						{
							label: CHARTS,
							icon: TimelineIcon,
						},
						{
							label: CONSOLE,
							icon: CodeIcon,
						},
						{
							label: EVENTS,
							icon: ListAltIcon,
						},
					]}
				/>
			</Grid>
			<Grid container item direction="column" spacing={1}>
				<Grid item>
					<SelectedRunField
						comparedRuns={comparedRuns}
						selectedRun={selectedRun}
						setSelectedRun={setSelectedRun}
					/>
				</Grid>
				<GridContainer>
					{currentOutputTab === CHARTS && (
						<TabContent comparedRuns={comparedRuns}>
							{({ instanceId, runId }) => <ChartField instanceId={instanceId} runId={runId} />}
						</TabContent>
					)}
					{currentOutputTab === EVENTS && (
						<TabContent
							comparedRuns={comparedRuns}
							fixedHeight
						>
							{({ instanceId, runId }) => <RunEventsTable instanceId={instanceId} runId={runId} />}
						</TabContent>
					)}
					{currentOutputTab === CONSOLE && (
						<TabContent
							comparedRuns={comparedRuns}
							fixedHeight
						>
							{({ instanceId, runId }) => <Console
								instanceId={instanceId}
								runId={runId}
								showInGrid
							/>}
						</TabContent>
					)}
				</GridContainer>
			</Grid>
		</Grid>
	);
};

export default ScriptOutput;
