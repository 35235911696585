import { Box, Button, Stack, Typography } from "@mui/material";

import { MetaDataTable, Modal, Pill, TextField } from "components";
import { formatDate } from "utils";

import type { ReactNode } from "react";
import type { ProviderKey } from "types";

type ProviderKeyInfoProps = {
	onClose: () => void,
	handleRevokeKey: () => void,
	providerKey: ProviderKey,
	show: boolean,
};

const ProviderKeyInfo = ({
	onClose,
	handleRevokeKey,
	providerKey,
	show,
}: ProviderKeyInfoProps): ReactNode => {
	const providerKeyStatusPills = {
		ACTIVE: <Pill color="success" text="Active" />,
		REVOKED: <Pill color="error" text="Revoked" />,
	};
	
	return (
		<Modal
			title="Viewing"
			dynamicTitle={providerKey.name}
			handleClose={onClose}
			show={show}
			closeButtonText="Close"
		>
			<Button
				className="provider-key-info__title-button"
				color="error"
				variant="text"
				onClick={handleRevokeKey}
				sx={{ float: "right" }}
			>
				Revoke key
			</Button>
			<Stack>
				<TextField
					disabled
					label="API key name"
					id="test"
					defaultValue={providerKey.name}
					modal
				/>
				<TextField
					id="API key"
					disabled
					modal
					label="API key"
					defaultValue={providerKey.apiKey}
				/>
				<Stack pt={1} spacing={2}>
					<Typography variant="h6" color="text.secondary">
						Status
					</Typography>
					<Box component="div">
						{providerKeyStatusPills[providerKey.state]}
					</Box>
				</Stack>
				<MetaDataTable<ProviderKey>
					title={null}
					colSpan={4}
					metadata={providerKey}
					dataToShow={[
						{
							field: "createdAt",
							headerName: "Created",
							valueFormatter: (value) =>
								formatDate(value as ProviderKey["createdAt"]),
						},
						{
							field: "activeAt",
							headerName: "Active",
							valueFormatter: (value) =>
								formatDate(value as ProviderKey["activeAt"]),
						},
						{
							field: "expiresAt",
							headerName: "Expires",
							valueFormatter: (value) =>
								formatDate(value as ProviderKey["expiresAt"]),
						},
					]}
				/>
			</Stack>
		</Modal>
	);
};

export default ProviderKeyInfo;
