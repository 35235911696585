import { ReactNode, useEffect, useState } from "react";
import { useCallback } from "react";
import CogIcon from "@mui/icons-material/Settings";

import {
	BigCard,
	CreateProviderKeyForm,
	Loading,
	ProviderKeyIndexTable,
} from "components";
import { useGetKeysForProviderQuery, useGetProvidersQuery } from "services";
import type {
	ContainerProps,
	Provider,
	ProviderId,
	ProviderKey,
} from "types";

type SelectFromProviderResult = {
	providers: Provider[];
	isLoadingProviders: boolean;
};

type SelectFromProviderKeyResult = {
	providerKeys: ProviderKey[];
	isLoadingKeys: boolean;
};

const SettingsContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	const [isCreatingKey, setIsCreatingKey] = useState(false);
	
	// RTK Query
	const { providers, isLoadingProviders } = useGetProvidersQuery(undefined, {
		selectFromResult: ({ data, isLoading }): SelectFromProviderResult => ({
			isLoadingProviders: isLoading,
			providers: data?.providers ?? [],
		}),
	});
	
	// currently gets all keys for a given provider, Polygon assumed
	const { providerKeys, isLoadingKeys } = useGetKeysForProviderQuery(
		(providers && providers.length > 0
			? providers[0].providerId
			: "") as ProviderId,
		{
			skip: providers.length === 0,
			selectFromResult: ({
				data,
				isLoading,
			}): SelectFromProviderKeyResult => ({
				isLoadingKeys: isLoading,
				providerKeys: data?.keys ?? [],
			}),
		}
	);
	
	const isLoading = isLoadingProviders || isLoadingKeys;
	
	const toggleCreate = useCallback(() => {
		setIsCreatingKey(!isCreatingKey);
	}, [isCreatingKey]);
	
	useEffect(() => {
		setBreadcrumbs([{ text: "Settings" }]);
		setHeaderActions({
			create: [
				{
					label: "Add a key",
					onClick: toggleCreate,
					createIcon: true,
				},
			],
		});
	}, [setBreadcrumbs, setHeaderActions, toggleCreate]);
	
	return (
		<>
			{(isLoadingProviders || isLoadingKeys) && (
				<Loading message="Loading your configuration..." />
			)}
			{providerKeys.length === 0 && !isLoading && (
				<BigCard
					ctaLinkText="Create one now"
					handleClick={toggleCreate}
					icon={CogIcon}
					message="You don't have keys configured for any trading providers."
				/>
			)}
			{providerKeys.length > 0 && !isLoading && (
				<ProviderKeyIndexTable providerKeys={providerKeys} />
			)}
			<CreateProviderKeyForm
				handleFormClose={toggleCreate}
				providers={providers ?? []}
				show={isCreatingKey}
			/>
		</>
	);
};

export default SettingsContainer;
