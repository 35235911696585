import {
	FormControl,
	TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import {
	DateTimePicker,
	DateTimePickerProps,
	LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { TextField, TextFieldProps } from "components";

import type { ComponentType, ReactNode } from "react";

interface DateTimeFieldProps {
	id?: string;
	label?: string;
	value?: any;
	modal?: boolean;
	fullWidth?: boolean;
	margin?: "normal" | "dense" | "none" | undefined;
	onChange?: (value: any) => void;
}

const defaultProps = {
	modal: false,
	fullWidth: true,
	margin: "normal",
};

const DateTimeField = ({
	id,
	label,
	modal,
	value,
	fullWidth,
	margin,
	onChange,
}: DateTimeFieldProps & DateTimePickerProps<Date>): ReactNode => (
	<FormControl variant="standard" fullWidth={fullWidth} margin={margin}>
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DateTimePicker
				label={label}
				value={value}
				format="DD/MM/YYYY HH:mm"
				disableFuture
				onChange={onChange}
				slots={{
					textField: TextField as ComponentType<MuiTextFieldProps>,
				}}
				slotProps={{
					textField: {
						id,
						modal,
					} as TextFieldProps,
				}}
			/>
		</LocalizationProvider>
	</FormControl>
);

DateTimeField.defaultProps = defaultProps;

export default DateTimeField;
