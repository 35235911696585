import {
	Tooltip as MuiTooltip,
	type TooltipProps as MuiTooltipProps,
	type Shadows,
} from "@mui/material";
import { styled } from "@mui/system";
import { tooltipClasses } from "@mui/material/Tooltip";

import type { Typography } from "@mui/material/styles/createTypography";

interface TooltipProps extends MuiTooltipProps {
	className?: string;
	value?: string;
}

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
	<MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		...(theme.typography as Typography).h5,
		backgroundColor: theme.palette.common.white,
		color: theme.palette.tooltip.contrastText,
		boxShadow: (theme.shadows as Shadows)[8],
		borderRadius: 50,
		paddingLeft: 16,
		paddingRight: 16,
		lineHeight: 1,
		border:
			theme.palette.mode === "dark"
				? "none"
				: `1px solid ${theme.palette.tooltip.main}`,
	},
}));
