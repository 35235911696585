import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import type { PaletteMode } from "@mui/material";
import type { AssetIntervals } from "types";

export const THEME_DARK: PaletteMode = "dark";
export const THEME_LIGHT: PaletteMode = "light";

export interface UserState {
	theme: PaletteMode;
	favouriteAssets: AssetIntervals[];
	recentlyViewedAssets: AssetIntervals[];
}

const initialState: UserState = {
	theme: THEME_DARK,
	favouriteAssets: [] as AssetIntervals[],
	recentlyViewedAssets: [] as AssetIntervals[],
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		toggleTheme: (state): void => {
			state.theme = state.theme === THEME_DARK ? THEME_LIGHT : THEME_DARK;
		},
		favouriteAsset: (
			state,
			action: PayloadAction<AssetIntervals>
		): void => {
			const { payload } = action;
			
			state.favouriteAssets.push(payload);
		},
		unFavouriteAsset: (
			state,
			action: PayloadAction<AssetIntervals>
		): void => {
			const { payload } = action;
			const favouriteAssets = state.favouriteAssets.filter(
				(asset: AssetIntervals) => asset.ticker !== payload.ticker
			);
			
			state.favouriteAssets = favouriteAssets;
		},
		setRecentlyViewedAsset: (
			state,
			action: PayloadAction<AssetIntervals>
		): void => {
			const { payload } = action;
			
			// Clear if already there.
			state.recentlyViewedAssets = state.recentlyViewedAssets.filter(
				(asset: AssetIntervals) => asset.ticker !== payload.ticker
			);
			
			state.recentlyViewedAssets.unshift(payload);
			
			// Limit recently view items to 5.
			if (state.recentlyViewedAssets.length > 5)
				state.recentlyViewedAssets.pop();
		},
	},
});

export const {
	favouriteAsset,
	unFavouriteAsset,
	setRecentlyViewedAsset,
	toggleTheme,
} = userSlice.actions;

export const selectUserTheme = (state: RootState): PaletteMode =>
	state.user.theme;
export const selectFavouriteAssets = (state: RootState): AssetIntervals[] =>
	state.user.favouriteAssets;
export const selectRecentlyViewedAssets = (
	state: RootState
): AssetIntervals[] => state.user.recentlyViewedAssets;

export default userSlice.reducer;
