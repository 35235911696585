import { type ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { Add as AddIcon, Menu as MenuIcon } from "@mui/icons-material";
import LoadingButton, {
	type LoadingButtonTypeMap,
} from "@mui/lab/LoadingButton";

import {
	AssetDrawer,
	Breadcrumbs,
	type MenuItemProps,
	PopoverMenuButton,
	Toast,
} from "components";
import {
	AssetsContext,
	type AssetsContextValue,
	ToastContext,
	type ToastContextType,
} from "context";

import type {
	AssetIntervals,
	Breadcrumb,
	HeaderAction,
	HeaderActions,
} from "types";

type AppContentHeaderProps = {
	breadcrumbs: Array<Breadcrumb>;
	headerActions?: HeaderActions;
};

const AppContentHeader = ({
	breadcrumbs,
	headerActions,
}: AppContentHeaderProps): ReactNode => {
	// CONTEXT & OTHER HOOKS
	const { isAssetDrawerOpen, toggleAssetDrawer } = useContext(AssetsContext) as AssetsContextValue;
	const { toasts } = useContext(ToastContext) as ToastContextType;
	const navigate = useNavigate();
	
	const handleAssetClicked = (asset: AssetIntervals): void => {
		navigate(`/stock-data/${asset.ticker}`, {
			replace: false,
		});
		toggleAssetDrawer();
	};
	
	const createButton = (
		{ label, onClick, loading, disabled, createIcon }: HeaderAction,
		idx: number
	): ReactNode => {
		const buttonProps = {
			key: idx,
			onClick,
			disabled: disabled ?? false,
			startIcon: createIcon ? <AddIcon /> : null,
			variant: "contained",
			color: "success",
			sx: { ml: 3 },
		} as Partial<LoadingButtonTypeMap>;
		
		if (typeof loading === "boolean") {
			return (
				<LoadingButton {...buttonProps} loading={loading}>
					{label}
				</LoadingButton>
			);
		}
		
		return <Button {...buttonProps}>{label}</Button>;
	};
	
	const createActionButton = (actions: HeaderAction[]) => {
		const menutems: MenuItemProps[] = actions.map(
			(action: HeaderAction) => {
				return {
					handleClick: action.onClick,
					text: action.label,
				};
			}
		);
		
		return <PopoverMenuButton items={menutems} label="Actions" />;
	};
	
	return (
		<>
			<Grid
				component="header"
				container
				alignItems="center"
				sx={(theme) => ({ height: "8.8rem", px: 4 })}
			>
				<Grid
					container
					item
					sm
					md={4}
					justifyContent="flex-start"
					alignItems="center"
				>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Grid>
				<Grid
					container
					item
					sm
					md={8}
					justifyContent="flex-end"
					alignItems="center"
				>
					{headerActions &&
						headerActions.actions &&
						createActionButton(headerActions.actions)}
					{headerActions &&
						headerActions.create &&
						headerActions.create.map(
							(action: HeaderAction, idx) => {
								return createButton(action, idx);
							}
						)}
					<MenuIcon
						aria-label="Open asset drawer"
						onClick={() => toggleAssetDrawer()}
						sx={{
							ml: 4,
							cursor: "pointer",
						}}
					/>
				</Grid>
			</Grid>
			<AssetDrawer
				handleAssetClicked={handleAssetClicked}
				isOpen={isAssetDrawerOpen}
				onClose={toggleAssetDrawer}
			/>
			{toasts.map((toast, idx) => {
				return (
					<Toast
						key={`toast-${idx}`}
						id={idx}
						type={toast.type}
						value={toast.message}
					/>
				);
			})}
		</>
	);
};

export default AppContentHeader;
