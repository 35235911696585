import {
	type ReactNode,
	createContext,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectError, setError } from "store";

type Toast = {
	type?: "error" | "warning" | "info" | "success";
	message: string;
};

export type ToastContextType = {
	createToast: (message: string, type?: Toast["type"]) => void;
	removeToast: (id: number) => void;
	toasts: Array<Toast>;
};

export const ToastContext = createContext<ToastContextType | undefined>(
	undefined
);

type ToastProviderProps = {
	children?: ReactNode | ReactNode[];
};

export function ToastProvider({ children }: ToastProviderProps): ReactNode {
	const dispatch = useAppDispatch();
	const reduxError = useAppSelector(selectError);
	const [toasts, setToasts] = useState<Toast[]>([]);
	
	const createToast = useCallback((message: string, type?: Toast["type"]) => {
		setToasts((currentState) => [...currentState, { message, type }]);
	}, []);
	
	const removeToast = useCallback((id: number) => {
		setToasts((currentState) =>
			currentState.filter((_, idx) => idx !== id)
		);
	}, []);
	
	// This lets errors from outside this context (i.e. redux) to
	// be pushed into the main error handling of the application.
	useEffect(() => {
		if (reduxError === "") return;
		
		setToasts((currentState) => [
			...currentState,
			{ message: reduxError as string, type: "error" },
		]);
		// Clear, after we have responded to the state change.
		dispatch(setError(""));
	}, [reduxError, dispatch]);
	
	return (
		<ToastContext.Provider
			value={{
				createToast,
				removeToast,
				toasts,
			}}
		>
			{children}
		</ToastContext.Provider>
	);
}
