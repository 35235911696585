import * as Yup from "yup";

const FIELD_MAX_LENGTH = 50;

export const CreateAndEditInstanceFormSchema = Yup.object({
	name: Yup.string()
		.max(FIELD_MAX_LENGTH, `Maximum length of ${FIELD_MAX_LENGTH} exceeded`)
		.required("An instance name is required"),
	scriptId: Yup.string().required("You need to specify which script to run"),
	versionId: Yup.string().required(
		"You need to specify which version to run"
	),
	params: Yup.object(),
});
