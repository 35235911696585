import { useFormik } from "formik";

import { Alert, Modal, TextField } from "components";
import { type ReactNode, useState } from "react";
import { useCreateScriptMutation } from "services";
import { CreateScriptFormSchema } from "./CreateScriptForm.schema";

interface FormInputsType {
	name: string;
	description: string;
}

const FORM_INITIAL_STATE: FormInputsType = {
	name: "",
	description: "",
};

type CreateScriptFormProps = {
	handleFormClose: () => void;
	show: boolean;
};

const CreateScriptForm = ({
	handleFormClose,
	show,
}: CreateScriptFormProps): ReactNode => {
	const [formError, setFormError] = useState<string>("");
	
	const [createScript, { isLoading: isSubmitting }] = useCreateScriptMutation();
	
	const formik = useFormik({
		initialValues: FORM_INITIAL_STATE,
		validationSchema: CreateScriptFormSchema,
		onSubmit: (formData) => createScript(formData)
			.unwrap()
			.then((script) => handleFormClose())
			.catch((e) => setFormError(e.message)),
		validateOnChange: true,
	});
	
	return (
		<Modal
			title="Create a script"
			handleClose={handleFormClose}
			show={show}
			onSave={formik.handleSubmit}
			saveButtonText="Create"
			isValid={formik.dirty && formik.isValid}
			isSubmitting={isSubmitting}
		>
			{formError && <Alert>{formError}</Alert>}
			<form onSubmit={formik.handleSubmit}>
				<TextField
					modal
					id="name"
					name="name"
					label="Script name"
					placeholder="Enter a name to identify your new script..."
					value={formik.values.name}
					onChange={formik.handleChange}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={
						formik.touched.name && formik.errors.name
							? formik.errors.name
							: undefined
					}
				/>
				<TextField
					modal
					id="description"
					name="description"
					label="Description"
					placeholder="A description of what your script does..."
					value={formik.values.description}
					onChange={formik.handleChange}
					error={
						formik.touched.description &&
						Boolean(formik.errors.description)
					}
					helperText={
						formik.touched.description && formik.errors.description
							? formik.errors.description
							: undefined
					}
				/>
			</form>
		</Modal>
	);
};

export default CreateScriptForm;
