export const COMPARE_COLORS = [
	"charts.main",
	"charts.light",
	"charts.hover",
	"charts.dark",
];

export const METRICS = [
	{
		name: "endBalance",
		label: "End Balance ($)",
	},
	{
		name: "grossProfit",
		label: "Gross Profit ($)",
	},
	{
		name: "grossLoss",
		label: "Gross Loss ($)",
	},
	{
		name: "gcr",
		label: "GCR",
	},
	{
		name: "ncr",
		label: "NCR",
	},
	{
		name: "orderCount",
		label: "Order Count",
	},
	{
		name: "winningOrderCount",
		label: "Winning Order Count",
	},
	{
		name: "losingOrderCount",
		label: "Losing Order Count",
	},
	{
		name: "winRatio",
		label: "Win Ratio",
	},
	{
		name: "profitFactor",
		label: "Profit Factor",
	},
	{
		name: "avgTradeWindowSecs",
		label: "Avg Trade Window (Secs)",
	},
	{
		name: "avgGcr",
		label: "Avg GCR",
	},
	{
		name: "avgNcr",
		label: "Avg NCR",
	},
	{
		name: "maxDrawdown",
		label: "Max Drawdown ($)",
	},
	{
		name: "maxPushup",
		label: "Max Pushup ($)",
	},
];
