import { useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import {
	AspectRatio,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	ViewInAr,
} from "@mui/icons-material";
import { checkOverlap, getFormattedDate } from "utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectRotation, toggleRotation, triggerRequestScaleY } from "store";
import { CHART_OBJECT_CANDLE_DEFAULTS } from "consts";
import { AggLevelType } from "types";
import { sigPointsTs } from ".";
import type { MutableRefObject, ReactNode } from "react";
import type { ChartPos, MousePos } from "types";

interface TimestampMap {
	ts: number;
	timeF: string;
	timeF2: string;
	day: string;
	month: string;
	year: string;
	offset: number;
}

const ALL_KEYS = ["timeF", "day", "month", "year"];

type AxisProps = {
	mousePos?: MousePos;
	maxSteps: number;
	label?: string;
	chartPos: MutableRefObject<ChartPos>;
};

const formatDateToTime = (r: Date) => {
	const formatted = getFormattedDate(r, "HH:mm:ss");
	
	if (formatted.slice(-3) === ":00") {
		return formatted.slice(0, -3);
	}
	
	return formatted;
};

const mapTimestamp = (r: number, offset: number): TimestampMap => {
	const currentDate = new Date(r * 1000);
	
	return {
		ts: r,
		timeF: formatDateToTime(currentDate),
		timeF2: formatDateToTime(currentDate),
		day: getFormattedDate(currentDate, "ddd DD"),
		month: getFormattedDate(currentDate, "MMM"),
		year: getFormattedDate(currentDate, "YYYY"),
		offset,
	};
};

export const XAxis = ({
	mousePos,
	maxSteps,
	label,
	chartPos,
}: AxisProps): ReactNode => {
	const dispatch = useAppDispatch();
	const rotation = useAppSelector<boolean>(selectRotation);
	
	const [show, setShow] = useState<boolean>(false);
	const [ticks, setTicks] = useState<TimestampMap[]>([]);
	const [minPos, setMinPos] = useState<number>(0);
	const [labelKeys, setLabelKeys] = useState<string[]>([]);
	const [skippedLabelKeys, setSkippedLabelKeys] = useState<string[]>([]);
	
	const color = CHART_OBJECT_CANDLE_DEFAULTS.style.positiveCandleColor;
	const {
		x,
		mints,
		maxts,
		minX,
		maxX,
		minXOffset,
		maxXOffset,
		width,
		vWidth,
		timescale,
		aggLevel,
	} = chartPos.current;
	
	// We need to find the x position of the mouse, to work out
	// in world space where we are mouse is over, from which we
	// can then get the offset that we have worked out from any
	// timescale changes that we may have made when plotting
	// chart objects.
	const [layerX, xPos] = useMemo(() => {
		if (!show || !timescale) return [0, 0];
		
		const { layerX } = mousePos || { layerX: 0 };
		
		const mouseX = (layerX / width) * vWidth;
		const worldX = mouseX + x - vWidth / 2;
		
		const day = (timescale ?? []).find(
			(ts) => ts.start <= worldX && ts.end >= worldX
		);
		
		// Calculate the value for the x pos of the mouse.
		let xPos = minX + layerX / (width / (maxX - minX)) - (day?.offset || 0);
		
		// Then round down this value so that it stay consistent when
		// the user is mousing over an agg of a particular type.
		switch (aggLevel) {
			case AggLevelType.MIN:
				xPos = Math.floor(xPos / 60) * 60;
				break;
			case AggLevelType.HOUR:
				xPos = Math.floor(xPos / 3600) * 3600;
				break;
			case AggLevelType.DAY:
				xPos = Math.floor(xPos / 86400) * 86400;
				break;
		}
		
		return [layerX, xPos];
	}, [aggLevel, mousePos, x, minX, maxX, show, width, vWidth, timescale]);
	
	useEffect(() => {
		setShow(false);
		
		const handler = setTimeout(() => {
			const minV = minXOffset || minX;
			const maxV = maxXOffset || maxX;
			
			const ticks = (
				timescale && timescale.length > 0 ? timescale : [{ openTs: minV, endTs: maxV, offset: 0 }]
			).reduce((acc, { openTs, endTs, offset }) => {
				if (checkOverlap(minV, maxV, openTs, endTs)) {
					const start = Math.max(minV, openTs);
					const end = Math.min(maxV, endTs);
					
					acc.push(
						...sigPointsTs({
							minV: start,
							maxV: end,
							maxDivisions: Math.floor(
								((end - start) / (maxX - minX)) * maxSteps
							),
						}).map((r) => mapTimestamp(r, offset))
					);
				}
				
				return acc;
			}, [] as TimestampMap[]);
			
			const sec = (timescale ?? []).find(
				({ openTs, endTs }) => openTs <= mints && endTs >= mints
			);
			
			setMinPos((width * (mints + (sec?.offset || 0) - minX)) / (maxX - minX));
			
			const skipLabels: { [key: string]: boolean } = {};
			
			ALL_KEYS.forEach((key) => {
				if (
					[...new Set(ticks.map((t) => t[key as keyof TimestampMap]))].length <=
					1
				) {
					skipLabels[key] = true;
				}
			});
			
			setLabelKeys(ALL_KEYS.filter((key) => !skipLabels[key]));
			setSkippedLabelKeys(ALL_KEYS.filter((key) => skipLabels[key]));
			setTicks(ticks);
			setShow(true);
		}, 500);
		
		return () => {
			clearTimeout(handler);
		};
	}, [
		minX,
		maxX,
		minXOffset,
		maxXOffset,
		maxSteps,
		mints,
		timescale,
		width,
		setShow,
		setTicks,
		setLabelKeys,
		setSkippedLabelKeys,
		aggLevel,
	]);
	
	return (
		<Grid
			container
			item
			xs
			direction="column"
			sx={{ position: "relative", mt: 1 }}
		>
			<Grid container item direction="row" xs sx={{ minHeight: "32px" }}>
				{show &&
					!rotation &&
					ticks.map((t, i) => {
						return (
							<Grid
								item
								key={i}
								sx={{
									position: "absolute",
									translate: "-45% 0",
									whiteSpace: "nowrap",
									left: (width * (t.ts + t.offset - minX)) / (maxX - minX),
								}}
							>
								{labelKeys.map((key) => t[key as keyof TimestampMap]).join(" ")}
							</Grid>
						);
					})}
			</Grid>
			{show && !rotation && (
				<Grid container item direction="row" xs>
					<Grid
						item
						sx={{
							position: "absolute",
							translate: "-45% 0",
							left: minPos,
						}}
					>
						<Tooltip title="Lower limit of data">
							<KeyboardArrowRight fontSize="small" />
						</Tooltip>
					</Grid>
					<Grid
						item
						sx={{
							position: "absolute",
							translate: "-45% 0",
							left: (width * (maxts - minX)) / (maxX - minX),
						}}
					>
						<Tooltip title="Upper limit of data">
							<KeyboardArrowLeft fontSize="small" />
						</Tooltip>
					</Grid>
				</Grid>
			)}
			<Grid container item justifyContent="center" xs={2}>
				<Grid item xs>
					<Tooltip title="Enabled/Disable rotation">
						<IconButton
							onClick={() => dispatch(toggleRotation())}
							sx={(theme) => ({
								color: !rotation
									? "text.secondary"
									: theme.palette.mode === "light"
										? "info.main"
										: "text.primary",
							})}
						>
							<ViewInAr />
						</IconButton>
					</Tooltip>
					<Tooltip title="Fit to height">
						<IconButton
							onClick={() => !rotation && dispatch(triggerRequestScaleY())}
							sx={() => ({
								color: "text.secondary",
							})}
						>
							<AspectRatio />
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid
					item
					xs={4}
					sx={{ textAlign: "center", paddingTop: 1, whiteSpace: "nowrap" }}
				>
					{show &&
						!rotation &&
						(label
							? label.toLocaleUpperCase()
							: skippedLabelKeys.map(
								(key) => ticks[0] ? ticks[0][key as keyof TimestampMap] : ""
							).join(" "))}
				</Grid>
				<Grid item xs />
			</Grid>
			{show && layerX && !rotation ? (
				<Box
					component="div"
					alignItems="center"
					sx={{
						position: "absolute",
						backgroundColor: color,
						translate: "-45% 0",
						zIndex: 15,
						whiteSpace: "nowrap",
						left: layerX,
						padding: 0.5,
					}}
				>
					{labelKeys
						.map((key) => mapTimestamp(xPos, 0)[key as keyof TimestampMap])
						.join(" ")}
				</Box>
			) : null}
		</Grid>
	);
};
