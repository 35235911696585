import { streamAsyncIteratorByLine } from "utils";
import { toSeconds } from "utils";
import { api } from ".";
import type {
	AggLevelType,
	ApiQuery,
	ChartCandleObject,
	ExecuteSQLRequest,
	FetchedData,
	Query,
	QueryData,
	QueryDataIndex,
	QueryId,
	QueryResponseRow,
	QueryResult,
	QueryResults,
	RunEvent,
} from "types";

const convertToQuery = (query: ApiQuery): Query => ({
	id: query.executionId,
	groupId: query.executionId,
	name: query.queryName,
	code: query.queryCode || "SELECT * FROM ticker_aggs LIMIT 15;",
	commitSHA: query.commitSHA,
	startedAt: query.startedAt,
	completedAt: query.completedAt,
	createdAt: query.createdAt,
});

export const query = api
	.enhanceEndpoints({
		addTagTypes: ["queries", "query"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getQueries: build.query<Query[], void>({
				query() {
					return {
						url: "/queries",
						method: "GET",
					};
				},
				transformResponse: (response: QueryData) => {
					return response.queries.map(query => convertToQuery(query));
				},
				providesTags: ["queries"],
			}),
			getQuery: build.query<Query, QueryId>({
				query(queryId: QueryId) {
					return {
						url: `/queries/${queryId}`,
						method: "GET",
					};
				},
				transformResponse: (response: ApiQuery) => {
					return convertToQuery(response);
				},
				providesTags: ["query"],
			}),
			deleteQuery: build.mutation<void, QueryId>({
				query(queryId: QueryId) {
					return {
						url: `/queries/${queryId}`,
						method: "DELETE",
					};
				},
				invalidatesTags: ["queries", "query"],
			}),
			createQuery: build.mutation<Query, Partial<Query>>({
				query(body: Partial<Query>) {
					return {
						url: "/queries",
						method: "POST",
						body: {
							queryName: body.name,
							queryCode: body.code,
							groupId: "",
						},
					};
				},
				transformResponse: ({ content: query }: Partial<{content: ApiQuery}>) => {
					return convertToQuery(query || {} as ApiQuery);
				},
				invalidatesTags: ["queries", "query"],
			}),
			updateQuery: build.mutation<Query, Partial<Query>>({
				query(body: Partial<Query>) {
					return {
						url: `/queries/${body.id}`,
						method: "PUT",
						body: {
							queryName: body.name,
							queryCode: body.code,
						},
					};
				},
				transformResponse: (query: ApiQuery) => {
					return convertToQuery(query);
				},
				invalidatesTags: ["queries", "query"],
			}),
			executeQuery: build.query<QueryResults, ExecuteSQLRequest>({
				/*
					The result of this query is streamed and returned line by line.
					
					This is a bit of a hacky implementation using an onChunk() method passed with the query args to send
					chunks back as streaming isn't officially supported by RTK.
					See https://github.com/reduxjs/redux-toolkit/issues/3701
					
					onChunk() is called with a list of candles returned by the endpoint so far, at the end a final
					result with all candles is also returned.
				*/
				query(queryArguments: ExecuteSQLRequest) {
					return {
						url: `/queries/execute`,
						method: "POST",
						body: {
							query: queryArguments.sql,
						},
						responseHandler: async(response) => {
							if (!response.body) throw new Error("Error while running SQL query");
							
							let results: QueryResults = {};
							
							for await (const line of streamAsyncIteratorByLine(response.body)) {
								// Inefficient, it returns each line individually regardless how many are in a chunk
								const row = JSON.parse(line) as QueryResponseRow;
								
								if (row.status === 200) {
									convertQueryRowData(results, row.content);
								}
							}
							
							return results;
						},
					};
				},
				serializeQueryArgs: ({ queryArgs }) => {
					// This custom serializer is needed to remove the onChunk method that cant be serialized
					return queryArgs.sql;
				},
			}),
		}),
	});

export const {
	useGetQueriesQuery,
	useDeleteQueryMutation,
	useCreateQueryMutation,
	useUpdateQueryMutation,
	useExecuteQueryQuery,
	useGetQueryQuery,
} = query;

// This will take candle data when it comes in row-by-row, and determine
// the various min/max values from the candle, and convert that to
// something that the chart can then use to visualise the data.
const convertQueryRowData  = (res: QueryResults, data: RunEvent) => {
	const candle = (data.params?.chartcmd?.obj as ChartCandleObject).candle;
	
	if (!candle) return;
	
	const sym = candle.symbol;
	const agg = candle.agg_level as AggLevelType;
	
	if (!sym ||  !agg) return;
	
	res[sym] = res[sym] || {};
	
	res[sym][agg] = res[sym][agg] || {
		data: [],
		mints: 0,
		maxts: 0,
		index: {} as QueryDataIndex,
	};
	
	if (res[sym][agg].mints === 0) {
		res[sym][agg].mints = toSeconds(candle.dt_from_ms);
	}
	
	res[sym][agg].mints = Math.min(res[sym][agg].mints, toSeconds(candle.dt_from_ms));
	res[sym][agg].maxts = Math.max(res[sym][agg].maxts, toSeconds(candle.dt_to_ms));
	
	// Add the timestamp here, this is always 0 from the API
	data.timestamp = candle.dt_from_ms / 1000;
	
	// Sort the data as is comes in, this will slow things down, but will
	// make it much faster for the user to interact with.
	res[sym][agg].data.push(data);
	res[sym][agg].data.sort((a, b) => a.timestamp - b.timestamp);
	
	// Yes, each time we get a new row, we have to create a new index lookup.
	// This should speed things elsewhere, fingers crossed.
	res[sym][agg].index = res[sym][agg].data.reduce((acc, item: RunEvent, index) => {
		acc[item.timestamp] = index;
		
		return acc;
	}, {} as QueryDataIndex);
};

// This is a universal fetcher that can be used by components that need to
// "fetch" data with the chart, and not overload it too much. It relies on
// the index, and will calculate the basic window data for the chart to use,
// i.e. the minY/maxY etc.
export const fetchQueryData = async(mints: number, maxts: number, result: QueryResult): Promise<FetchedData> => {
	const { data, index } = result;
	let fetchedData: FetchedData = {
		data: [],
		minY: 0,
		maxY: 0,
		minLow: 0,
		maxVol: 0,
	};
	
	if (data.length === 0) return fetchedData;
	
	const { start, end } = getIndexes(index, mints, maxts);
	const selection: RunEvent[] = data.slice(start, end + 1);
	
	fetchedData = selection.reduce((acc, item: RunEvent) => {
		const candle = (item.params?.chartcmd?.obj as ChartCandleObject).candle;
		
		if (!candle) return acc;
		
		if (acc.minY === 0) {
			acc.minY = candle.l;
		}
		
		acc.minY = acc.minLow = Math.min(acc.minY, candle.l);
		acc.maxY = Math.max(acc.maxY, candle.h);
		acc.maxVol = Math.max(acc.maxVol, candle.v);
		
		acc.data.push(item);
		
		return acc;
	}, fetchedData);
	
	return new Promise(
		(resolve) => setTimeout(() => resolve(fetchedData), 500)
	);
};

// Gets the start and end indexes from the QueryDataIndex that we create
// as the data is coming in. This should be "fast" if we're lucky enough
// to get exact timestamps that are in our data set, otherwise, we have
// to search - which is then carried out the the findNearest function.
const getIndexes = (index: QueryDataIndex, mints: number, maxts: number) => {
	const start = index[mints] !== undefined
		? index[mints]
		: findNearest(index, mints, true);
	
	const end = index[maxts] !== undefined
		? index[maxts]
		: findNearest(index, maxts, false);
	
	return { start, end };
};

// Search the index for matches - this could possibly have been done on
// the data array, but perhaps this will be faster, and more throw away,
// it's very hard to tell. It does mean above that we can sometimes get
// direct matches, which will be really fast.
const findNearest = (index: QueryDataIndex, timestamp: number, start: boolean): number => {
	const timestamps = Object.keys(index).map(Number);
	
	if (start) {
		const next = timestamps.find(t => t >= timestamp);
		
		return next ? index[next] : timestamps.length - 1;
	} else {
		const prev = timestamps.reverse().find(t => t <= timestamp);
		
		return prev ? index[prev] : 0;
	}
};