import { Grid, Switch } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

import type { ReactNode } from "react";

type Props = {
	toggle: () => void;
	isDarkMode: boolean;
};

function DarkModeSwitch({ isDarkMode, toggle }: Props): ReactNode {
	return (
		<Grid
			container
			justifyContent="flex-end"
			alignItems="center"
			pt={3}
		>
			<Grid item>
				<LightModeIcon />
			</Grid>
			<Grid item>
				<Switch
					checked={isDarkMode}
					color="primary"
					onChange={toggle}
				/>
			</Grid>
			<Grid>
				<DarkModeIcon />
			</Grid>
		</Grid>
	);
}

export default DarkModeSwitch;