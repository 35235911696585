import { ChartCmd } from "./chart.types";

export type RunId = string;
export type RunEventId = string;

export interface Run {
	instanceId: string;
	runId: RunId;
	createdAt: number;
	startedAt: number;
	stoppingAt: number;
	stoppedAt: number;
	scriptId: string;
	params?: RunParams;
};

export type RunParams = {
	[key: string]: string,
};

export enum RunEventType {
	STARTED = "STARTED",
	HEARTBEAT = "HEARTBEAT",
	STDOUT = "STDOUT",
	STDERR = "STDERR",
	CHART = "CHART",
	COMPLETED = "COMPLETED",
	CANCELLED = "CANCELLED",
	REJECTED = "REJECTED",
}

export enum AggLevelType {
	SEC = "second",
	MIN = "minute",
	HOUR = "hour",
	DAY = "day",
}

export type RunEventParams = {
	line?: string,
	seq?: number,
	chartcmd?: ChartCmd,
};

export type RunEvent = {
	eventId: RunEventId,
	eventType: RunEventType,
	params: RunEventParams,
	runId: RunId,
	timestamp: number,
};

export type Runs = {
	instanceId: string,
	runCount: number,
	scriptId: string,
	instanceRuns: Run[],
};

export type RunEvents = {
	runId: string,
	instanceId: string,
	scriptId: string,
	events: RunEvent[],
	eventCount?: number,
	mints?: number,
	maxts?: number,
	tsFrom?: number,
	tsTo?: number,
	minY?: number,
	maxY?: number,
	minLow?: number,
	maxVol?: number,
	aggLevel?: AggLevelType,
};

export enum MetricsType {
	TOTAL_ROWS = "total",
	MIN_MAX_TIMESTAMPS = "timestamps",
	AGG_LEVEL = "agg",
	MIN_LOW_VOLUME = "lowvolume",
}

export type RunEventsRequest = {
	instanceId: string,
	runId: RunId,
	msgtype?: string[],
	metrics?: MetricsType[],
	limit?: number,
	offset?: number,
	tsFrom?: number,
	tsTo?: number
}
