import { type ReactNode, useState } from "react";
import {
	ContentCopy as ContentCopyIcon,
	Description as DescriptionIcon,
	DoneAll as DoneAllIcon,
	PlayCircleRounded as PlayIcon,
	Remove as RemoveIcon,
	StopCircle as StopCircleIcon,
	Sync as SyncIcon,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { DataTable, Modal, Pill } from "components";
import { useDeleteBacktestMutation } from "services";
import type { Backtest, PillColor, PillVariant } from "types";

type BacktestIndexTableProps = {
	backtests: Array<Backtest>;
	handleShowBacktest: (backtest: Backtest) => void;
	handleStopBacktest: (backtest: Backtest) => void;
	handleStartBacktest: (backtest: Backtest) => void;
};

const BacktestIndexTable = ({
	backtests,
	handleShowBacktest,
	handleStopBacktest,
	handleStartBacktest,
}: BacktestIndexTableProps): ReactNode => {
	const [backtestToDelete, setBacktestToDelete] = useState<Backtest | null>(null);
	const [deleteBacktest, { isLoading: isDeletingBacktest }] = useDeleteBacktestMutation();
	
	const getPlayPauseButtonForBacktest = (backtest: Backtest) => {
		switch (backtest.state) {
			case "RUNNING":
			case "PENDING":
				return (
					<IconButton
						aria-label={`Stop backtest "${backtest.name}"`}
						onClick={() => handleStopBacktest(backtest)}
						color="error"
					>
						<StopCircleIcon />
					</IconButton>
				);
			case "CREATED":
				return (
					<IconButton
						aria-label={`Run backtest "${backtest.name}"`}
						onClick={() => handleStartBacktest(backtest)}
						color="success"
					>
						<PlayIcon />
					</IconButton>
				);
			case "STOPPING":
			case "ARCHIVING":
			default:
				return null;
		}
	};
	
	const pillStatus = (status: string) => {
		let Icon = RemoveIcon;
		let color: PillColor = undefined;
		let text = "Pending";
		let variant: PillVariant = "outlined";
		
		switch (status) {
			case "CREATED":
				text = "Created";
				break;
			case "RUNNING":
				Icon = SyncIcon;
				color = "primary";
				text = "Running";
				variant = undefined;
				break;
			case "STOPPING":
				Icon = SyncIcon;
				color = "error";
				text = "Stopping";
				variant = undefined;
				break;
			case "STOPPED":
				Icon = StopCircleIcon;
				color = "error";
				text = "Stopped";
				variant = undefined;
				break;
			case "COMPLETED":
				Icon = DoneAllIcon;
				color = "success";
				text = "Finished";
				variant = undefined;
				break;
			default:
				break;
		}
		
		return (
			<Pill
				color={color}
				text={text}
				variant={variant}
				table
				icon={Icon}
			/>
		);
	};
	
	return (
		<>
			<DataTable
				getRowId={(row: Backtest) => row.backtestId}
				search={false}
				columns={[
					{
						field: "Play",
						headerName: "",
						flex: 1,
						sortable: false,
						renderCell: ({ row }) => getPlayPauseButtonForBacktest(row),
					},
					{
						field: "name",
						headerName: "Name",
						flex: 5,
					},
					{
						field: "status",
						headerName: "Run status",
						flex: 1,
						sortable: false,
						renderCell: ({ row }) => pillStatus(row.state),
					},
					{
						field: "actions",
						type: "actions",
						getActions: ({ row }) => [
							<GridActionsCellItem
								icon={<DescriptionIcon />}
								onClick={() => handleShowBacktest(row)}
								label="Show results"
								showInMenu
							/>,
							<GridActionsCellItem
								icon={<RemoveIcon />}
								onClick={() => handleStopBacktest(row)}
								label="Cancel"
								showInMenu
							/>,
							<GridActionsCellItem
								icon={<ContentCopyIcon />}
								onClick={() => setBacktestToDelete(row)}
								label="Delete"
								showInMenu
								disabled={isDeletingBacktest}
							/>,
						],
					},
				]}
				rows={backtests}
			/>
			<Modal
				title="Are you sure you want to delete"
				dynamicTitle={`${backtestToDelete?.name}?`}
				handleClose={() => setBacktestToDelete(null)}
				onNegative={() =>
					deleteBacktest(backtestToDelete?.backtestId ?? "")
						.unwrap()
						.then(() => setBacktestToDelete(null))
				}
				negativeButtonText="Delete"
				show={!!backtestToDelete}
			/>
		</>
	);
};

export default BacktestIndexTable;
