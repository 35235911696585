import {
	type ReactElement,
	type ReactNode,
	createContext,
	useMemo,
} from "react";
import {
	ThemeProvider as MUIThemeProvider,
	Theme,
	ThemeOptions,
	alpha,
	createTheme,
	hexToRgb,
} from "@mui/material/styles";

import { useAppSelector } from "hooks";
import { selectUserTheme } from "store";

import type { PaletteMode } from "@mui/material";

type ThemeContextType = {
	themeMode: PaletteMode;
};

export const ThemeContext = createContext<ThemeContextType | undefined>(
	undefined
);

type ThemeProviderProps = {
	children?: ReactElement | ReactElement[];
};

export function ThemeProvider({ children }: ThemeProviderProps): ReactNode {
	const themeMode = useAppSelector<PaletteMode>(selectUserTheme);
	
	/*
	White:         #FFFFFF
	
	Grey:          #282C34
	Grey Tint 01:  #2C313C
	Grey Tint 02:  #303841
	Grey Tint 03:  #4F575F
	Grey Tint 04:  #838A91
	
	Blue:          #426AF6
	Blue Tint 03:  #8DA6FF
	Blue Tint 04:  #ECF0FE
	Blue Tint 05:  #F6F8FF
	
	Green:         #89CA78
	Green Tint 03: #ACDAA0
	
	Orange:        #FF984E
	
	Red:           #EE5864
	*/
	
	const getBasicConfig = (mode: PaletteMode) => ({
		typography: {
			htmlFontSize: 10,
			h1: {
				fontSize: "2.2rem",
				fontWeight: 400,
			},
			h2: {
				fontSize: "2rem",
				fontWeight: 400,
			},
			h3: {
				fontSize: "1.8rem",
				fontWeight: 400,
			},
			h4: {
				fontSize: "1.6rem",
				fontWeight: 500,
			},
			h5: {
				fontSize: "1.4rem",
				fontWeight: 500,
			},
			h6: {
				fontSize: "1.2rem",
				fontWeight: 500,
			},
			body1: {
				fontSize: "1.4rem",
				fontWeight: 400,
			},
			body2: {
				fontSize: "1.4rem",
				fontWeight: 400,
			},
			dashboard: {
				fontSize: "3rem",
				fontWeight: 200,
			},
			button: {
				fontSize: "1.4rem",
				fontWeight: 500,
				textTransform: "unset",
			},
			formError: {
				fontSize: "1rem",
				fontWeight: 500,
			},
			bigIcon: {
				fontSize: "8rem",
			},
		},
		palette: {
			mode,
			...(mode === "light"
				? {
					background: {
						default: "#FFFFFF",
						paper: "#FFFFFF",
						backdrop: "#282C34",
						popper: "#FFFFFF",
						drawer: "#F6F8FF",
						modal: "#FFFFFF",
						nav: "#ECF0FE",
						fallback: "#FFFFFF",
					},
					primary: {
						main: "#426AF6",
						light: "#8DA6FF",
					},
					secondary: {
						main: "#282C34",
					},
					error: {
						main: "#EE5864",
					},
					warning: {
						main: "#FF984E",
					},
					info: {
						main: "#426AF6",
					},
					success: {
						main: "#89CA78",
						light: "#ACDAA0",
						contrastText: "#FFFFFF",
					},
					text: {
						primary: "#303841",
						secondary: "#4F575F",
						success: "#89CA78",
						error: "#EE5864",
					},
					nav: {
						hover: "#F6F8FF",
						contrastText: "#303841",
						dark: "#4F575F",
					},
					scrollbar: {
						track: "#ECF0FE",
						thumb: "#838A91",
					},
					outline: {
						main: "#282C34",
					},
					input: {
						main: "#F6F8FF",
						dark: "#F6F8FF",
						hover: "#426AF6",
						selected: "#426AF6",
						light: "#ECF0FE",
					},
					menuItem: {
						main: "#FFFFFF",
						hover: "#426AF6",
					},
					stroke: {
						main: "#FFFFFF",
					},
					tabs: {
						main: "#ECF0FE",
					},
					tooltip: {
						main: "#ECF0FE",
						contrastText: "#303841",
					},
					table: {
						main: "#ECF0FE",
						secondary: "#4F575F",
						hover: "#F6F8FF",
					},
					charts: {
						main: "#426AF6",
						dark: "#89CA78",
						light: "#EE5864",
						hover: "#303841",
					},
					chartBorder: {
						main: "#CCCCCC",
					},
				  }
				: {
					background: {
						default: "#282C34",
						paper: "#282C34",
						backdrop: "#282C34",
						popper: "#2C313C",
						drawer: "#303841",
						modal: "#303841",
						nav: "#4F575F",
						fallback: "#282C34",
					},
					primary: {
						main: "#426AF6",
						light: "#8DA6FF",
					},
					secondary: {
						main: "#282C34",
					},
					error: {
						main: "#EE5864",
					},
					warning: {
						main: "#FF984E",
					},
					info: {
						main: "#426AF6",
					},
					success: {
						main: "#89CA78",
						light: "#ACDAA0",
						contrastText: "#FFFFFF",
					},
					text: {
						primary: "#FFFFFF",
						secondary: "#838A91",
						success: "#89CA78",
						error: "#EE5864",
					},
					nav: {
						hover: "#303841",
						contrastText: "#FFFFFF",
						dark: "#838A91",
					},
					scrollbar: {
						track: "#303841",
						thumb: "#4F575F",
					},
					outline: {
						main: "#FFFFFF",
					},
					input: {
						main: "#303841",
						dark: "#2C313C",
						hover: "#282C34",
						selected: "#ECF0FE",
						light: "#4F575F",
					},
					menuItem: {
						main: "#303841",
						hover: "#426AF6",
					},
					stroke: {
						main: "#2C313C",
					},
					tabs: {
						main: "#303841",
					},
					tooltip: {
						main: "#4F575F",
						contrastText: "#303841",
					},
					table: {
						main: "#303841",
						hover: "#2C313C",
					},
					charts: {
						main: "#426AF6",
						light: "#89CA78",
						dark: "#EE5864",
						hover: "#FFFFFF",
					},
					chartBorder: {
						main: "#4C5674",
					},
				  }),
		},
	});
	
	const theme = useMemo(() => {
		let t: Theme = createTheme(getBasicConfig(themeMode) as ThemeOptions);
		
		return createTheme(t as ThemeOptions, {
			// This also feels a little off - but a better approach may become clearer as we see these shadows in use
			// more.
			// Currently this depth of 8 handles the popover menu shadows.
			shadows: [
				...t.shadows.map((shadow, i: number) =>
					i === 1 || i === 8
						? `0px 0px 10px rgb(0 0 0 / ${
							t.palette.mode === "dark" ? "50%" : "10%"
						  })`
						: shadow
				),
			],
			shape: {
				borderRadius: 4,
			},
			components: {
				MuiCssBaseline: {
					styleOverrides: {
						"*": {
							scrollbarWidth: "0.6rem",
							scrollbarHeight: "0.6rem",
							scrollbarColor: `${t.palette.scrollbar.thumb} ${t.palette.scrollbar.track}`,
						},
						"*::-webkit-scrollbar": {
							width: "0.6rem",
							height: "0.6rem",
						},
						"*::-webkit-scrollbar-track, *::-webkit-scrollbar-thumb":
							{
								borderRadius: "0.6rem",
							},
						"*::-webkit-scrollbar-thumb": {
							backgroundColor: t.palette.scrollbar.thumb,
						},
						"*::-webkit-scrollbar-track": {
							backgroundColor: t.palette.scrollbar.track,
						},
						html: {
							fontSize: "62.5%",
						},
						ul: {
							margin: 0,
							padding: 0,
							li: {
								listStyleType: "none",
							},
						},
						"input:-webkit-autofill, input:-webkit-autofill:focus":
							{
								transition:
									"background-color 600000s 0s, color 600000s 0s",
							},
					},
				},
				MuiBackdrop: {
					styleOverrides: {
						root: ({ theme }: { theme: Theme }) => ({
							backgroundColor: alpha(
								hexToRgb(theme.palette.background.backdrop),
								theme.palette.mode === "light" ? 0.3 : 0.7
							),
						}),
						invisible: {
							backgroundColor: "transparent",
						},
					},
				},
				MuiPaper: {
					styleOverrides: {
						root: ({ theme }: { theme: Theme }) => ({
							overflow: "hidden",
							border: `1px solid ${theme.palette.stroke.main}`,
							backgroundImage: "none",
						}),
					},
				},
				MuiMenu: {
					styleOverrides: {
						list: {
							padding: 0,
						},
						paper: ({ theme }: { theme: Theme }) => ({
							marginTop: theme.spacing(0.5),
						}),
					},
				},
				MuiMenuItem: {
					defaultProps: {
						dense: true,
					},
					styleOverrides: {
						root: ({ theme }: { theme: Theme }) => ({
							...theme.typography.h6,
							paddingLeft: theme.spacing(1),
							paddingRight: theme.spacing(1),
							color: theme.palette.text.secondary,
							backgroundColor: theme.palette.menuItem.main,
							lineHeight: "unset",
							"&:hover": {
								color: theme.palette.common.white,
								backgroundColor: theme.palette.menuItem.hover,
							},
							"& .MuiListItemIcon-root": {
								minWidth: "unset",
								marginRight: theme.spacing(0.5),
								color: "inherit",
							},
						}),
					},
				},
				MuiList: {
					styleOverrides: {
						root: {
							padding: 0,
						},
					},
				},
				MuiInputLabel: {
					styleOverrides: {
						root: ({ theme }: { theme: Theme }) => ({
							...theme.typography.h6,
							transform: "none",
							position: "relative",
							marginBottom: theme.spacing(1),
							"&.Mui-focused": {
								color: theme.palette.text.secondary,
							},
							"&.Mui-error": {
								color: theme.palette.error.main,
							},
						}),
					},
				},
				Pill: {
					styleOverrides: {
						root: ({ theme }: { theme: Theme }) => ({
							color: theme.palette.common.white,
						}),
						primary: ({ theme }: { theme: Theme }) => ({
							backgroundColor: theme.palette.primary.main,
						}),
						success: ({ theme }: { theme: Theme }) => ({
							backgroundColor: theme.palette.success.main,
						}),
						error: ({ theme }: { theme: Theme }) => ({
							backgroundColor: theme.palette.error.main,
						}),
					},
					variants: [
						{
							props: { variant: "outlined" },
							style: ({ theme }: { theme: Theme }) => ({
								backgroundColor: "unset",
								border: `1px solid ${
									theme.palette.mode === "light"
										? theme.palette.secondary.main
										: theme.palette.common.white
								}`,
								color:
									theme.palette.mode === "light"
										? theme.palette.secondary.main
										: theme.palette.common.white,
							}),
						},
					],
				},
				Tile: {
					styleOverrides: {
						root: ({ theme }: { theme: Theme }) => ({
							padding: `${theme.spacing(2)} ${theme.spacing(
								2
							)} ${theme.spacing(2)} ${theme.spacing(3)}`,
						}),
						primary: ({ theme }: { theme: Theme }) => ({
							background: `linear-gradient(
								90deg,
								${theme.palette.primary.main},
								${theme.palette.primary.light}
							)`,
						}),
						secondary: ({ theme }: { theme: Theme }) => ({
							padding: `${theme.spacing(2)} 0`,
						}),
						success: ({ theme }: { theme: Theme }) => ({
							background: `linear-gradient(
								90deg,
								${theme.palette.success.main},
								${theme.palette.success.light}
							)`,
						}),
						warning: ({ theme }: { theme: Theme }) => ({
							background: `linear-gradient(
								90deg,
								${theme.palette.error.main},
								${theme.palette.warning.main}
							)`,
						}),
					},
				},
				MuiIconButton: {
					styleOverrides: {
						root: ({ theme }: { theme: Theme }) => ({
							".MuiInputAdornment-root > &.MuiIconButton-edgeEnd":
								{
									marginRight: 0,
								},
						}),
					},
				},
				MuiPickersDay: {
					styleOverrides: {
						root: ({ theme }: { theme: Theme }) => ({
							...theme.typography.body2,
						}),
					},
				},
				MuiBreadcrumbs: {
					styleOverrides: {
						separator: ({ theme }: { theme: Theme }) => ({
							...theme.typography.h1,
							color: theme.palette.primary.main,
						}),
					},
				},
			},
		});
	}, [themeMode]);
	
	return (
		<ThemeContext.Provider value={{ themeMode }}>
			<MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
		</ThemeContext.Provider>
	);
}
