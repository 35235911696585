import { Fragment, type MutableRefObject, useEffect, useRef, useState } from "react";
import { useThree } from "@react-three/fiber";
import {
	Annotations,
	Boxes,
	Candles,
	Line,
	Points,
	Polygons,
	Symbols,
	Volumes,
} from "components";
import { useAppDispatch, useAppSelector } from "hooks";
import {
	resetRequestScaleY,
	selectScaleFactor,
	selectScaleY,
	setScaleFactor,
} from "store";
import { updateScaleFactorFromFromVisibleData } from "../helpers";
import type { ReactNode } from "react";
import type {
	ChartAnnotationObject,
	ChartBoxObject,
	ChartCandleObject,
	ChartData,
	ChartDotObject,
	ChartLineObject,
	ChartPoint,
	ChartPolygonObject,
	ChartPos,
	ChartSymbolObject,
} from "types";

type ChartObjectsProps = {
	chartPos: MutableRefObject<ChartPos>,
	cmds: ChartData[],
};

type ElementRef = {
	points?: ChartPoint[];
	candles?: ChartCandleObject[];
	boxes?: ChartBoxObject[];
	annotations?: ChartAnnotationObject[];
	polygons?: ChartPolygonObject[];
	dots?: ChartDotObject[];
	symbols?: ChartSymbolObject[];
	element: React.ReactNode;
};

type ElementRefs = {
	[key: string]: ElementRef;
};

const ChartObjects = ({ chartPos, cmds }: ChartObjectsProps): ReactNode => {
	const dispatch = useAppDispatch();
	const scaleY = useAppSelector<boolean>(selectScaleY);
	const scaleFactor = useAppSelector(selectScaleFactor);
	
	const elementRefs = useRef<ElementRefs>({});
	const { invalidate } = useThree();
	const [, forceUpdate] = useState<{}>();
	
	// This is the main renderer of all the chart objects, storing them in
	// a ref so that no recalculations have to be done - i.e. once the ref
	// is fully set up and calculated, the return of the ReactNode just
	// returns the fully formed elements.
	useEffect(() => {
		elementRefs.current = {};
		
		const { height, low, maxvol } = chartPos.current;
		
		if (!scaleFactor) return;
		
		cmds.forEach((cmd) => {
			const obj = cmd.params.chartcmd.obj;
			
			switch (obj.objtype) {
				case "CANDLE":
					const candle = obj as ChartCandleObject;
					
					let candles: ChartCandleObject[] = [];
					
					if (elementRefs.current["candles"]) {
						const ref = elementRefs.current["candles"];
						
						candles = [...ref?.candles ?? []];
					}
					
					candles.push(candle);
					
					// Both candles and volumes take candles to render their
					// commensurate structures - so we have to add them both
					// in the one element.
					elementRefs.current["candles"] = {
						element: (
							<Fragment key="candles">
								<Candles scaleFactor={scaleFactor}
									candles={candles}
									minvol={low}
									maxvol={maxvol}
								/>
								<Volumes scaleFactor={scaleFactor}
									candles={candles}
									minvol={low}
									maxvol={maxvol}
									height={height}
								/>
							</Fragment>
						),
						candles,
					};
					break;
				case "BOX":
					let boxes: ChartBoxObject[] = [];
					
					if (elementRefs.current["boxes"]) {
						const ref = elementRefs.current["boxes"];
						
						boxes = [...ref?.boxes ?? []];
					}
					
					boxes.push(obj as ChartBoxObject);
					
					elementRefs.current["boxes"] = {
						element: (<Boxes scaleFactor={scaleFactor} boxes={boxes} key="boxes" />),
						boxes,
					};
					break;
				case "ANNOTATION":
					let annotations: ChartAnnotationObject[] = [];
					
					if (elementRefs.current["annotations"]) {
						const ref = elementRefs.current["annotations"];
						
						annotations = [...ref?.annotations ?? []];
					}
					
					annotations.push(obj as ChartAnnotationObject);
					
					elementRefs.current["annotations"] = {
						element: (
							<Annotations
								scaleFactor={scaleFactor}
								annotations={annotations}
								key="annotations"
							/>
						),
						annotations,
					};
					break;
				case "LINE":
					const line = obj as ChartLineObject;
					const points: ChartPoint[] = [];
					
					if (elementRefs.current[obj.objId]) {
						const ref = elementRefs.current[obj.objId];
						
						points.push(...(ref?.points ?? []));
					}
					
					// Just push "any" points in from the getPoints accessor, have a
					// look at the types for the reason behind this - this set of points
					// will have been updated with any offsets due to "gaps". We also need
					// to sort, as if we don't ThreeJS renders tries to connect the ends
					// of the lines.
					points.push(...line.getPoints());
					points.sort((a, b) => a.x - b.x);
					
					elementRefs.current[obj.objId] = {
						element: (
							<Line scaleFactor={scaleFactor} {...line} points={points} key={obj.objId} />
						),
						points,
					};
					break;
				case "POLYGON":
					const polygons: ChartPolygonObject[] = [];
					
					if (elementRefs.current["polygons"]) {
						const ref = elementRefs.current["polygons"];
						
						polygons.push(...ref?.polygons ?? []);
					}
					
					polygons.push(obj as ChartPolygonObject);
					
					elementRefs.current["polygons"] = {
						element: (<Polygons scaleFactor={scaleFactor} polygons={polygons} key="polygons" />),
						polygons,
					};
					break;
				case "DOT":
					let dots: ChartDotObject[] = [];
					
					if (elementRefs.current["dots"]) {
						const ref = elementRefs.current["dots"];
						
						dots = [...ref?.dots ?? []];
					}
					
					dots.push(obj as ChartDotObject);
					
					elementRefs.current["dots"] = {
						element: (<Points scaleFactor={scaleFactor} dots={dots} key="dots" />),
						dots,
					};
					break;
				case "SYMBOL":
					let symbols: ChartSymbolObject[] = [];
					
					if (elementRefs.current["symbols"]) {
						const ref = elementRefs.current["symbols"];
						
						symbols = [...ref?.symbols ?? []];
					}
					
					symbols.push(obj as ChartSymbolObject);
					
					elementRefs.current["symbols"] = {
						element: (
							<Symbols
								scaleFactor={scaleFactor}
								symbols={symbols}
								key="symbols"
							/>
						),
						symbols,
					};
					break;
			};
		});
		
		invalidate();
		forceUpdate({});
	}, [cmds, chartPos, scaleFactor, invalidate]);
	
	// This will try and call for the recalculation of the ScaleFactor
	// so that we can then re-scale all the chart objects to fit the
	// current bounds.
	useEffect(() => {
		if (!scaleY || !chartPos.current) return;
		
		const scaleFactor = updateScaleFactorFromFromVisibleData(cmds, chartPos);
		
		if (scaleFactor) dispatch(setScaleFactor(scaleFactor));
		
		dispatch(resetRequestScaleY());
	}, [scaleY, cmds, chartPos, dispatch]);
	
	return (
		<>
			{Object.values(elementRefs.current).map((ref) => ref.element)}
		</>
	);
};

export default ChartObjects;
