import { TICKER_BATCH_SIZE } from "consts";
import { api } from "services";

import type {
	AssetCandles,
	AssetData,
	AssetDataApi,
	AssetHistoricalDataRequest,
	AssetIntervals,
	AssetRealtimeDataRequest,
	AssetTickerSymbol,
	AssetsPage,
	CreateHistoricalDataParams,
	FetchedData,
	GetAssetDataRequest,
	GetAssetRealtimeDataRequestRequest,
	GetAssetsParams,
} from "types";

export const assets = api
	.enhanceEndpoints({
		addTagTypes: ["assets", "asset", "asset_data", "asset_candles"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getAssets: build.query<
			AssetsPage,
			GetAssetsParams | void
			>({
				query(params: GetAssetsParams) {
					const { offset, market, search, limit } = params || {};
					const s = search || "";
					const m = market || "stocks";
					const o = offset || 0;
					const l = limit || TICKER_BATCH_SIZE;
					
					return {
						url: `/tickers?search=${s}&market=${m}&limit=${l}&offset=${o}`,
						method: "GET",
					};
				},
				providesTags: ["assets"],
			}),
			getAsset: build.query<AssetIntervals, AssetTickerSymbol>({
				query(symbol: AssetTickerSymbol) {
					return {
						url: `/tickers/${symbol.toUpperCase()}`,
						method: "GET",
					};
				},
				providesTags: ["asset"],
			}),
			getDataForAsset: build.query<AssetData, GetAssetDataRequest>({
				query({
					symbol,
					resolution,
					tsFrom,
					tsTo,
				}: GetAssetDataRequest) {
					return {
						url: `/tickers/${symbol}/data?resolution=${resolution}&tsFrom=${tsFrom}&tsTo=${tsTo}`,
						method: "GET",
					};
				},
				transformResponse: (res: AssetDataApi) => {
					if (res?.data?.length > 0) {
						const transformedData = res.data.map(adn => ({
						  ...adn,
						  data: adn.data?.map(adi => ({
								symbol: adi.symbol,
								aggLevel: adi.agg_level,
								o: adi.o,
								h: adi.h,
								l: adi.l,
								c: adi.c,
								v: adi.v,
								vw: adi.vw,
								dtFrom: adi.dt_from,
								dtTo: adi.dt_to,
								n: adi.n,
						  })),
						}));
						
						return { ...res, data: transformedData } as AssetData;
					  }
					
					return res as AssetData;
				},
				providesTags: ["asset_data"],
			}),
			getCandleDataForAsset: build.query<FetchedData, GetAssetDataRequest>({
				query({
					symbol,
					resolution,
					tsFrom,
					tsTo,
				}: GetAssetDataRequest) {
					return {
						url: `/tickers/${symbol}/data?format=CANDLE`,
						method: "GET",
						params: {
							resolution,
							tsFrom,
							tsTo,
						},
					};
				},
				transformResponse: (res: AssetCandles) => {
					const fetched: FetchedData = {
						data: [],
						minY: 0,
						maxY: 0,
						minLow: 0,
						maxVol: 0,
					};
					
					res?.data.forEach((d) => {
						if (fetched.minY === 0 && d.minY) {
							fetched.minY = d.minY;
						}
						
						fetched.data.push(...d.data);
						// This comes in this way, as we only ever have candles.
						fetched.minY = fetched.minLow = Math.min(fetched.minY, d.minY);
						fetched.maxY = Math.max(fetched.maxY, d.maxY);
						fetched.maxVol = Math.max(fetched.maxVol, d.maxVol);
					});
					
					return fetched;
				},
				providesTags: ["asset_candles"],
			}),
			createHistoricalDataRequest: build.mutation<
			AssetHistoricalDataRequest,
			CreateHistoricalDataParams
			>({
				query({ symbol, ...rest }: CreateHistoricalDataParams) {
					return {
						url: `/tickers/${symbol}/historical`,
						method: "POST",
						body: rest,
					};
				},
				invalidatesTags: ["asset"],
			}),
			deleteHistoricalDataRequest: build.mutation<
			AssetHistoricalDataRequest,
			string
			>({
				query(requestId: string) {
					return {
						url: `/ticker_requests/${requestId}`,
						method: "DELETE",
					};
				},
				invalidatesTags: ["asset"],
			}),
			createRealtimeDataRequest: build.mutation<
			AssetRealtimeDataRequest,
			GetAssetRealtimeDataRequestRequest
			>({
				query({
					symbol,
					resolution,
				}: GetAssetRealtimeDataRequestRequest) {
					return {
						url: `/tickers/${symbol}/realtime/${resolution}`,
						method: "POST",
					};
				},
				invalidatesTags: ["asset"],
			}),
			deleteHarvesterRequest: build.mutation<
			AssetRealtimeDataRequest,
			GetAssetRealtimeDataRequestRequest
			>({
				query({
					symbol,
					resolution,
				}: GetAssetRealtimeDataRequestRequest) {
					return {
						url: `/tickers/${symbol}/realtime/${resolution}`,
						method: "DELETE",
					};
				},
				invalidatesTags: ["asset"],
			}),
		}),
	});

export const {
	useGetAssetQuery,
	useLazyGetDataForAssetQuery,
	useGetAssetsQuery,
	useLazyGetAssetsQuery,
	useLazyGetCandleDataForAssetQuery,
	useCreateHistoricalDataRequestMutation,
	useDeleteHistoricalDataRequestMutation,
	useCreateRealtimeDataRequestMutation,
	useDeleteHarvesterRequestMutation,
} = assets;
