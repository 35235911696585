import { type ReactNode, useState } from "react";
import {
	GridPagination as MuiXGridPagination,
	gridPageSelector,
	gridPageSizeSelector,
	useGridApiContext,
	useGridRootProps,
} from "@mui/x-data-grid";
import { Grid, SelectChangeEvent } from "@mui/material";

import SelectPageActions from "./SelectPageAction";
import TablePaginationActions from "./TablePaginationActions";

const Pagination = (props: any): ReactNode => {
	const apiRef = useGridApiContext();
	const rootProps = useGridRootProps();
	const statePagesize = gridPageSizeSelector(apiRef);
	const page = gridPageSelector(apiRef);
	const setPage = apiRef.current.setPage;
	
	const [selectVal, setSelectVal] = useState(0);
	
	const handleChangePage = (
		event: SelectChangeEvent<number>,
		newPage: number
	) => {
		setPage(newPage);
		setSelectVal(newPage);
	};
	
	return (
		<Grid container justifyContent="flex-end">
			<Grid item>
				<SelectPageActions
					endPage={Math.ceil(rootProps?.rowCount ? rootProps?.rowCount/ statePagesize : statePagesize)}
					page={page}
					val={selectVal}
					onPageChange={handleChangePage}
				/>
			</Grid>
			<Grid item>
				<MuiXGridPagination
					sx={{
						"& .MuiTablePagination-spacer": {
							display: "none",
						},
						"& .MuiTablePagination-selectLabel": {
							display: "none",
						},
						"& .MuiInputBase-root": {
							display: "none",
						},
						"& .MuiTablePagination-displayedRows": {
							display: "none",
						},
					}}
					onPageChange={handleChangePage}
					ActionsComponent={TablePaginationActions}
					{...props}
				/>
			</Grid>
		</Grid>
	);
};

export default Pagination;
