import { Grid, Typography } from "@mui/material";

import { formatNumber } from "utils";
import { Tile } from "components";

import type { ReactNode } from "react";

type Items = {
	text: string;
	value: number;
};

type TradingOverviewProps = {
	items: Array<Items>;
	currency: string;
};

const TradingOverview = ({
	items,
	currency,
}: TradingOverviewProps): ReactNode => {
	return (
		<>
			<Typography variant="h1" display="inline-block">
				Trading overview for&nbsp;
			</Typography>
			<Typography
				variant="h1"
				display="inline-block"
				sx={{ textDecoration: "underline" }}
			>
				seven days
			</Typography>
			<Grid container spacing={2}>
				{items.map((item, idx) => (
					<Grid item xs md={3} key={`trading-${idx}`}>
						<Tile
							heroText={formatNumber(item.value)}
							heroSubscript={currency.toUpperCase()}
							text={item.text}
						/>
					</Grid>
				))}
			</Grid>
		</>
	);
};

export default TradingOverview;
