import { type ReactNode, useState } from "react";
import { useFormik } from "formik";

import { Alert, Modal, TextField } from "components";
import { useCreateScriptVersionMutation } from "services";
import { CreateVersionFormSchema } from "./CreateVersionForm.schema";

import type { Code, CreateScriptVersionParams, ScriptId } from "types";

interface FormInputsType {
	commitMsg: string;
}

const FORM_INITIAL_STATE: FormInputsType = {
	commitMsg: "",
};

type CreateVersionFormProps = {
	handleFormClose: () => void;
	code: Code;
	scriptId: ScriptId,
	show: boolean;
};

const CreateVersionForm = ({
	handleFormClose,
	code,
	scriptId,
	show,
}: CreateVersionFormProps): ReactNode => {
	const [formError, setFormError] = useState<string>("");
	const [createScriptVersion, { isLoading }] = useCreateScriptVersionMutation();
	
	const handleFormSubmit = (form: CreateScriptVersionParams) => {
		const { commitMsg } = form;
		
		createScriptVersion({
			scriptId,
			versionData: {
				commitMsg,
				code,
			},
		})
			.unwrap()
			.then(() => handleFormClose())
			.catch((e) => setFormError(e.message));
	};
	
	const formik = useFormik({
		initialValues: FORM_INITIAL_STATE,
		validationSchema: CreateVersionFormSchema,
		onSubmit: handleFormSubmit,
		validateOnChange: true,
	});
	
	return (
		<Modal
			title="Create a version"
			handleClose={handleFormClose}
			show={show}
			onSave={formik.handleSubmit}
			saveButtonText="Create"
			isValid={formik.dirty && formik.isValid}
			isSubmitting={isLoading}
		>
			{formError && <Alert>{formError}</Alert>}
			<form onSubmit={formik.handleSubmit}>
				<TextField
					modal
					id="commitMsg"
					name="commitMsg"
					label="Description of changes"
					placeholder="Enter a message describing your changes..."
					value={formik.values.commitMsg}
					onChange={formik.handleChange}
					error={
						formik.touched.commitMsg &&
						Boolean(formik.errors.commitMsg)
					}
					helperText={
						formik.touched.commitMsg && formik.errors.commitMsg
							? formik.errors.commitMsg
							: undefined
					}
				/>
			</form>
		</Modal>
	);
};

export default CreateVersionForm;
