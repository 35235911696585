import { locale } from "consts";

export const formatNumber = (number: number): string => {
	const fValue = new Intl.NumberFormat(locale).format(number);
	
	return fValue;
};

export const formatCurrency = (
	amount: number | string,
	currency: string
): string => {
	let parsed =
		typeof amount === "string" ? Number.parseInt(amount, 10) : amount;
	
	return `${parsed.toFixed(2)} ${currency}`;
};

export const isPositiveNumber = (num: number | string): boolean => {
	const parsedNum = typeof num === "string" ? Number.parseInt(num, 10) : num;
	
	return !isNaN(parsedNum) && parsedNum > 0;
};
