import { type ReactElement, StrictMode } from "react";
import { render } from "@testing-library/react";
import { BrowserRouter } from "react-router-dom";

import {
	AssetsProvider,
	ReduxProvider,
	ThemeProvider,
	ToastProvider,
} from "context";

const customRenderProviders = ({
	children,
}: {
	children: ReactElement | ReactElement[];
}) => {
	return (
		<ReduxProvider>
			<ToastProvider>
				<AssetsProvider>
					<ThemeProvider>{children}</ThemeProvider>
				</AssetsProvider>
			</ToastProvider>
		</ReduxProvider>
	);
};
const customRender = (ui: ReactElement) =>
	render(ui, { wrapper: customRenderProviders });

const customRenderWithRouterProviders = ({
	children,
}: {
	children: ReactElement | ReactElement[];
}) => {
	return (
		<StrictMode>
			<ReduxProvider>
				<BrowserRouter>
					<ThemeProvider>{children}</ThemeProvider>
				</BrowserRouter>
			</ReduxProvider>
		</StrictMode>
	);
};
const customRenderWithRouter = (ui: ReactElement, { route = "/" } = {}) => {
	window.history.pushState({}, "Test page", route);
	
	return render(ui, { wrapper: customRenderWithRouterProviders });
};

// re-export everything
export * from "@testing-library/react";

// override render method
export { customRender as render, customRenderWithRouter as renderWithRouter };
