// ** Axis Display **

import { ChartObjectType } from "types";

// Axis label alignment helpers
export const CHART_AXIS_Y_STEPHEIGHT = 40; // Acommodated height of a Y axis label
export const CHART_AXIS_X_STEPWIDTH = 80; // Acommodated width of an X axis label
export const DEFAULT_X_DIVISIONS = 4;
export const DEFAULT_Y_DIVISIONS = 4;

// ** Chart Objects **

export const CHART_LINE_STYLE_TYPES = {
	SOLID: "SOLID",
	DASHED: "DASHED",
	DOTTED: "DOTTED",
};

// Common Object Defaults
export const CHART_OBJECT_DEFAULTS = {
	scale: { x: 1, y: 1, z: 1 },
	rotation: { x: 0, y: 0, z: 0 },
	style: {
		font: "/facetypes/roboto.json",
		fontSize: 5,
		color: "gold",
		opacity: 1,
		borderColor: "black",
		borderWidth: 0.5,
		lineWidth: 2,
		lineType: CHART_LINE_STYLE_TYPES.SOLID,
	},
};

// Candle
export const CHART_OBJECT_CANDLE_DEFAULTS = {
	style: {
		positiveCandleColor: "#559f86",
		negativeCandleColor: "#f45e5e",
		wickWidthScale: 0.2,
	},
};

export const validChartObjectTypes: ChartObjectType[] = [
	"BOX",
	"CANDLE",
	"ANNOTATION",
	"POINT",
	"SYMBOL",
	"LINE",
	"POLYGON",
];
