import { Stack, Typography, useTheme } from "@mui/material";

import type { ReactNode } from "react";

type LoadingProps = {
	message?: string;
	spinnerSize: number;
};

const defaultProps = {
	spinnerSize: 120,
};

const Loading = ({ message, spinnerSize }: LoadingProps): ReactNode => {
	// CONTEXT & OTHER HOOKS
	const theme = useTheme();
	
	const spinnerStyles = {
		width: spinnerSize,
		height: spinnerSize,
		color: theme.palette.text.primary,
	};
	
	return (
		<Stack
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			sx={{ height: "100%" }}
		>
			<div style={spinnerStyles}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 100 100"
					preserveAspectRatio="xMidYMid"
				>
					<path
						d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
						stroke="none"
						fill={spinnerStyles.color}
					>
						<animateTransform
							attributeName="transform"
							type="rotate"
							dur="1s"
							repeatCount="indefinite"
							keyTimes="0;1"
							values="0 50 51;360 50 51"
						></animateTransform>
					</path>
				</svg>
			</div>
			{message && <Typography pt={2.5}>{message}</Typography>}
		</Stack>
	);
};

Loading.defaultProps = defaultProps;

export default Loading;
