import { SocketService, api } from ".";
import type { Backtest, Backtests, CreateBacktestTest, Instance } from "types";

enum MessageType {
	STARTED = "RUNNING",
	STOPPED = "STOPPED",
	COMPLETED = "COMPLETED",
}

const msgtypeMapper = (
	msgtype: keyof typeof MessageType
): MessageType | null => {
	return MessageType[msgtype] || null;
};

export const backtest = api
	.enhanceEndpoints({
		addTagTypes: ["backtest", "backtests", "instances"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getBacktests: build.query<Backtests, void>({
				query() {
					return {
						url: "/backtests",
						method: "GET",
					};
				},
				providesTags: ["backtests"],
				async onCacheEntryAdded(
					arg,
					{ updateCachedData, cacheDataLoaded, cacheEntryRemoved }
				) {
					let subscriptionId;
					
					try {
						await cacheDataLoaded;
						subscriptionId = SocketService().subscribeToBacktest((error, data) => {
							if (error) return;
							
							updateCachedData((draft: any) => {
								for (let i in draft.backtests) {
									if (
										draft.backtests[i].backtestId ===
										data.backtestId
									) {
										draft.backtests[i].state =
											msgtypeMapper(data.msgtype) ||
											draft.backtests[i].state;
										break;
									}
								}
							});
						});
					} catch {}
					
					await cacheEntryRemoved;
					
					if (subscriptionId) {
						SocketService().unsubscribeToBacktest(subscriptionId);
					}
				},
			}),
			getBacktest: build.query<Backtest, string>({
				query(backtestId: string) {
					return {
						url: `/backtests/${backtestId}`,
						method: "GET",
					};
				},
				providesTags: ["backtests"],
			}),
			getBacktestInstances: build.query<Instance[], string>({
				query(backtestId: string) {
					return {
						url: `/backtests/${backtestId}/instances`,
						method: "GET",
					};
				},
				providesTags: ["instances"],
			}),
			startBacktest: build.mutation<Backtest, string>({
				query(backtestId: string) {
					return {
						url: `/backtests/${backtestId}/start`,
						method: "POST",
					};
				},
			}),
			stopBacktest: build.mutation<Backtest, string>({
				query(backtestId: string) {
					return {
						url: `/backtests/${backtestId}/stop`,
						method: "POST",
					};
				},
			}),
			createBacktest: build.mutation<Backtest, CreateBacktestTest>({
				query(backtest: CreateBacktestTest) {
					return {
						url: "/backtests",
						method: "POST",
						body: backtest,
					};
				},
				invalidatesTags: ["backtests"],
			}),
			deleteBacktest: build.mutation<void, string>({
				query(backtestId: string) {
					return {
						url: `/backtests/${backtestId}`,
						method: "DELETE",
					};
				},
				invalidatesTags: ["backtests"],
			}),
		}),
	});

export const {
	useGetBacktestsQuery,
	useGetBacktestQuery,
	useGetBacktestInstancesQuery,
	useLazyGetBacktestInstancesQuery,
	useStartBacktestMutation,
	useStopBacktestMutation,
	useCreateBacktestMutation,
	useDeleteBacktestMutation,
} = backtest;
