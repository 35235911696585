import {
	FormControl,
	FormHelperText,
	InputLabel,
	type Transitions,
} from "@mui/material";

import { type ReactNode, cloneElement } from "react";
import { useTheme } from "@mui/system";

export interface InputFieldHOCProps {
	label?: string;
	id: string;
	modal?: boolean;
	error?: boolean;
	helperText?: string | false;
	InputProps?: Object;
	select?: boolean;
	endAdornment?: ReactNode;
	fullWidth?: boolean;
	margin?: "normal" | "dense" | "none" | undefined;
	children: JSX.Element;
}

const defaultProps = {
	modal: false,
	fullWidth: true,
	margin: "normal",
};

const InputFieldHOC = ({
	label,
	id,
	modal,
	error,
	helperText,
	InputProps,
	select,
	endAdornment,
	fullWidth,
	margin,
	children,
	...rest
}: InputFieldHOCProps): ReactNode => {
	const theme = useTheme();
	
	return (
		<FormControl variant="standard" fullWidth={fullWidth} margin={margin}>
			{label && (
				<InputLabel shrink htmlFor={id} error={error}>
					{label}
				</InputLabel>
			)}
			{cloneElement(children, {
				...rest,
				sx: {
					...{
						borderRadius: 1,
						backgroundColor: modal ? "input.dark" : "input.main",
						borderWidth: "1px",
						borderStyle: "solid",
						borderColor: "input.light",
						py: 1,
						height: theme.spacing(4),
						color: "text.primary",
						transition: (theme.transitions as Transitions).create([
							"border-color",
						]),
						"&.Mui-focused": {
							borderColor: "input.selected",
						},
						"&.Mui-disabled": {
							color: "inherit",
							opacity: 0.5,
						},
						"&.Mui-error": {
							borderColor: "error.main",
						},
						input: {
							px: 1,
							py: 0,
						},
						"& .MuiSelect-select": {
							pl: 1,
							py: 0,
							lineHeight: theme.spacing(4),
						},
					},
					endAdornment,
					...(children.props.sx || {}),
				},
			})}
			{helperText && (
				<FormHelperText
					error={error}
					sx={{ typography: "formError", mt: 0.5 }}
				>
					{helperText}
				</FormHelperText>
			)}
		</FormControl>
	);
};

InputFieldHOC.defaultProps = defaultProps;

export default InputFieldHOC;
