import { w3cwebsocket as W3CWebSocket } from "websocket";

import { SOCKET } from "consts";

import type { RunEvent, RunId, SocketCb, SocketMessage, SubscriptionIndex, WebSocketMessage } from "types";

// create client and connection listeners
const {
	REACT_APP_API_IP,
	REACT_APP_API_WEBSOCKET_PORT,
	REACT_APP_WS_PROTOCOL,
} = process.env;
const ip = REACT_APP_API_IP || "localhost";
const port = REACT_APP_API_WEBSOCKET_PORT || 8080;
const protocol = REACT_APP_WS_PROTOCOL || "ws";
const url = `${protocol}://${ip}:${port}/ws`;

// create web socket and bind connection handlers
const client = new W3CWebSocket(url);

client.onerror = () => {
	// handle error("W3CWebSocket Connection Error");
};

client.onopen = () => {
	// log("WebSocket Client Connected");
};

client.onclose = () => {
	// log("WebSocket Client Closed");
};

client.onmessage = (message: SocketMessage) => {
	if (typeof message.data !== "string") return;
	const {
		type: socketEventType,
		service: socketEventService,
		data: eventData,
	} = JSON.parse(message.data);
	
	if (socketEventType !== SOCKET.EVENT_TYPES.SERVER) return;
	
	switch (socketEventService) {
		case SOCKET.SERVICES.EXECUTION:
			// execute handler function if one exists for given runId
			const handlers = runEventHandlers[eventData.runId];
			
			handlers.forEach((handler) => {
				if (eventData) {
					handler(null, eventData);
				} else {
					handler({ message: SOCKET.ERRORS.NO_EVENT_DATA });
				}
			});
			
			break;
		case SOCKET.SERVICES.EXECUTION_METADATA:
			if (eventData) {
				executionMetadataEventHandlers.forEach((handler) =>
					handler(null, eventData)
				);
			} else {
				executionMetadataEventHandlers.forEach((handler) =>
					handler({ message: SOCKET.ERRORS.NO_EVENT_DATA })
				);
			}
			break;
		case SOCKET.SERVICES.BACKTEST:
			if (eventData) {
				backtestHandlers.forEach((handler) => handler(null, eventData));
			} else {
				backtestHandlers.forEach((handler) =>
					handler({ message: SOCKET.ERRORS.NO_EVENT_DATA })
				);
			}
			break;
		default:
			return;
	}
};

const runEventHandlers: Record<RunId, SocketCb<RunEvent>[]> = {};
const backtestHandlers: (SocketCb)[] = [];
const executionMetadataEventHandlers: (SocketCb)[] = [];

type SocketServiceType = {
	subscribeToRun: (
		runId: RunId,
		callback: (
			error: { message: string } | null | undefined,
			runEvent?: RunEvent,
		) => void,
	) => SubscriptionIndex;
	unsubscribeToRun: (runId: RunId, handlerIndex: SubscriptionIndex) => void;
	subscribeToBacktest: (callback: SocketCb) => SubscriptionIndex;
	unsubscribeToBacktest: (handlerIndex: SubscriptionIndex) => void;
	subscribeToExecutionMetadata: (callback: SocketCb) => SubscriptionIndex;
	unsubscribeToExecutionMetadata: (handlerIndex: SubscriptionIndex) => void;
};

export function SocketService(): SocketServiceType {
	const _emit = (message: WebSocketMessage) => {
		client.send(JSON.stringify(message));
	};
	
	const subscribeToBacktest = (callback: SocketCb) => {
		if (backtestHandlers.length === 0) {
			_emit({
				event: SOCKET.EVENTS.REGISTER,
				service: SOCKET.SERVICES.BACKTEST,
				args: {},
			});
		}
		
		// save handler in handler list and return index of the callback so we can unsubscribe later
		return backtestHandlers.push(callback);
	};
	
	const unsubscribeToBacktest = (handlerIndex: SubscriptionIndex) => {
		backtestHandlers.splice(handlerIndex-1);
		
		if (backtestHandlers.length === 0) {
			_emit({
				event: SOCKET.EVENTS.UNREGISTER,
				service: SOCKET.SERVICES.BACKTEST,
				args: {},
			});
		}
	};
	
	const subscribeToRun = (runId: RunId, callback: SocketCb<RunEvent>) => {
		// save handler in map
		if (!runEventHandlers.hasOwnProperty(runId)) runEventHandlers[runId] = [];
		if (runEventHandlers[runId].length === 0) {
			_emit({
				event: SOCKET.EVENTS.REGISTER,
				service: SOCKET.SERVICES.EXECUTION,
				args: {
					runId,
				},
			});
		}
		
		// save handler in handler in the run list and return index of the callback so we can unsubscribe later
		return runEventHandlers[runId].push(callback);
	};
	
	const unsubscribeToRun = (runId: RunId, handlerIndex: SubscriptionIndex) => {
		runEventHandlers[runId].splice(handlerIndex-1);
		
		if (runEventHandlers[runId].length === 0) {
			_emit({
				event: SOCKET.EVENTS.UNREGISTER,
				service: SOCKET.SERVICES.EXECUTION,
				args: {
					runId,
				},
			});
		}
	};
	
	const subscribeToExecutionMetadata = (callback: SocketCb) => {
		if (executionMetadataEventHandlers.length === 0) {
			_emit({
				event: SOCKET.EVENTS.REGISTER,
				service: SOCKET.SERVICES.EXECUTION_METADATA,
				args: {},
			});
		}
		
		// save handler in handler list and return index of the callback so we can unsubscribe later
		return executionMetadataEventHandlers.push(callback);
	};
	
	const unsubscribeToExecutionMetadata = (handlerIndex: SubscriptionIndex) => {
		executionMetadataEventHandlers.splice(handlerIndex-1);
		
		if (executionMetadataEventHandlers.length === 0) {
			_emit({
				event: SOCKET.EVENTS.UNREGISTER,
				service: SOCKET.SERVICES.EXECUTION_METADATA,
				args: {},
			});
		}
	};
	
	return {
		subscribeToBacktest,
		unsubscribeToBacktest,
		subscribeToRun,
		unsubscribeToRun,
		subscribeToExecutionMetadata,
		unsubscribeToExecutionMetadata,
	};
}
