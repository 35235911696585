import { NavLink } from "react-router-dom";
import { Grid, IconButton, Link, useTheme } from "@mui/material";

import {
	Person as AccountIcon,
	Timeline as AssetsIcon,
	CurrencyExchange as CurrencyExchangeIcon,
	DarkMode as DarkModeIcon,
	Dashboard as DashboardIcon,
	LibraryBooksOutlined as InstancesIcon,
	IntegrationInstructionsOutlined as IntegrationInstructionsOutlinedIcon,
	LightMode as LightModeIcon,
	Code as ScriptsIcon,
	Settings as SettingsIcon,
	JoinLeftRounded as SqlIcon,
} from "@mui/icons-material";

import { useAppDispatch } from "hooks";
import { THEME_DARK, toggleTheme } from "store";
import { Tooltip } from "components";
import type { ComponentType, ReactNode } from "react";

// ----------------------------------------------------------------
// NavItem
// ----------------------------------------------------------------

type NavItemProps = {
	icon: ComponentType;
	label: string;
	url?: string;
	onClick?: () => void;
	alt?: boolean;
	end?: boolean;
};

const NavItem = ({
	icon,
	label,
	url,
	onClick,
	alt = false,
	end = false,
}: NavItemProps): ReactNode => {
	const ariaLabel = `Navigate to ${label}`;
	const Icon = icon;
	
	const commonStyles = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "4.8rem",
		height: "4.8rem",
		color: alt ? "nav.dark" : "nav.contrastText",
	};
	const hoverStyles = {
		backgroundColor: "nav.hover",
		transition: "background 0.3s ease-in",
	};
	
	return (
		<li>
			<Tooltip title={label} placement="left">
				{url ? (
					<Link
						component={NavLink}
						aria-label={ariaLabel}
						to={url}
						sx={{
							...commonStyles,
							"&.active": {
								backgroundColor: "background.default",
							},
							"&:not(.active):hover": {
								...hoverStyles,
							},
						}}
						end={end}
					>
						<Icon />
					</Link>
				) : (
					<IconButton
						aria-label={ariaLabel}
						onClick={onClick}
						sx={{
							...commonStyles,
							borderRadius: 0,
							"&:hover": {
								...hoverStyles,
							},
						}}
					>
						<Icon />
					</IconButton>
				)}
			</Tooltip>
		</li>
	);
};

// ----------------------------------------------------------------
// Nav
// ----------------------------------------------------------------

const Nav = (): ReactNode => {
	const dispatch = useAppDispatch();
	const theme = useTheme();
	
	const ThemeSwitchIcon =
		theme.palette.mode === THEME_DARK ? LightModeIcon : DarkModeIcon;
	
	return (
		<Grid
			container
			item
			xs="auto"
			direction="column"
			justifyContent="space-between"
			component="nav"
			aria-label="Main Menu"
			sx={(theme) => ({ backgroundColor: theme.palette.background.nav })}
		>
			<Grid item component="ul">
				<NavItem
					label="Dashboard"
					icon={DashboardIcon}
					url="/"
					end
				/>
				<NavItem label="Scripts" icon={ScriptsIcon} url="/scripts" />
				<NavItem
					label="Instances"
					icon={InstancesIcon}
					url="/instances"
				/>
				<NavItem
					label="Stock Data"
					icon={AssetsIcon}
					url="/stock-data"
				/>
				<NavItem label="SQL Editor" icon={SqlIcon} url="/sql" />
				<NavItem
					label="Backtests"
					icon={IntegrationInstructionsOutlinedIcon}
					url="/backtests"
				/>
				<NavItem
					label="Orders"
					icon={CurrencyExchangeIcon}
					url="/orders"
				/>
				<NavItem label="Settings" icon={SettingsIcon} url="/settings" />
			</Grid>
			<Grid item component="ul">
				<NavItem
					label="Account"
					icon={AccountIcon}
					url="/account"
					alt
				/>
				<NavItem
					label="Switch theme"
					icon={ThemeSwitchIcon}
					onClick={() => dispatch(toggleTheme())}
					alt
				/>
			</Grid>
		</Grid>
	);
};

export default Nav;
