import { api } from ".";
import type {
	CreateProviderKeyRequest,
	ProviderId,
	ProviderKey,
	ProviderKeys,
	Providers,
	RevokeProviderKeyRequest,
} from "types";

export const config = api
	.enhanceEndpoints({
		addTagTypes: ["providers", "provider_keys"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getProviders: build.query<Providers, void>({
				query() {
					return {
						url: "/providers",
						method: "GET",
					};
				},
				providesTags: ["providers"],
			}),
			getKeysForProvider: build.query<ProviderKeys, ProviderId>({
				query(providerId: ProviderId) {
					return {
						url: `/providers/${providerId}/keys`,
						method: "GET",
					};
				},
				providesTags: ["provider_keys"],
			}),
			createKey: build.mutation<ProviderKey, CreateProviderKeyRequest>({
				query({ providerId, ...body }: CreateProviderKeyRequest) {
					return {
						url: `/providers/${providerId}/keys`,
						method: "POST",
						body,
					};
				},
				invalidatesTags: ["provider_keys"],
			}),
			revokeKey: build.mutation<ProviderKey, RevokeProviderKeyRequest>({
				query({ providerId, keyId }: RevokeProviderKeyRequest) {
					return {
						url: `/providers/${providerId}/keys/${keyId}/revoke`,
						method: "POST",
					};
				},
				invalidatesTags: ["provider_keys"],
			}),
		}),
	});

export const {
	useGetProvidersQuery,
	useGetKeysForProviderQuery,
	useCreateKeyMutation,
	useRevokeKeyMutation,
} = config;
