import * as Yup from "yup";

const FIELD_MAX_LENGTH = 50;
const MAX_STARTING_BALANCE = 1000000;

export const BrokerConfigSchema = Yup.object().shape({
	startingBalance: Yup.number()
		.required()
		.default(10000)
		.max(
			MAX_STARTING_BALANCE,
			`Maximum of ${MAX_STARTING_BALANCE} exceeded`
		),
	shortingEnabled: Yup.bool().required().default(false),
	fees: Yup.object().shape({
		pricePerShare: Yup.number().optional().default(0),
		pricePerOrder: Yup.number().optional().default(0),
		percentPerOrder: Yup.number().optional().default(0),
		orderMax: Yup.number().optional().default(0),
		orderMin: Yup.number()
			.optional()
			.default(0)
			.when("orderMax", (maxPerOrder, schema) => {
				return schema.test({
					test: function(value: number) {
						if (
							maxPerOrder !== undefined &&
							maxPerOrder !== null &&
							maxPerOrder !== 0
						) {
							return value <= maxPerOrder;
						}
						
						return true;
					},
					message:
						"Min per order must be less than or equal to max per order",
				});
			}),
	}),
	slippage: Yup.object().shape({
		// time_offset,
		priceOffset: Yup.number()
			.required("Price offset is required")
			.notOneOf([0], "Price offset cannot be zero"),
	}),
});

export const BacktestParameters = Yup.array().of(
	Yup.object().shape({
		permutationType: Yup.string()
			.oneOf(["LIST", "RANGE"])
			.required("Type is required"),
		
		parameter: Yup.string().required("Parameter value is required"),
		
		values: Yup.mixed()
			.when("permutationType", {
				is: "LIST",
				then: Yup.array()
					.of(Yup.string().required("Values are required"))
					.min(1, "At least one param is required")
					.required("Values are required"),
			})
			.when("permutationType", {
				is: "RANGE",
				then: Yup.object().shape({
					fromVal: Yup.number().required("From value is required"),
					toVal: Yup.number().required("To value is required"),
					step: Yup.number().required("Step value is required"),
				}),
			}),
	})
);

export const CreateBacktestFormSchema = Yup.object({
	name: Yup.string()
		.max(FIELD_MAX_LENGTH, `Maximum length of ${FIELD_MAX_LENGTH} exceeded`)
		.required("A backtest name is required"),
	instanceId: Yup.string().required("InstanceId is required"),
	config: Yup.object().shape({
		interval: Yup.object().shape({
			start: Yup.string().required("Start date is required"),
			end: Yup.string().required("End date is required"),
		}),
		brokerConfig: BrokerConfigSchema,
	}),
	params: BacktestParameters,
});
