import * as Yup from "yup";
import { dateIsAfter } from "utils";

export const RequestAssetDataFormSchema = Yup.object({
	dateFrom: Yup.string()
		.required("Pick a from date")
		.test("dateFrom", "Should be less than now", (value) => {
			return dateIsAfter(null, value as string);
		}),
	dateTo: Yup.string()
		.required("Pick a to date")
		.test("dateTo", "Should be less than now", (value) => {
			return dateIsAfter(null, value as string);
		}),
	resolution: Yup.string().required("Pick a time resolution"),
});
