import { useNavigate } from "react-router-dom";
import {
	Delete as DeleteIcon,
	Description as DescriptionIcon,
	Edit as EditIcon,
	PauseCircle as PauseIcon,
	Pending as PendingIcon,
	PlayCircleRounded as PlayIcon,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { GridActionsCellItem } from "@mui/x-data-grid";

import { instanceStateToStatusMap } from "consts";
import { formatDateTime } from "utils";
import { DataTable, Pill } from "components";

import type { ReactNode } from "react";
import type { Instance } from "types";

type InstancesIndexTableProps = {
	instances: Array<Instance>;
	handleEditInstance: (instance: Instance) => void;
	handleStartInstance: (instance: Instance) => void;
	handleStopInstance: (instance: Instance) => void;
	handleDeleteInstance: (instance: Instance) => void;
};

const InstancesIndexTable = ({
	instances,
	handleEditInstance,
	handleStartInstance,
	handleStopInstance,
	handleDeleteInstance,
}: InstancesIndexTableProps): ReactNode => {
	const navigate = useNavigate();
	
	const showResultsForInstanceRun = (instance: Instance) => {
		const { latestRun } = instance;
		
		if (latestRun) {
			navigate(
				`/instances/${instance.instanceId}/runs/${latestRun.runId}`,
				{ replace: false }
			);
		}
	};
	
	const getMenuItemsForInstance = (instance: Instance) => {
		const actions = instance.latestRun
			? [
				<GridActionsCellItem
					icon={<DescriptionIcon />}
					onClick={() => showResultsForInstanceRun(instance)}
					label="Show results"
					showInMenu
				/>,
			  ]
			: [];
		
		return [
			...actions,
			<GridActionsCellItem
				icon={<EditIcon />}
				onClick={() => handleEditInstance(instance)}
				label="Edit instance"
				showInMenu
			/>,
			<GridActionsCellItem
				icon={<DeleteIcon />}
				onClick={() => handleDeleteInstance(instance)}
				label="Delete instance"
				showInMenu
			/>,
		];
	};
	
	const getPlayPauseButtonForInstance = (instance: Instance) => {
		switch (instance.state) {
			case "RUNNING":
				return (
					<IconButton
						aria-label={`Stop instance "${instance.name}"`}
						onClick={() => handleStopInstance(instance)}
						color="error"
					>
						<PauseIcon />
					</IconButton>
				);
			case "NOT_RUNNING":
				return (
					<IconButton
						aria-label={`Run instance "${instance.name}"`}
						onClick={() => handleStartInstance(instance)}
						color="success"
					>
						<PlayIcon />
					</IconButton>
				);
			default:
				return <PendingIcon color="primary" />;
		}
	};
	
	return (
		<>
			<DataTable
				getRowId={(row: Instance) => row.instanceId}
				columns={[
					{
						field: "Play",
						headerName: "",
						flex: 1,
						sortable: false,
						renderCell: ({ row }: { row: Instance }) =>
							getPlayPauseButtonForInstance(row),
					},
					{
						field: "Status",
						flex: 2,
						renderCell: ({ row }: { row: Instance }) => (
							<Pill
								table
								{...instanceStateToStatusMap[row.state]}
							/>
						),
					},
					{
						field: "name",
						headerName: "Name",
						flex: 4,
					},
					{
						field: "latestRun",
						headerName: "Last run",
						type: "dateTime",
						valueFormatter: ({ value }) => formatDateTime(value),
						valueGetter: ({ row }: { row: Instance }) =>
							row.latestRun?.createdAt &&
							new Date(row.latestRun?.createdAt * 1000),
						flex: 2,
					},
					{
						field: "actions",
						type: "actions",
						getActions: ({ row }: { row: Instance }) =>
							getMenuItemsForInstance(row),
					},
				]}
				rows={instances}
			/>
		</>
	);
};

export default InstancesIndexTable;
