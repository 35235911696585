import MUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import { TableContainer } from "@mui/material";

import type { ReactNode } from "react";

type TableColumnItem<T> = {
	id: string;
	label: string;
	mapper: (row: T) => string | number | ReactNode;
	align?: "center" | "left" | "right" | "inherit" | "justify";
	minWidth?: number;
};

type TableProps<T> = {
	columns: Array<TableColumnItem<T>>;
	getRowId: (row: T) => string | number;
	hideHeadings: boolean;
	rows: T[];
	height?: number;
	isModal?: boolean;
};

const defaultProps = {
	getRowId: (row: any): string | number | void => {
		if (row.id !== undefined) return row.id;
		throw new Error(
			"Table error, each row requires an 'id' value, or the table property 'getRowId' to be set"
		);
	},
	hideHeadings: false,
	rows: [] as Array<any>,
};

const Table = <T, >({
	columns,
	getRowId,
	hideHeadings,
	rows,
	height,
	isModal,
}: TableProps<T>): ReactNode => {
	if (
		_.uniq(columns.map((column) => column.label)).length !== columns.length
	) {
		throw new Error("Table error,  table columns must have unique labels");
	}
	
	return (
		<TableContainer sx={{ maxHeight: height }}>
			<MUITable
				aria-label="table"
				stickyHeader={isModal}
				size={isModal ? "small" : undefined}
			>
				<TableHead>
					<TableRow>
						{!hideHeadings &&
							columns.map((column) => (
								<TableCell
									variant="head"
									key={column.label}
									align={column?.align || "left"}
									sx={{
										minWidth: column?.minWidth,
										backgroundColor: "transparent",
										typography: "h6",
										color: "text.secondary",
									}}
								>
									{column.label}
								</TableCell>
							))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={getRowId(row)} data-id={getRowId(row)}>
							{columns.map((column) => (
								<TableCell
									key={column.label}
									align={column?.align}
								>
									{column.mapper(row)}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</MUITable>
		</TableContainer>
	);
};

Table.defaultProps = defaultProps;

export default Table;
