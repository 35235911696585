import {
	type ReactElement,
	type ReactNode,
	createContext,
	useState,
} from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import {
	favouriteAsset,
	selectFavouriteAssets,
	selectRecentlyViewedAssets,
	unFavouriteAsset,
} from "store";

import type { AssetIntervals } from "types";

type AssetsProviderProps = {
	children?: ReactNode | ReactNode[];
};

export interface AssetsContextValue {
	addFavorite: (asset: AssetIntervals) => void;
	favouriteAssets: AssetIntervals[];
	isAssetDrawerOpen: boolean;
	removeFavorite: (asset: AssetIntervals) => void;
	toggleAssetDrawer: () => void;
	recentlyViewed: AssetIntervals[];
}

export const AssetsContext = createContext<AssetsContextValue | undefined>(
	undefined
);

export function AssetsProvider({
	children,
}: AssetsProviderProps): ReactElement {
	const dispatch = useAppDispatch();
	const favouriteAssets = useAppSelector<AssetIntervals[]>(
		selectFavouriteAssets
	);
	const recentlyViewed = useAppSelector<AssetIntervals[]>(
		selectRecentlyViewedAssets
	);
	
	const [isAssetDrawerOpen, setIsAssetDrawerOpen] = useState(false);
	
	const addFavorite = (asset: AssetIntervals): void => {
		dispatch(favouriteAsset(asset));
	};
	
	const removeFavorite = (asset: AssetIntervals): void => {
		dispatch(unFavouriteAsset(asset));
	};
	
	const toggleAssetDrawer = (): void =>
		setIsAssetDrawerOpen(!isAssetDrawerOpen);
	
	return (
		<AssetsContext.Provider
			value={{
				addFavorite,
				favouriteAssets,
				isAssetDrawerOpen,
				removeFavorite,
				toggleAssetDrawer,
				recentlyViewed,
			}}
		>
			{children}
		</AssetsContext.Provider>
	);
}
