import {
	Description as DescriptionIcon,
	DoneAll as DoneAllIcon,
	Edit as EditIcon,
	Remove as RemoveIcon,
	Sync as SyncIcon,
} from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";

import { DataTable, Pill } from "components";
import { formatDateTime } from "utils";

import type { AssetHistoricalDataRequest, AssetRealtimeDataRequest, Interval, PillColor, PillVariant } from "types";
import type { ReactNode } from "react";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import type { SerializedError } from "@reduxjs/toolkit";

type AssetMetadataTableProps = {
	intervals: Interval[];
	populateBlock: (interval: Interval) => void;
	cancelRequest: (reqId: string) => Promise<
	{ data: AssetHistoricalDataRequest; } | { error: FetchBaseQueryError | SerializedError; }>
	stopHarvesting: (resolution: string) => Promise<
	{ data: AssetRealtimeDataRequest; } | { error: FetchBaseQueryError | SerializedError; }>;
};

const assetStatus = (row: Interval) => {
	if (row.loaded) {
		return "COMPLETED";
	} else if (!row.request) {
		return "MISSING";
	} else if (row.request.state === "PENDING") {
		return "PENDING";
	} else if (row.request.state === "QUEUED") {
		return "PENDING";
	} else if (row.request.state === "COMPLETED") {
		return "COMPLETED";
	} else {
		return "RUNNING";
	}
};

const pillStatus = (status: string) => {
	let Icon = RemoveIcon;
	let color: PillColor = undefined;
	let text = "Pending";
	let variant: PillVariant = "outlined";
	
	switch (status) {
		case "MISSING":
			text = "Unpopulated";
			break;
		case "PENDING":
			text = "Pending";
			break;
		case "RUNNING":
			Icon = SyncIcon;
			color = "primary";
			text = "Populating";
			variant = undefined;
			break;
		case "HARVESTING":
			Icon = SyncIcon;
			color = "primary";
			text = "Harvesting";
			variant = undefined;
			break;
		case "COMPLETED":
			Icon = DoneAllIcon;
			color = "success";
			text = "Available";
			variant = undefined;
			break;
		default:
			break;
	}
	
	return (
		<Pill color={color} text={text} variant={variant} table icon={Icon} />
	);
};

const AssetMetadataTable = ({
	intervals,
	populateBlock,
	cancelRequest,
	stopHarvesting,
}: AssetMetadataTableProps): ReactNode => (
	<DataTable
		getRowId={(row) => `${row.resolution}:${row.tsFrom}`}
		columns={[
			{
				field: "From",
				type: "dateTime",
				valueFormatter: ({ value }: { value: Date }) =>
					value ? formatDateTime(value, "after") : "-",
				valueGetter: ({ row }: { row: Interval }) =>
					new Date(row.tsFrom * 1000),
				flex: 3,
			},
			{
				field: "Until",
				type: "dateTime",
				valueFormatter: ({ value }) =>
					value ? formatDateTime(value, "after") : "Now",
				valueGetter: ({ row }: { row: Interval }) =>
					new Date(row.tsTo * 1000),
				flex: 3,
			},
			{
				field: "resolution",
				headerName: "Resolution",
				flex: 2,
				valueGetter: ({ row }: { row: Interval }) => row.resolution,
			},
			{
				field: "status",
				headerName: "Status",
				flex: 2,
				valueGetter: ({ row }: { row: Interval }) => assetStatus(row),
				renderCell: ({ row }: { row: Interval }) =>
					pillStatus(
						row.harvesting ? "HARVESTING" : assetStatus(row)
					),
			},
			{
				field: "actions",
				type: "actions",
				getActions: ({ row }: { row: Interval }) =>
					[
						assetStatus(row) === "MISSING" ? (
							<GridActionsCellItem
								icon={<DescriptionIcon />}
								onClick={() => populateBlock(row)}
								label="Populate"
								showInMenu
							/>
						) : (
							<></>
						),
						assetStatus(row) === "PENDING" || assetStatus(row) === "RUNNING" ? (
							<GridActionsCellItem
								icon={<EditIcon />}
								onClick={() =>
									cancelRequest(
										row.request && row.request.requestId
									)
								}
								label="Cancel"
								showInMenu
							/>
						) : (
							<></>
						),
						row.harvesting ? (
							<GridActionsCellItem
								icon={<EditIcon />}
								onClick={() => stopHarvesting(row.resolution)}
								label="Cancel"
								showInMenu
							/>
						) : (
							<></>
						),
					].filter((a) => a),
			},
		]}
		rows={intervals}
		filter={true}
		search={false}
	/>
);

export default AssetMetadataTable;
