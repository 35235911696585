import {
	DoneAll as DoneAllIcon,
	StopCircle as StopCircleIcon,
	Sync as SyncIcon,
} from "@mui/icons-material";

export const historicalRequestsStateToStatusMap = {
	PENDING: {
		text: "Pending",
		color: "success",
		icon: SyncIcon,
	},
	QUEUED: {
		text: "Queued",
		color: "primary",
		icon: SyncIcon,
	},
	IN_PROGRESS: {
		text: "Populating",
		color: "success",
		icon: SyncIcon,
	},
	CANCELLING: {
		text: "Cancelling",
		color: "error",
		icon: StopCircleIcon,
	},
	CANCELLED: {
		text: "Cancelled",
		color: "error",
		icon: StopCircleIcon,
	},
	REJECTED: {
		text: "Rejected",
		color: "error",
		icon: StopCircleIcon,
	},
	COMPLETED: {
		text: "Populated",
		color: "success",
		icon: DoneAllIcon,
	},
} as const;
