import {
	type ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
	DoneAll as DoneAllIcon,
	Remove as RemoveIcon,
	StopCircle as StopCircleIcon,
	Sync as SyncIcon,
} from "@mui/icons-material";

import { COMPARE_COLORS } from "consts";
import { DataTable, Pill } from "components";
import { ToastContext, ToastContextType } from "context";
import { formatDateTimeWithSeconds } from "utils";

import type { ExtendedBacktest } from "containers";
import type {
	GridCallbackDetails,
	GridRowSelectionModel,
} from "@mui/x-data-grid";
import type { PillColor, PillVariant, Run } from "types";

const MAX_COMPARISONS = 4;

const instanceRunStatus = (row: Run) => {
	if (row.stoppedAt) {
		return "COMPLETED";
	} else if (row.stoppingAt) {
		return "STOPPING";
	} else if (row.startedAt) {
		return "RUNNING";
	} else {
		return "PENDING";
	}
};

const pillStatus = (status: string) => {
	let Icon = RemoveIcon;
	let color: PillColor = undefined;
	let text = "Pending";
	let variant: PillVariant = "outlined";
	
	switch (status) {
		case "CREATED":
			text = "Created";
			break;
		case "RUNNING":
			Icon = SyncIcon;
			color = "primary";
			text = "Running";
			variant = undefined;
			break;
		case "STOPPING":
			Icon = SyncIcon;
			color = "error";
			text = "Stopping";
			variant = undefined;
			break;
		case "STOPPED":
			Icon = StopCircleIcon;
			color = "error";
			text = "Stopped";
			variant = undefined;
			break;
		case "COMPLETED":
			Icon = DoneAllIcon;
			color = "success";
			text = "Finished";
			variant = undefined;
			break;
		default:
			break;
	}
	
	return (
		<Pill color={color} text={text} variant={variant} table icon={Icon} />
	);
};

type InstanceRunsTableProps = {
	runHistory: Array<ExtendedBacktest>;
	handleCompareRuns?: (runs: Array<Run>) => void;
	runId: string;
	handleShowRun: (rowId: string) => void;
};

const InstanceRunsTable = ({
	runHistory,
	handleCompareRuns,
	runId,
	handleShowRun,
}: InstanceRunsTableProps): ReactNode => {
	// STATE
	const [selectedRunIds, setSelectedRunIds] = useState<any[]>([]);
	
	const { createToast } = useContext(ToastContext) as ToastContextType;
	
	const handleSelectForCompare = useCallback((
		selectedIds: GridRowSelectionModel,
		details?: GridCallbackDetails
	): void => {
		if (selectedIds.length > MAX_COMPARISONS) {
			createToast(`Exceeded maximum of ${MAX_COMPARISONS} comparisons`);
		} else {
			setSelectedRunIds(selectedIds);
			
			if (handleCompareRuns) {
				handleCompareRuns(
					runHistory.filter((run) => selectedIds.includes(run.runId))
				);
			}
		}
	}, [createToast, handleCompareRuns, setSelectedRunIds, runHistory]);
	
	const dataGridOptions = {
		checkboxSelection: true,
		onRowSelectionModelChange: handleSelectForCompare,
		rowSelectionModel: selectedRunIds,
	};
	
	useEffect(() => {
		if (runId !== "") handleSelectForCompare([runId]);
	}, [runId, handleSelectForCompare]);
	
	return (
		<Grid container item direction="column">
			<Grid item>
				<Typography variant="h1" my={4}>
					Run history
				</Typography>
			</Grid>
			<Grid item>
				<DataTable
					getRowId={(row: Run) => row.runId}
					columns={[
						{
							field: "name",
							headerName: "Name",
							valueGetter: ({ row }: { row: Run }) => row.runId,
							flex: 2,
						},
						{
							field: "color",
							headerName: "Color",
							renderCell: ({ row }: { row: Run }) => (
								<Box
									component="div"
									sx={{
										p: 1,
										m: 1,
										backgroundColor:
											COMPARE_COLORS[
												selectedRunIds.findIndex(
													(runId) =>
														runId === row.runId
												)
											],
									}}
								/>
							),
							flex: 1,
							sortable: false,
						},
						{
							field: "params",
							headerName: "Parameters",
							renderCell: ({ row }: { row: any }) => (
								<Grid container columnGap={1}>
									{Object.keys(row.params).map((key, i) => (
										<Grid item key={i}>
											<Pill
												text={`${key}=${row.params[key]}`}
												table
											/>
										</Grid>
									))}
								</Grid>
							),
							flex: 8,
							sortable: false,
						},
						{
							field: "status",
							headerName: "Run status",
							flex: 2,
							sortable: false,
							renderCell: ({ row }: { row: Run }) =>
								pillStatus(instanceRunStatus(row)),
						},
						{
							field: "startedAt",
							headerName: "Started at",
							type: "dateTime",
							valueFormatter: ({ value }) =>
								formatDateTimeWithSeconds(value),
							valueGetter: ({ row }: { row: Run }) =>
								row.startedAt && new Date(row.startedAt * 1000),
							flex: 2,
						},
						{
							field: "finishedAt",
							headerName: "Finished at",
							type: "dateTime",
							valueFormatter: ({ value }) =>
								value && formatDateTimeWithSeconds(value),
							valueGetter: ({ row }: { row: Run }) =>
								row.stoppedAt && new Date(row.stoppedAt * 1000),
							flex: 2,
						},
						{
							field: "View",
							headerName: "",
							flex: 2,
							sortable: false,
							renderCell: ({ row }: { row: Run }) => {
								return row.runId === runId ? (
									<Button color="success" variant="contained">
										Viewing
									</Button>
								) : (
									<Button
										variant="contained"
										onClick={() => handleShowRun(row.runId)}
									>
										View run
									</Button>
								);
							},
						},
					]}
					{...dataGridOptions}
					rows={runHistory}
					search={false}
				/>
			</Grid>
		</Grid>
	);
};

export default InstanceRunsTable;
