// TODO: Rename utils files and move "functions" into relevant categories
import type {
	AnyTypedFunction,
	AssetData,
	AssetDataItem,
} from "types";

export const debounce = (
	expensiveFunction: AnyTypedFunction,
	delay: number
): AnyTypedFunction => {
	let timeoutId: NodeJS.Timeout;
	
	return function(...args): void {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(
			() => expensiveFunction.apply(this, args),
			delay
		);
	};
};

export const streamAsyncIteratorByLine = async function* (
	stream: ReadableStream
): AsyncGenerator<string, any, any> {
	// Get a lock on the stream
	const reader = stream.getReader();
	const utf8Decoder = new TextDecoder("utf-8");
	
	const re = /\r\n|\n|\r/gm;
	let buffer = "";
	
	try {
		let done = false;
		let chunk;
		
		while (!done) {
			// Populate the buffer
			// NOTE: reader.read() does not work on safari
			({ done, value: chunk } = await reader.read());
			if (chunk) buffer += utf8Decoder.decode(chunk, { stream: true });
			// Consume the buffer
			const lines = buffer.split(re);
			
			buffer = lines.pop() ?? "";
			for (const line of lines) yield line;
		}
		// Deal with any remainder
		if (buffer) yield buffer;
	} finally {
		reader.releaseLock();
	}
};

export const flattenAssetData = (
	assetData: AssetData
): Array<AssetDataItem> => {
	const items: Array<AssetDataItem> = [];
	
	(assetData.data || []).forEach((assetDataNode) => {
		if (assetDataNode.data) {
			assetDataNode.data.forEach((item) => items.push(item));
		}
	});
	
	return items;
};

// Checks the overlap between to ranges of numbers - great for comparing
// two sets of timestamps that you want to "check" for an overlap between
export const checkOverlap = (
	a1: number,
	a2: number,
	b1: number,
	b2: number
): boolean => {
	return Math.max(a1, b1) <= Math.min(a2, b2);
};