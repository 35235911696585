import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import {
	IconButton,
	ListItem,
	ListItemButton,
	ListItemText,
} from "@mui/material";

import { formatCurrency, isPositiveNumber } from "utils";

import type { ReactNode } from "react";
import type { AssetIntervals } from "types";

export type AssetDrawerItemProps = {
	asset: AssetIntervals;
	handleAddFavorite: (asset: AssetIntervals) => void;
	handleAssetClicked: (asset: AssetIntervals) => void;
	handleRemoveFavorite: (asset: AssetIntervals) => void;
	isFavorite: boolean;
};

const AssetDrawerItem = ({
	asset,
	handleAddFavorite,
	handleAssetClicked,
	handleRemoveFavorite,
	isFavorite,
}: AssetDrawerItemProps): ReactNode => {
	const Icon = isFavorite ? BookmarkIcon : BookmarkBorderIcon;
	
	return (
		<ListItem
			secondaryAction={
				<IconButton
					edge="end"
					aria-label="favourite"
					onClick={() =>
						isFavorite
							? handleRemoveFavorite(asset)
							: handleAddFavorite(asset)
					}
					data-testid="favouriteButton"
				>
					<Icon
						sx={(theme) => ({
							color: !isFavorite
								? "text.secondary"
								: theme.palette.mode === "light"
									? "info.main"
									: "text.primary",
						})}
					/>
				</IconButton>
			}
			disablePadding
		>
			<ListItemButton
				role={undefined}
				onClick={() => handleAssetClicked(asset)}
				dense
				sx={{ pl: 1 }}
				data-testid="assetButton"
			>
				<ListItemText
					id={`checkbox-list-label-${asset.ticker}`}
					primary={asset.ticker}
				/>
				{/* TO DO Replace for value data which currently is not in asset */}
				<ListItemText
					id={`checkbox-list-label-${asset.cik}`}
					primary={asset.latestPrice && formatCurrency(asset.latestPrice, "USD")}
					sx={{
						flexGrow: 0,
						color: isPositiveNumber(asset.latestPrice)
							? "success.main"
							: "warning.main",
					}}
				/>
			</ListItemButton>
		</ListItem>
	);
};

export default AssetDrawerItem;
