import { Typography } from "@mui/material";
import { styled } from "@mui/system";

import type { ReactNode } from "react";

const TileComponent = styled("div", {
	shouldForwardProp: (prop) =>
		prop !== "color" && prop !== "variant" && prop !== "sx",
	name: "Tile",
	slot: "Root",
	overridesResolver: (props, styles) => [styles.root, styles[props.color]],
})(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	borderRadius: theme.shape.borderRadius,
	"& .MuiSvgIcon-root": {
		marginRight: theme.spacing(0.5),
	},
}));

type TileProps = {
	color: "primary" | "secondary" | "success" | "warning";
	heroText: string;
	text: string;
	heroIcon?: React.ComponentType<{}>;
	heroSubscript?: string;
};

const defaultProps = {
	color: "secondary",
};

const Tile = ({
	color,
	heroText,
	text,
	heroIcon,
	heroSubscript,
}: TileProps): ReactNode => {
	const HeroIcon = heroIcon;
	
	return (
		<TileComponent color={color}>
			{heroText && (
				<Typography
					variant="dashboard"
					sx={{
						mb: 1,
						display: "flex",
						alignItems: "flex-end",
						lineHeight: 1,
					}}
				>
					{HeroIcon && <HeroIcon />}
					{heroText}
					{heroSubscript && (
						<Typography variant="body1">{heroSubscript}</Typography>
					)}
				</Typography>
			)}
			{text}
		</TileComponent>
	);
};

Tile.defaultProps = defaultProps;

export default Tile;
