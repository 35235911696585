import { type ReactNode, useState } from "react";
import { getIn, useFormik } from "formik";
import dayjs from "dayjs";
import { Link as RouterLink } from "react-router-dom";
import {
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import { Button, Collapse, Grid, Link, Stack, Typography } from "@mui/material";

import {
	Alert,
	DateTimeField,
	Modal,
	SelectField,
	TextField,
} from "components";
import { useCreateBacktestMutation, useGetInstancesQuery } from "services";
import { CreateBacktestFormSchema } from "./CreateBacktestForm.schema";
import BrokerConfigForm from "./BrokerConfigForm";
import ParametersForm from "./ParametersForm";

import type {
	Backtest,
	BacktestParametersRange,
	BacktestParametersValue,
} from "types";

export interface CreateBacktestFormValues {
	instanceId: string;
	name: string;
	config: {
		interval: {
			start: dayjs.Dayjs;
			end: dayjs.Dayjs;
		};
		brokerConfig: {
			startingBalance: number;
			shortingEnabled: boolean;
			fees: {
				pricePerShare: number;
				pricePerOrder: number;
				percentPerOrder: number;
				orderMax: number;
				orderMin: number;
			};
			slippage: {
				priceOffset: number;
			};
		};
	};
	params: (BacktestParametersValue | BacktestParametersRange)[];
}

type CreateBacktestFormProps = {
	handleFormClose: () => void;
	backtests: Array<Backtest>;
	show: boolean;
};

export const FORM_INITIAL_STATE: CreateBacktestFormValues = {
	instanceId: "",
	name: "",
	config: {
		interval: {
			start: dayjs().subtract(1, "minute"),
			end: dayjs(),
		},
		brokerConfig: {
			startingBalance: 10000,
			shortingEnabled: false,
			fees: {
				pricePerShare: 0,
				pricePerOrder: 0,
				percentPerOrder: 0,
				orderMax: 0,
				orderMin: 0,
			},
			slippage: {
				priceOffset: 0.1,
			},
		},
	},
	params: [],
};

const CreateBacktestForm = ({
	handleFormClose,
	show,
}: CreateBacktestFormProps): ReactNode => {
	const [openBrokerInput, setOpenBrokerInputs] = useState(false);
	const [openParameters, setOpenParameters] = useState(false);
	const [formError, setFormError] = useState<string>("");
	const [createBackTest, { isLoading }] = useCreateBacktestMutation();
	
	const { instances } = useGetInstancesQuery(undefined, {
		skip: !show,
		selectFromResult: ({ data }) => ({
			instances: data?.instances ?? [],
		}),
	});
	
	const handleFormSubmit = (form: CreateBacktestFormValues) => {
		const roundedForm = {
			...form,
			config: {
				...form.config,
				interval: {
					start: Math.floor(form.config.interval.start.unix()),
					end: Math.floor(form.config.interval.end.unix()),
				},
			},
		};
		
		createBackTest(roundedForm)
			.unwrap()
			.then(() => handleFormClose())
			.catch((e) => setFormError(e.message));
	};
	
	const formik = useFormik({
		initialValues: FORM_INITIAL_STATE,
		validationSchema: CreateBacktestFormSchema,
		onSubmit: handleFormSubmit,
		validateOnChange: true,
	});
	
	const paramsValidator = () => {
		const keys = (formik.values.params || []).map((x, i) =>
			getIn(formik.values, `params[${i}].parameter`)
		);
		
		return keys.find((x, i) => keys.indexOf(x) !== i) === undefined;
	};
	const paramsValid = paramsValidator();
	
	return (
		<Modal
			title="Create backtest"
			handleClose={handleFormClose}
			show={show}
			onSave={formik.handleSubmit}
			saveButtonText="Create"
			isValid={formik.dirty && formik.isValid && paramsValid}
			isSubmitting={isLoading}
			size="md"
		>
			{formError && <Alert>{formError}</Alert>}
			<form onSubmit={formik.handleSubmit}>
				<Stack position="relative">
					<TextField
						id="name"
						modal
						name="name"
						label="Backtest name"
						placeholder="Enter a name to identify your new backtest..."
						value={formik.values.name}
						onChange={formik.handleChange}
						error={formik.touched.name && Boolean(formik.errors.name)}
						helperText={formik.touched.name && formik.errors.name}
					/>
					{instances.length === 0 && (
						<Alert type="info">
							You need to create an instance before creating a backtest.{" "}
							<Link component={RouterLink} to="/instances">
								Create one now
							</Link>
						</Alert>
					)}
					{instances.length > 0 && (
						<SelectField
							id="instanceId"
							modal
							options={instances.map((instance) => ({
								label: instance.name,
								value: instance.instanceId,
							}))}
							name="instanceId"
							value={formik.values.instanceId}
							label="Instance to be backtested"
							onChange={formik.handleChange}
							error={
								formik.touched.instanceId && Boolean(formik.errors.instanceId)
							}
							helperText={formik.touched.instanceId && formik.errors.instanceId}
						/>
					)}
					<Typography variant="h5" mt={2}>
						Time Interval
					</Typography>
					<>
						<Grid container wrap="nowrap" columnGap={4} sx={{ width: "50%" }}>
							<Grid item container>
								<DateTimeField
									label="Start"
									value={formik.values.config.interval.start}
									onChange={(value) => {
										formik.setFieldValue("config.interval.start", value);
									}}
									modal={true}
								/>
							</Grid>
							<Grid item container>
								<DateTimeField
									label="End"
									value={formik.values.config.interval.end}
									onChange={(value) => {
										formik.setFieldValue("config.interval.end", value);
									}}
									modal={true}
								/>
							</Grid>
						</Grid>
						<Button
							onClick={() => setOpenParameters(!openParameters)}
							endIcon={
								openParameters ? (
									<KeyboardArrowUpIcon />
								) : (
									<KeyboardArrowDownIcon />
								)
							}
							sx={{
								width: "100%",
								justifyContent: "space-between",
								px: 0,
								mt: 3,
								color: "text.primary",
							}}
						>
							Parameters
						</Button>
						<Collapse in={openParameters}>
							{!paramsValid && (
								<Alert type="info">Parameter keys must be unique</Alert>
							)}
							<ParametersForm formik={formik} />
						</Collapse>
						<Button
							onClick={() => setOpenBrokerInputs(!openBrokerInput)}
							endIcon={
								openBrokerInput ? (
									<KeyboardArrowUpIcon />
								) : (
									<KeyboardArrowDownIcon />
								)
							}
							sx={{
								width: "100%",
								justifyContent: "space-between",
								px: 0,
								mt: 3,
								color: "text.primary",
							}}
						>
							Broker scenarios
						</Button>
						<Collapse in={openBrokerInput}>
							<BrokerConfigForm<CreateBacktestFormValues> formik={formik} />
						</Collapse>
					</>
				</Stack>
			</form>
		</Modal>
	);
};

export default CreateBacktestForm;
