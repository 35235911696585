import { MouseEvent, type ReactNode, useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Button, Menu, MenuItem } from "@mui/material";
import {
	KeyboardArrowDown as KeyboardArrowDownIcon,
	type SvgIconComponent,
} from "@mui/icons-material";

export type MenuItemProps = {
	handleClick: Function;
	icon?: SvgIconComponent;
	text: string;
};

type MenuProps = {
	items: Array<MenuItemProps>;
	label: string;
};

const defaultProps = {
	items: [],
	label: "Actions",
};

const PopoverMenuButton = ({ items, label }: MenuProps): ReactNode => {
	const [anchorElement, setAnchorElement] =
		useState<HTMLAnchorElement | null>(null);
	
	const isOpen = !!anchorElement;
	const menuId = uniqueId("popover-menu-button-");
	
	const handleItemClicked = (
		e: MouseEvent<HTMLAnchorElement>,
		item: MenuItemProps
	): void => {
		handleToggleMenu(e);
		item.handleClick();
	};
	
	const handleToggleMenu = (e: MouseEvent<HTMLAnchorElement>): void => {
		const anchor = anchorElement ? null : e.currentTarget;
		
		setAnchorElement(anchor);
	};
	
	return (
		<>
			<Button
				component="a"
				id={`${menuId}-button`}
				aria-controls={isOpen ? menuId : undefined}
				aria-haspopup="true"
				aria-expanded={isOpen ? "true" : undefined}
				variant="contained"
				onClick={handleToggleMenu}
				endIcon={<KeyboardArrowDownIcon />}
			>
				{label}
			</Button>
			
			<Menu
				anchorEl={anchorElement}
				id={menuId}
				onClose={handleToggleMenu}
				open={isOpen}
			>
				{items.map((item, idx) => {
					const Icon = item.icon;
					
					return (
						<MenuItem
							component="a"
							key={idx}
							onClick={(e) => handleItemClicked(e, item)}
						>
							{Icon && <Icon fontSize="small" />}
							{item.text}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

PopoverMenuButton.defaultProps = defaultProps;

export default PopoverMenuButton;
