import { api } from ".";
import type {
	CreateScriptRequest,
	Script,
	ScriptId,
	ScriptVersion,
	ScriptVersionRequest,
	ScriptVersions,
	Scripts,
	VersionCodeResult,
} from "types";

export const script = api
	.enhanceEndpoints({
		addTagTypes: ["code", "code_versions", "script", "scripts"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getScript: build.query<Script, ScriptId>({
				query(scriptId: ScriptId) {
					return {
						url: `/scripts/${scriptId}`,
						method: "GET",
					};
				},
				providesTags: ["script"],
			}),
			getScripts: build.query<Script[], void>({
				query() {
					return {
						url: "/scripts",
						method: "GET",
					};
				},
				transformResponse: (response: Scripts) => response.scripts,
				providesTags: ["scripts"],
			}),
			getLatestCode: build.query<VersionCodeResult, ScriptId>({
				query(scriptId: ScriptId) {
					return {
						url: `/scripts/${scriptId}/code`,
						method: "GET",
					};
				},
				providesTags: ["code"],
			}),
			getCodeForVersion: build.query<VersionCodeResult, ScriptVersion | null>({
				query(version: ScriptVersion) {
					return {
						url: `/scripts/${version.scriptId}/versions/${version.commitSHA}/code`,
						method: "GET",
					};
				},
				providesTags: ["code"],
			}),
			getVersions: build.query<ScriptVersions, ScriptId>({
				query(scriptId: ScriptId) {
					return {
						url: `/scripts/${scriptId}/versions`,
						method: "GET",
					};
				},
				providesTags: ["code_versions"],
			}),
			createDraft: build.mutation<Script, VersionCodeResult>({
				query(req: VersionCodeResult) {
					return {
						url: `/scripts/${req.scriptId}/update_code`,
						method: "POST",
						body: req,
					};
				},
				invalidatesTags: ["code"],
			}),
			createScriptVersion: build.mutation<ScriptVersion, ScriptVersionRequest>({
				query({ scriptId, versionData }: ScriptVersionRequest) {
					return {
						url: `/scripts/${scriptId}/versions`,
						method: "POST",
						body: versionData,
					};
				},
				invalidatesTags: ["code", "code_versions"],
			}),
			createScript: build.mutation<Script, CreateScriptRequest>({
				query(scritpData: CreateScriptRequest) {
					return {
						url: "/scripts",
						method: "POST",
						body: scritpData,
					};
				},
				invalidatesTags: ["scripts"],
			}),
		}),
	});

export const {
	useGetScriptQuery,
	useGetLatestCodeQuery,
	useGetCodeForVersionQuery,
	useGetVersionsQuery,
	useCreateDraftMutation,
	useCreateScriptVersionMutation,
	useCreateScriptMutation,
	useGetScriptsQuery,
} = script;
