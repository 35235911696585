import { useFormik } from "formik";
import { useState } from "react";

import { Alert, Modal, TextField } from "components";
import { useCreateQueryMutation, useUpdateQueryMutation } from "services";
import { CreateQueryFormSchema } from "./CreateQuery.schema";
import type { Query } from "types";

import type { ReactNode } from "react";

interface Formvalues {
	name: string;
}

const FORM_INITIAL_STATE: Formvalues = {
	name: "",
};

const SaveOrEditSqlModal = ({
	show,
	handleClose,
	isEdit,
	setIsEdit,
	setCurrentQuery,
	currentQuery = null,
}: {
	show: boolean;
	handleClose: () => void;
	isEdit: boolean;
	setIsEdit: () => void;
	setCurrentQuery: (query: Query) => void;
	currentQuery: Query | null,
}): ReactNode => {
	const [createQuery, { isLoading }] = useCreateQueryMutation();
	const [updateQuery] = useUpdateQueryMutation();
	
	const [formError, setFormError] = useState<string>("");
	
	const handleFormSubmit = (form: { name: string }) => {
		const apiCall = isEdit ? updateQuery({
			id: currentQuery?.id || "",
			name: form.name,
		}) : createQuery({
			name: form.name,
			code: "",
		});
		
		apiCall.unwrap().then((query) => {
			setCurrentQuery(query);
			handleClose();
			setIsEdit();
		}).catch((e) => setFormError(e.message));
	};
	
	const formik = useFormik({
		initialValues: isEdit ? { name: currentQuery?.name || "" } : FORM_INITIAL_STATE,
		validationSchema: CreateQueryFormSchema,
		onSubmit: (data) => handleFormSubmit(data),
		validateOnChange: true,
		enableReinitialize: true,
	});
	
	return (
		<Modal
			title={`${isEdit ? "Save" : "Create"} Query`}
			handleClose={() => {
				handleClose();
				setIsEdit();
			}}
			show={show}
			onSave={formik.handleSubmit}
			saveButtonText={`${isEdit ? "Save" : "Create"}`}
			isValid={formik.dirty && formik.isValid}
			isSubmitting={isLoading}
			size="sm"
		>
			{formError && <Alert>{formError}</Alert>}
			<form onSubmit={formik.handleSubmit}>
				<TextField
					modal
					id="name"
					name="name"
					label="Query name"
					placeholder="Enter a name to identify this query..."
					value={formik.values.name}
					onChange={formik.handleChange}
					error={
						formik.touched.name && Boolean(formik.errors.name)
					}
					helperText={
						formik.touched.name && formik.errors.name
							? formik.errors.name
							: undefined
					}
				/>
			</form>
		</Modal>
	);
};

export default SaveOrEditSqlModal;