import {
	type ReactNode,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
	BacktestIndexTable,
	BigCard,
	CreateBacktestForm,
	Loading,
} from "components";
import {
	useGetBacktestsQuery,
	useLazyGetBacktestInstancesQuery,
	useStartBacktestMutation,
	useStopBacktestMutation,
} from "services";
import type {
	Backtest,
	ContainerProps,
} from "types";

type SelectFromResult = {
	backtests: Backtest[];
	backtestCount: number;
	isLoading: boolean;
};

const BacktestListContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	const [isCreating, setIsCreating] = useState<boolean>(false);
	
	const [trigger] = useLazyGetBacktestInstancesQuery();
	
	const navigate = useNavigate();
	
	const handleShowBacktest = (backtest: Backtest) => {
		const { backtestId } = backtest;
		
		trigger(backtestId)
			.unwrap()
			.then((instances) => {
				const instance = instances.find((instance) => instance.latestRun);
				
				navigate(`/backtests/${backtest.backtestId}/runs/${instance?.latestRun!.runId}`);
			});
	};
	
	const [startBacktest] = useStartBacktestMutation();
	const [stopBacktest] = useStopBacktestMutation();
	const { backtests, isLoading } = useGetBacktestsQuery(undefined, {
		selectFromResult: ({ data, isLoading }): SelectFromResult => ({
			backtests: data?.backtests ?? [],
			backtestCount: data?.backtestCount ?? 0,
			isLoading,
		}),
	});
	
	const toggleCreate = useCallback((): void => {
		setIsCreating(!isCreating);
	}, [isCreating]);
	
	useEffect(() => {
		setBreadcrumbs([{ text: "Backtests", url: "/backtests" }]);
		setHeaderActions({
			create: [
				{
					label: "Create new backtest",
					onClick: toggleCreate,
					createIcon: true,
				},
			],
		});
	}, [setBreadcrumbs, setHeaderActions, toggleCreate]);
	
	return (
		<>
			{isLoading && <Loading message="Loading backtests..." />}
			{backtests.length === 0 && !isLoading && (
				<BigCard
					ctaLinkText="Create one now"
					handleClick={() => setIsCreating(true)}
					message="You don't have any backtests."
				/>
			)}
			{backtests.length > 0 && !isLoading && (
				<BacktestIndexTable
					backtests={backtests}
					handleShowBacktest={handleShowBacktest}
					handleStartBacktest={(bt) => startBacktest(bt.backtestId)}
					handleStopBacktest={(bt) => stopBacktest(bt.backtestId)}
				/>
			)}
			<CreateBacktestForm
				show={isCreating}
				handleFormClose={toggleCreate}
				backtests={backtests}
			/>
		</>
	);
};

export default BacktestListContainer;
