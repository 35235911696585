import type { ChartData, DateType } from "types";

export enum OrderOp {
	BUY = "BUY",
	SELL = "SELL",
}

enum Event {
	ORDER_SUCCESS = "ORDER_SUCCESS",
	ORDER_FAILURE = "ORDER_FAILURE",
}

export type BrokerOrder = {
	id: string;
	symbol: string;
	op: OrderOp;
	units: number;
	unit_price?: number | null;
	fee?: number | null;
	req_id: string;
	req_timestamp?: DateType | null;
	created_at: DateType;
	updated_at?: DateType | null;
	timestamp?: DateType | null;
};

export type BrokerEvent = {
	msgtype: Event;
	timestamp: DateType;
	event_id: string;
	order: BrokerOrder;
	error?: string | null;
};

export type BrokerOrders = {
	accountId: string,
	orderCount: number,
	orders: BrokerEvent[],
};

export type BrokerAnnotations = {
	orders: ChartData[],
};