import { SOCKET } from "consts";
import { SocketService, api } from ".";
import type {
	InstanceId,
	RunEvent,
	RunEvents,
	RunEventsRequest,
	Runs,
} from "types";

// Define an uppercase const for the event type, as event types from the websocket are uppercase
// TODO - Update the the front end event casing to match the backend
const SOCKET_CHART_EVENT = SOCKET.SERVICES.CHART.toUpperCase();

export const run = api
	.enhanceEndpoints({
		addTagTypes: ["run", "runs", "run_events"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getRuns: build.query<Runs, InstanceId | void>({
				query(instanceId: InstanceId) {
					return {
						url: `/instances/${instanceId}/runs`,
						method: "GET",
					};
				},
				providesTags: ["runs"],
			}),
			getRunEvents: build.query<RunEvents, RunEventsRequest>({
				query: ({
					instanceId, runId, msgtype, metrics, limit, offset, tsFrom, tsTo,
				}: RunEventsRequest) => ({
					url: `/instances/${instanceId}/runs/${runId}/events`,
					method: "GET",
					params: {
						msgtype: msgtype ? msgtype.join(",") : undefined,
						metrics: metrics ? metrics.join(",") : undefined,
						limit,
						offset,
						tsFrom,
						tsTo,
					},
				}),
				providesTags: ["run_events"],
				onCacheEntryAdded: async(
					arg,
					{
						updateCachedData,
						cacheDataLoaded,
						cacheEntryRemoved,
						getCacheEntry,
					}
				) => {
					let subscriptionId;
					
					try {
						let pendingMessages: RunEvent[] = [];
						let timeoutId: NodeJS.Timeout | null = null;
						
						await cacheDataLoaded;
						
						// Flush and append the events into place
						const flushUpdates = () => {
							if (pendingMessages.length > 0) {
								updateCachedData((draft) => {
									draft.events.push(...pendingMessages);
								});
								pendingMessages = [];
							}
							if (timeoutId) {
								clearTimeout(timeoutId);
								timeoutId = null;
							}
						};
						
						subscriptionId = SocketService().subscribeToRun(
							arg?.runId ?? "",
							(error, data) => {
								if (data?.eventType === SOCKET_CHART_EVENT) {
									pendingMessages.push(data);
									
									// Set a timeout to flush after 1 second if
									// we don't reach 10 events.
									if (pendingMessages.length >= 10) {
										flushUpdates();
									} else if (!timeoutId) {
										timeoutId = setTimeout(flushUpdates, 1000);
									}
								}
							}
						);
					} catch {}
					
					await cacheEntryRemoved;
					
					if (subscriptionId) {
						SocketService().unsubscribeToRun(arg?.runId ?? "", subscriptionId);
					}
				},
			}),
		}),
	});

export const { useGetRunsQuery, useGetRunEventsQuery, useLazyGetRunEventsQuery } = run;
