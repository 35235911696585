import { Link, Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import type { ReactNode } from "react";
import type { Breadcrumb } from "types";

type BreadcrumbsProps = {
	breadcrumbs: Array<Breadcrumb>;
};

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps): ReactNode => {
	return (
		<MUIBreadcrumbs aria-label="breadcrumb">
			{breadcrumbs.map((b, idx) => {
				const { text, type, url } = b;
				
				return url ? (
					<Link
						key={idx}
						variant="h1"
						color="text.secondary"
						underline="none"
						to={url}
						aria-label={`Navigate to ${text}`}
						component={RouterLink}
					>
						{text}
					</Link>
				) : (
					<Typography
						key={idx}
						variant={type === "pill" ? "h5" : "h1"}
						sx={(theme) =>
							type === "pill"
								? {
									lineHeight: 1,
									py: 1,
									px: 2,
									background: `linear-gradient(
										90deg,
										${theme.palette.error.main} 50%,
										${theme.palette.warning.main} 100%
									)`,
									color: "common.white",
									borderRadius: 4,
								} : {}
						}
					>
						{text}
					</Typography>
				);
			})}
		</MUIBreadcrumbs>
	);
};

export default Breadcrumbs;
