import type { SigPointsInput } from "types";

const MINS_TO_SECONDS = 60;
const HOURS_TO_SECONDS = 60 * 60;

// These constants are involved in choosing significant points to show on a time-based x-axis.
// For example, if wanting to show a maximum of 10 axis labels over a time range of 10 years, you'd
// probably expect:
// - the axis go up 1 year at a time, and
// - for a significant point such as 1st January to be chosen for each year, rather than the label chosen for an
//   arbitrary point of the year.
// If only displaying 3 years of data with a maximum of 10 points,
// you may instead expect something like it going up every 4
// months.
// These constants have been chosen to try to split into sensible points.
// There isn't a "right answer" to this, but these
// constants seem to roughly have the correct behaviour.

const SIGNIFICANT_YEARS = [1, 2, 3, 4, 5, 6].reverse();

// For how many months to go up at a time on the axis,
// we choose numbers that divide into 12 to ensure it is the same number
// of points per year.
const SIGNIFICANT_MONTHS = [1, 3, 4, 6, 12].reverse();

// For significant days, it isn't possible to choose it going equally into a month, due to each month having different
// numbers of days. The 7 and 14 are chosen to correspond to a week or fortnight,
// but the other numbers are arbitrary and
// may need adjusting.
const SIGNIFICANT_DAYS = [1, 3, 4, 7, 10, 14].reverse();

// For significant hours, factors of 24 are chosen so that they divide evenly into a day (24 hours).
const SIGNIFICANT_HOURS = [1, 3, 6, 8, 12].reverse();

// For significant minutes, factors of 60 are chosen so that they divide evenly into an hour (60 minutes).
const SIGNIFICANT_MINS = [1, 2, 5, 10, 15, 30].reverse();

// For significant minutes, factors of 60 are chosen so that they divide evenly into a minute (60 seconds).
const SIGNIFICANT_SECS = [1, 2, 5, 10, 15, 30].reverse();

const dateSplitter = (startDate: Date, maxTs: number, nextDateFunc: (date: Date) => Date): Array<number> => {
	let date = startDate;
	let dateTs = Math.floor(date.getTime() / 1000);
	let results = [];
	
	while (dateTs < maxTs) {
		results.push(dateTs);
		date = nextDateFunc(date);
		dateTs = Math.floor(date.getTime() / 1000);
	}
	
	return results;
};

const divideIntoSecs = (xMin: number, xMax: number): Array<number> => {
	const date = new Date(xMin * 1000);
	
	return dateSplitter(
		date,
		xMax,
		(date) => new Date(date.setSeconds(date.getSeconds() + 1))
	);
};

const divideIntoMins = (xMin: number, xMax: number): Array<number> => {
	const dMin = new Date(xMin * 1000);
	// Round starting timestamp to start of next minute
	let date = new Date(
		dMin.getFullYear(),
		dMin.getMonth(),
		dMin.getDate(),
		dMin.getHours(),
		dMin.getMinutes() + 1
	);
	
	return dateSplitter(
		date,
		xMax,
		(date) => new Date(date.setMinutes(date.getMinutes() + 1))
	);
};

const divideIntoHours = (xMin: number, xMax: number): Array<number> => {
	const dMin = new Date(xMin * 1000);
	// Round starting timestamp to start of next hour
	let date = new Date(
		dMin.getFullYear(),
		dMin.getMonth(),
		dMin.getDate(),
		dMin.getHours() + 1
	);
	
	return dateSplitter(
		date,
		xMax,
		(date) => new Date(date.setHours(date.getHours() + 1))
	);
};

const divideIntoDays = (xMin: number, xMax: number): Array<number> => {
	const dMin = new Date(xMin * 1000);
	// Round starting timestamp to start of next day
	let date = new Date(
		dMin.getFullYear(),
		dMin.getMonth(),
		dMin.getDate() + 1
	);
	
	return dateSplitter(
		date,
		xMax,
		(date) => new Date(date.setDate(date.getDate() + 1))
	);
};

const divideIntoMonths = (xMin: number, xMax: number): Array<number> => {
	const dMin = new Date(xMin * 1000);
	// Round starting timestamp to start of next month
	let date = new Date(dMin.getFullYear(), dMin.getMonth() + 1);
	
	return dateSplitter(
		date,
		xMax,
		(date) => new Date(date.setMonth(date.getMonth() + 1))
	);
};

const divideIntoYears = (xMin: number, xMax: number): Array<number> => {
	const dMin = new Date(xMin * 1000);
	// Round starting timestamp to start of next year
	let date = new Date(dMin.getFullYear() + 1, 0);
	
	return dateSplitter(
		date,
		xMax,
		(date) => new Date(date.setFullYear(date.getFullYear() + 1, 0))
	);
};

const getYearsSplitter = (interval = 1) => {
	return (xMin: number, xMax: number) => {
		const allResults = divideIntoYears(xMin, xMax);
		
		return allResults.filter((x, i) => i % interval === 0);
	};
};

const getMonthsSplitter = (interval = 1) => {
	return (xMin: number, xMax: number) => {
		const allResults = divideIntoMonths(xMin, xMax);
		
		return allResults.filter((x, i) => i % interval === 0);
	};
};

const getHoursSplitter = (interval = 1) => {
	return (xMin: number, xMax: number) => {
		const allResults = divideIntoHours(xMin, xMax);
		
		return allResults.filter(
			(x) => x % (interval * HOURS_TO_SECONDS) === 0
		);
	};
};

const getDaysSplitter = (interval = 1) => {
	return (xMin: number, xMax: number) => {
		const allResults = divideIntoDays(xMin, xMax);
		
		return allResults.filter((x, i) => i % interval === 0);
	};
};

const getMinsSplitter = (interval = 1) => {
	return (xMin: number, xMax: number) => {
		const allResults = divideIntoMins(xMin, xMax);
		
		return allResults.filter((x) => x % (interval * MINS_TO_SECONDS) === 0);
	};
};

const getSecsSplitter = (interval = 1) => {
	return (xMin: number, xMax: number) => {
		const allResults = divideIntoSecs(xMin, xMax);
		
		return allResults.filter((x) => x % interval === 0);
	};
};

const mround = (num: number, dp = 0) => {
	const m = Math.pow(10, dp);
	
	return (Math.round(m * num) / m).toFixed(dp);
};

/* Calculate significant float values in a range, aiming for no more than maxDivisions */
export const sigPointsNum = ({
	minV,
	maxV,
	maxDivisions,
}: SigPointsInput): Array<string> => {
	const minStepSize = Math.max((maxV - minV) / maxDivisions, 0.001);
	
	const ord = Math.floor(Math.log10(minStepSize));
	
	const size = Math.pow(10, ord);
	const interval = maxV - minV;
	
	const multiplier =
		[5, 2, 1].find((x) => x * size <= interval / maxDivisions) || 1;
	
	const last = Math.pow(10, Math.ceil(Math.log10(Math.max(1, maxV))));
	
	let vals: Array<string> = [];
	let cur = minV;
	
	while (cur <= last) {
		if (minV <= cur && cur <= maxV) {
			vals.push(mround(cur, Math.max(-ord, 0)));
		}
		cur += multiplier * size; // TODO: work out degree of precision
	}
	
	return vals;
};

/* Calculate significant timestamp values in a range, aiming for no more than maxDivisions */
export const sigPointsTs = ({
	minV,
	maxV,
	maxDivisions,
}: SigPointsInput): Array<number> => {
	let res: Array<number> = [];
	
	[
		...SIGNIFICANT_YEARS.map((d) => getYearsSplitter(d)),
		...SIGNIFICANT_MONTHS.map((d) => getMonthsSplitter(d)),
		...SIGNIFICANT_DAYS.map((d) => getDaysSplitter(d)),
		...SIGNIFICANT_HOURS.map((d) => getHoursSplitter(d)),
		...SIGNIFICANT_MINS.map((d) => getMinsSplitter(d)),
		...SIGNIFICANT_SECS.map((d) => getSecsSplitter(d)),
	].find((f) => {
		const nextRes = f(minV, maxV);
		
		if (res.length === 0) {
			res = nextRes;
		}
		if (nextRes.length >= maxDivisions) {
			return true;
		}
		res = nextRes;
		
		return false;
	});
	
	return res;
};
