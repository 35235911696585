import { Route, Routes } from "react-router-dom";
import StopIcon from "@mui/icons-material/DoNotTouch";
import { Box } from "@mui/material";

import { BigCard, ErrorBoundary } from "components";
import {
	AssetsContainer,
	BacktestListContainer,
	BacktestResultsContainer,
	DashboardContainer,
	InstanceResultsContainer,
	InstancesContainer,
	OrdersContainer,
	QueryTrend,
	ScriptEditorContainer,
	ScriptsContainer,
	SettingsContainer,
	SqlEditorContainer,
	StockDataContainer,
} from "containers";
import { ErrorProvider } from "context";

import type { ReactNode } from "react";
import type { ContainerProps } from "types";

const AppContent = (routeProps: ContainerProps): ReactNode => {
	return (
		<ErrorBoundary>
			<ErrorProvider>
				<Box
					component="div"
					sx={(theme) => ({
						padding: `0 ${theme.spacing(4)}`,
						height: "calc(100% - 8.8rem)",
						overflowY: "auto",
						overflowX: "hidden",
					})}
				>
					<Routes>
						<Route
							path="/"
							element={<DashboardContainer {...routeProps} />}
						/>
						<Route
							path="/scripts"
							element={<ScriptsContainer {...routeProps} />}
						/>
						<Route
							path="/scripts/:scriptId/version/:versionId/view"
							element={<ScriptEditorContainer {...routeProps} />}
						/>
						<Route
							path="/scripts/:scriptId/edit"
							element={<ScriptEditorContainer {...routeProps} />}
						/>
						<Route
							path="/instances/:instanceId/runs/:runId"
							element={
								<InstanceResultsContainer {...routeProps} />
							}
						/>
						<Route
							path="/instances"
							element={<InstancesContainer {...routeProps} />}
						/>
						<Route
							path="/stock-data"
							element={<AssetsContainer {...routeProps} />}
						/>
						<Route
							path="/stock-data/:assetId"
							element={<StockDataContainer {...routeProps} />}
						/>
						<Route
							path="/sql"
							element={<SqlEditorContainer {...routeProps} />}
						/>
						<Route
							path="/sql/:queryId"
							element={<QueryTrend {...routeProps} />}
						/>
						<Route
							path="/settings"
							element={<SettingsContainer {...routeProps} />}
						/>
						<Route
							path="/backtests"
							element={<BacktestListContainer {...routeProps} />}
						/>
						<Route
							path="/backtests/:backtestId/runs/:runId"
							element={
								<BacktestResultsContainer {...routeProps} />
							}
						/>
						<Route
							path="/orders"
							element={<OrdersContainer {...routeProps} />}
						/>
						<Route path="*" element={<BigCard icon={StopIcon} />} />
					</Routes>
				</Box>
			</ErrorProvider>
		</ErrorBoundary>
	);
};

export default AppContent;
