import * as Yup from "yup";

export const CreateGraphFormSchema = Yup.object({
	x: Yup.string()
		.required("A field selection is required")
		.test("unique", "X and Y fields must be different", function(value) {
			return value !== this.parent.y;
		}),
	y: Yup.string()
		.required("A field selection is required")
		.test("unique", "X and Y fields must be different", function(value) {
			return value !== this.parent.x;
		}),
});
