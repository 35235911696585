import {
	Breakpoint,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import type { ReactNode } from "react";

type ModalProps = {
	children?: ReactNode;
	show: boolean;
	title: string;
	handleClose: () => void;
	size?: false | Breakpoint;
	dynamicTitle?: ReactNode;
	closeButtonText?: string;
	onSave?: () => void;
	saveButtonText?: string;
	saveButtonType?:
	| "success"
	| "primary"
	onNegative?: () => void;
	negativeButtonText?: string;
	isValid?: boolean;
	isSubmitting?: boolean;
};

const defaultProps = {
	show: false,
	title: "",
	size: "sm",
	saveButtonText: "Save",
	saveButtonType: "success",
	closeButtonText: "Cancel",
	isValid: true,
	isSubmitting: false,
};

const Modal = ({
	children,
	show,
	title,
	handleClose,
	size,
	dynamicTitle,
	closeButtonText,
	onSave,
	saveButtonText,
	saveButtonType,
	onNegative,
	negativeButtonText,
	isValid,
	isSubmitting,
}: ModalProps): ReactNode => {
	return (
		<Dialog
			open={show}
			onClose={handleClose}
			PaperProps={{
				elevation: 8,
				sx: (theme) => ({
					backgroundColor: theme.palette.background.modal,
				}),
			}}
			fullWidth
			maxWidth={size}
		>
			<DialogTitle
				variant="h2"
				color="text.secondary"
				sx={(theme) => ({
					p: 4,
					pb: 2,
					"& span": {
						color: theme.palette.text.primary,
						...(typeof dynamicTitle === "string" ? {} : { float: "right" }),
					},
				})}
				role="title"
			>
				{title}
				{dynamicTitle && <span> {dynamicTitle}</span>}
			</DialogTitle>
			<DialogContent sx={{ px: 4, py: 0 }}>{children}</DialogContent>
			<DialogActions
				sx={{ p: 4, pt: 3, justifyContent: "space-between" }}
			>
				<Button
					onClick={handleClose}
					variant="outlined"
					color="outline"
				>
					{closeButtonText}
				</Button>
				{onNegative ? (
					<Button
						onClick={onNegative}
						variant="contained"
						color="error"
					>
						{negativeButtonText}
					</Button>
				) : (
					<></>
				)}
				{onSave ? (
					<LoadingButton
						disabled={!isValid}
						loading={isSubmitting}
						onClick={onSave}
						variant="contained"
						color={saveButtonType}
					>
						{saveButtonText}
					</LoadingButton>
				) : (
					<></>
				)}
			</DialogActions>
		</Dialog>
	);
};

Modal.defaultProps = defaultProps;

export default Modal;
