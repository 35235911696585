import { type ReactNode, useContext, useEffect, useState } from "react";
import { AssetsContext, type AssetsContextValue } from "context";
import { useGetAssetsQuery } from "services";
import { AssetsIndexTable } from "components";

import type { AssetIntervals, ContainerProps, GetAssetsParams } from "types";
import type { GridFilterModel } from "@mui/x-data-grid";

type SelectFromResult = {
	assets: AssetIntervals[];
	allAssetsCount: number;
	isFetching: boolean;
};

const AssetsContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	const handleFilter = (f: GridFilterModel): void => {
		if (!f?.quickFilterValues) return;
		
		const search =
			f.quickFilterValues.length > 0
				? f.quickFilterValues?.join(" ").trim()
				: "";
		
		setSearchTerm(search);
	};
	
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 25,
	});
	const [searchTerm, setSearchTerm] = useState("");
	const { favouriteAssets, addFavorite, removeFavorite } = useContext(
		AssetsContext
	) as AssetsContextValue;
	
	const { assets, allAssetsCount, isFetching } = useGetAssetsQuery(
		{
			offset: paginationModel.pageSize * paginationModel.page,
			limit: paginationModel.pageSize,
			search: searchTerm,
		} as GetAssetsParams,
		{
			selectFromResult: ({ data, isFetching }): SelectFromResult => ({
				assets: data?.tickers ?? [],
				allAssetsCount: data?.tickerCount ?? 0,
				isFetching,
			}),
		}
	);
	
	useEffect(() => {
		setBreadcrumbs([{ text: "Stocks", url: "/stock-data" }]);
		setHeaderActions();
	}, [setBreadcrumbs, setHeaderActions]);
	
	return (
		<AssetsIndexTable
			handelAddAssetBookmark={(asset: AssetIntervals): void =>
				addFavorite(asset)
			}
			handelRemoveAssetBookmarked={(asset: AssetIntervals): void =>
				removeFavorite(asset)
			}
			assets={assets}
			bookmarkedTickers={favouriteAssets.map((asset) => asset.ticker)}
			loading={isFetching}
			rowCount={allAssetsCount}
			onPageChange={setPaginationModel}
			onFilterModelChange={handleFilter}
			paginationModel={paginationModel}
		/>
	);
};

export default AssetsContainer;
