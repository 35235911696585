import { type ReactNode } from "react";
import Annotation from "./Annotation";
import type { ChartSymbolObject, ScaleFactor } from "types";

const Symbols = ({
	symbols,
	scaleFactor,
}: {
	symbols: ChartSymbolObject[],
	scaleFactor: ScaleFactor,
}): ReactNode => (
	<>
		{symbols.map(symbol => (
			<Annotation
				key={symbol.objId}
				annotation={{
					...symbol,
					text: symbol.symbol,
					style: {
						fontSize: 20,
						font: "/facetypes/fontawsome.json",
						...symbol.style,
					},
				}}
				scaleFactor={scaleFactor}
				autoScale={false}
			/>
		))}
	</>
);

export default Symbols;
