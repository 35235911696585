import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface ErrorState {
	message: string;
}

const initialState: ErrorState = {
	message: "",
};

export const errorSlice = createSlice({
	name: "error",
	initialState,
	reducers: {
		setError: (state, action: PayloadAction<string>): void => {
			const { payload } = action;
			
			state.message = payload;
		},
	},
});

export const {
	setError,
} = errorSlice.actions;

export const selectError = (state: RootState): string | void =>
	state.error.message;

export default errorSlice.reducer;
