import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid } from "@mui/material";

import { MouseEvent, ReactNode } from "react";

type TablePaginationActionsProps = {
	count: number;
	page: number;
	rowsPerPage: number;
	showFirstButton: boolean;
	showLastButton: boolean;
	onPageChange: (
		event: MouseEvent<HTMLButtonElement>,
		newPage: number,
	) => void;
};

const TablePaginationActions = (
	props: TablePaginationActionsProps
): ReactNode => {
	const { count, page, rowsPerPage, onPageChange } = props;
	const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, page - 1);
	};
	
	const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, page + 1);
	};
	
	return (
		<Grid container columnSpacing={2}>
			<Grid item>
				<IconButton
					sx={{ borderRadius: 1 }}
					onClick={handleBackButtonClick}
					disabled={page === 0}
					aria-label="previous page"
				>
					<ArrowBackIcon />
				</IconButton>
			</Grid>
			<Grid item>
				<IconButton
					sx={{ borderRadius: 1 }}
					onClick={handleNextButtonClick}
					disabled={page >= Math.floor(count / rowsPerPage)}
					aria-label="next page"
				>
					<ArrowForwardIcon />
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default TablePaginationActions;
