import { useCallback, useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import WrenchIcon from "@mui/icons-material/PlumbingRounded";
import {
	CHART_AXIS_X_STEPWIDTH,
	CHART_AXIS_Y_STEPHEIGHT,
	DEFAULT_X_DIVISIONS,
	DEFAULT_Y_DIVISIONS,
} from "consts";
import { BigCard, Chart } from "components";
import { useAppDispatch, useAppSelector } from "hooks";
import {
	disableRotation,
	resetScaleFactor,
	selectScaleFactor,
	setScaleFactor,
} from "store";
import { useGetMarketHoursQuery } from "services";
import { AggLevelType, CreateChartData } from "types";
import {
	EmptyChartPos,
	XAxis,
	YAxis,
	calculateScaleFactor,
	calculateTimespan,
	generateTimescale,
	generateTimescaleOffsetDict,
	setXAxisOffsets,
	updateTimelineOffsets,
} from "../helpers";
import type { ReactNode } from "react";
import type {
	ChartAxis,
	ChartData,
	ChartPos,
	Fetcher,
	MousePos,
	RunEvent,
} from "types";

type ChartContainerProps = {
	axis: {
		bottom: ChartAxis;
		right: ChartAxis;
	};
	aggLevel?: AggLevelType,
	fetcher?: Fetcher,
	liveEvents?: Array<RunEvent>; // TODO - when the harvester is working, implement live data
	maxts?: number,
	mints?: number,
	timespanOverride?: number,
};

const defaultProps = {
	axis: {
		bottom: { enabled: true, timestamps: true },
		right: { enabled: true, timestamps: false },
	},
};

const ChartContainer = ({
	axis,
	liveEvents,
	aggLevel,
	mints,
	maxts,
	fetcher,
	timespanOverride,
}: ChartContainerProps): ReactNode => {
	const dispatch = useAppDispatch();
	const scaleFactor = useAppSelector(selectScaleFactor);
	
	const [mousePos, setMousePos] = useState<MousePos>(); // This helps us track mouse pos in the axis
	const [chartData, setChartData] = useState<ChartData[]>([]);
	
	// Defines the initial setup of the main ChartPos ref.
	const chartPos = useRef<ChartPos>({ ...EmptyChartPos, fetcher });
	
	// This should check the market hours to generate an offset dict that
	// can then be used to offset chart objects using the performTimelineAnalysis.
	// If we get nothing here (i.e. the data is not available), then we
	// won't bother with setting the offsets.
	const { data: marketHours, isSuccess } = useGetMarketHoursQuery(
		{
			tsFrom: mints,
			tsTo: maxts,
		},
		{
			skip: !mints || !maxts,
		}
	);
	
	// This allows us to append to a list of events over time, and
	// just leave them there, in the hope that they will render fine. We
	// do all the conversion here also, so that regardless of where the
	// chart data may come from, as long as they are chart data types,
	// they will be converted correctly, rather than putting in loads of
	// transforms in RTK for each data source.
	//
	// The order here is important, as we always "fetch" back in time
	// for historical data, and so therefore we need to detect the "gaps"
	// in this data so we can provide the user with a smooth chart that
	// doesn't have large gaps due to weekends, holidays etc. We have
	// to do it on what we "have", as each "window" can represent starts
	// or ends of gaps etc. Hopefully this won't slow things down too much.
	const prependChartData = useCallback((data: RunEvent[]) => {
		// Check if we have anything, and save a spin through gap analysis.
		if (data.length === 0) return;
		
		setChartData(
			(prev) => updateTimelineOffsets([...data.map(d => CreateChartData(d)), ...prev], chartPos)
		);
	}, []);
	
	// This is used for adding data to the end of the time series, so it's
	// always "live" - and as such, there is not going to be any need to
	// slow this down with analysis etc. Note: this may not work as we
	// don't have the harvester in place yet.
	const appendChartData = useCallback((data: RunEvent[]) => {
		setChartData((prev) => [...prev, ...data.map(d => CreateChartData(d))]);
	}, []);
	
	// Calculate divisions for axis and gridlines - default to 4
	const maxXSteps = chartPos
		? Math.floor(chartPos.current.width / CHART_AXIS_X_STEPWIDTH)
		: DEFAULT_X_DIVISIONS;
	const maxYSteps = chartPos
		? Math.floor(chartPos.current.height / CHART_AXIS_Y_STEPHEIGHT) / 2
		: DEFAULT_Y_DIVISIONS;
	
	// When the agg level, min/max ts change, we need to set everything up,
	// this is only focused on the x axis, as that is the basis of what
	// we use to get data, and that is important.
	useEffect(() => {
		if (!mints || !maxts || !isSuccess) return;
		
		// This defines what we are showing in the chart viewport initially.
		let timespan = calculateTimespan(mints, maxts);
		
		// Allow for an implementing component to override the default
		// timespan set from the aggregate.
		if (timespanOverride) {
			timespan = timespanOverride;
		}
		
		const min = Math.max(mints, maxts - timespan);
		
		// Viewport timestamps for use with a specific width, we keep
		// what the chart "can" show (mints, maxts), and what it will
		// initially show with no zoom (minX, maxX). We are unable to
		// set anything to do with the y values, as these are only
		// returned when the min/max timestamps are used.
		chartPos.current.minX = min;
		chartPos.current.minXOffset = 0;
		chartPos.current.maxX = maxts;
		chartPos.current.maxXOffset = 0;
		chartPos.current.mints = mints;
		chartPos.current.maxts = maxts;
		chartPos.current.aggLevel = aggLevel;
		
		// This should set how many "pages" of data we need to load,
		// first reset what we need to load if the agg level changes.
		chartPos.current.windows = {};
		chartPos.current.timespan = timespan;
		chartPos.current.offsetDict = undefined;
		chartPos.current.timescale = undefined;
		chartPos.current.minY = 0;
		chartPos.current.maxY = 0;
		
		// Reset the scale factor if what we're looking at changes.
		dispatch(resetScaleFactor());
		
		// Then get the number of pages, and round down the min (which should
		// be the LHS of the chart) to the nearest hour. This will then allow the
		// chart to compare with this value to check if a new page needs to be
		// loaded.
		let pages = Math.ceil((maxts - mints) / timespan);
		let start = min;
		let acc = min - (min % timespan);
		
		while (pages--) {
			chartPos.current.windows[acc] = start;
			start -= timespan;
			acc -= timespan;
		}
		
		// Ensure that the events are empty to start with.
		setChartData([]);
		
		// Ensure that rotation is always reset to off.
		dispatch(disableRotation());
	}, [aggLevel, mints, maxts, isSuccess, timespanOverride, dispatch]);
	
	// This will try and load pages with whatever fetcher has been given to
	// us, and will rely on any endpoint responding to the setting of min
	// and max timestamps.
	useEffect(() => {
		const { windows, timespan, minX, minXOffset } = chartPos.current;
		const windowCount = Object.values(windows).length;
		
		if (windowCount === 0 || !minX || !fetcher) return;
		
		// Round down to the nearest hour the minX (or minXOffset), i.e. this
		// represents the LHS of the chart which we are using for our "page" indicator.
		const rMin: number = minXOffset
			? minXOffset - (minXOffset % timespan)
			: minX - (minX % timespan);
		
		// If we have loaded this "page", then we do nothing as the data will
		// already be loaded and rendered. The assumption here is that we
		// load two additional pages to the left so that the users experience
		// of using the chart is as smooth as possible. It can also happen
		// Very quickly, meaning that sometimes we get a match, then the
		// fetcher has already gone off and got the data. If we only have
		// one "page", then we have to force that.
		if (windows[rMin - (windowCount === 1 ? 0 : timespan)] === undefined) return;
		
		const fetch: Array<Array<number>> = [];
		let tofetch: number = 0;
		
		// This decides how many windows of data to fetch - we can increase this
		// value if unsatisfactory.
		while (tofetch < 2) {
			const acc: number = (tofetch++) * timespan;
			const min: number = windows[rMin - acc];
			
			// Just to be sure!
			if (!min) continue;
			
			// This will create a "queue" of fetches that we need to make, and remove
			// the record that we've visited. The addition of 1 here is so that we
			// shouldn't load the same event twice from the API.
			fetch.push([min + 1, min + timespan]);
			delete chartPos.current.windows[rMin - acc];
		}
		
		// We now need to get the data - and as we are going to do this quickly,
		// we need to cope with the vagaries of RTK Query - and handle the
		// whole thing all at once in a list of promises.
		const getData = async() => {
			if (fetch.length === 0) return;
			
			try {
				const results = await Promise.all(
					fetch.map((f: number[]) => fetcher(...f as [number, number]))
				).catch(() => null);
				
				// Reset fetch to avoid endless loops
				fetch.length = 0;
				
				if (!results)	return;
				
				// Handle the unwrapped results
				results.forEach((result) => {
					const { data, minY, maxY, minLow, maxVol } = result;
					
					// We setup the initial min/max values that we get back. The
					// min/max vals will change as we load new data, min/max y
					// will change as the user moves the window.
					if (chartPos.current.minY === 0 && chartPos.current.maxY === 0) {
						chartPos.current.minval = Number(minY);
						chartPos.current.minY = Number(minY);
						chartPos.current.maxval = Number(maxY);
						chartPos.current.maxY = Number(maxY);
						chartPos.current.low = Number(minLow);
						chartPos.current.maxvol = Number(maxVol);
						
						// Set the normalisation scaleFactor - this may get
						// updated in the camera on the first load also - which
						// is a pain, but the chart needs to cope with scale changes.
						const scaleFactor = calculateScaleFactor(chartPos);
						
						if (scaleFactor) dispatch(setScaleFactor(scaleFactor));
					} else {
						chartPos.current.minval = Math.min(chartPos.current.minval, Number(minY));
						chartPos.current.maxval = Math.max(chartPos.current.maxval, Number(maxY));
					}
					
					prependChartData(data);
				});
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		
		getData();
	}, [
		chartPos.current.minX, // We much check for fetching with both minX and offset
		chartPos.current.minXOffset,
		dispatch,
		fetcher,
		prependChartData,
	]);
	
	useEffect(() => {
		if (!liveEvents || liveEvents.length === 0) return;
		appendChartData(liveEvents);
	}, [liveEvents, appendChartData]);
	
	// We have to do this here because if we do it in a selectFromResult
	// in the hook above, it will get run at the refresh rate of the
	// the chart. What we are doing here is making a dict that each
	// object can check for an offset "day" that it's on. We are also
	// making a timescale that things like the camera can use to look
	// up an offset for wherever the user has their mouse over.
	useEffect(() => {
		if (!marketHours || !scaleFactor) return;
		
		chartPos.current.offsetDict = generateTimescaleOffsetDict(marketHours, chartPos);
		chartPos.current.timescale = generateTimescale(marketHours, scaleFactor, chartPos);
		
		// On the first load, we need to make sure that we set the offsets
		// because all the parts need won't be there until now.
		setChartData((prev) => updateTimelineOffsets(prev, chartPos));
		
		// We need to check on load if there are any offsets on the x axis
		// due to gaps - this will then be carried out in the camera as
		// the user interacts with the chart.
		setXAxisOffsets(chartPos);
	}, [marketHours, scaleFactor]);
	
	return (
		<Grid
			container
			item
			direction="column"
			sx={{ userSelect: "none", height: "100%" }}
		>
			<Grid container item direction="row" xs>
				<Grid
					item
					xs={11}
					sx={(theme) => ({
						position: "relative",
						border: `1px solid ${theme.palette.chartBorder.main}`,
					})}
				>
					{chartData.length === 0 && (
						<BigCard
							icon={WrenchIcon}
							message="Visualisation of results goes here"
							absolute
						/>
					)}
					<Chart
						cmds={chartData}
						mousePos={mousePos}
						setMousePos={setMousePos}
						chartPos={chartPos}
						maxXSteps={maxXSteps}
						maxYSteps={maxYSteps}
					/>
				</Grid>
				<Grid container item xs={1}>
					{axis.right.enabled && chartData.length > 0 && (
						<YAxis
							settings={axis.right}
							maxSteps={maxYSteps}
							mousePos={mousePos}
							chartPos={chartPos}
							label={axis.right.label || undefined}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container item xs={1}>
				{axis.bottom.enabled && chartData.length > 0 && (
					<XAxis
						maxSteps={maxXSteps}
						mousePos={mousePos}
						chartPos={chartPos}
						label={axis.bottom.label || undefined}
					/>
				)}
				<Grid item xs={1} />
			</Grid>
		</Grid>
	);
};

ChartContainer.defaultProps = defaultProps;

export default ChartContainer;
