import { type ReactNode, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Grid, Typography } from "@mui/material";

type SelectPageActionsProps = {
	endPage: number;
	page: number;
	val: number;
	onPageChange: (event: SelectChangeEvent<number>, newPage: number) => void;
};

const SelectPageActions = ({
	endPage,
	page,
	val,
	onPageChange,
}: SelectPageActionsProps): ReactNode => {
	const [options, setOptions] = useState<number[]>([]);
	
	useEffect(() => {
		setOptions(Array.from({ length: endPage }, (_, idx) => idx));
	}, [page, endPage, setOptions]);
	
	const handleChange = (event: SelectChangeEvent<number>) => {
		onPageChange(event, Number(event.target.value));
	};
	
	const iconComponent = () => {
		return <ExpandMoreIcon />;
	};
	
	return (
		<Grid
			container
			columnSpacing={1.25}
			alignItems="center"
			justifyContent="flex-end"
			sx={(theme) => ({
				color:
					theme.palette.mode === "light"
						? theme.palette.text.secondary
						: theme.palette.text.primary,
			})}
		>
			{options.length && (
				<>
					<Grid item>
						<Typography component="span" variant="h6">
							Page
						</Typography>
					</Grid>
					<Grid item>
						<FormControl>
							<Select
								IconComponent={iconComponent}
								value={val}
								onChange={handleChange}
								sx={(theme) => ({
									color:
										theme.palette.mode === "light"
											? theme.palette.text.secondary
											: theme.palette.text.primary,
									"& div.MuiSelect-select[role=button]": {
										paddingRight: theme.spacing(1.25),
									},
									"& .MuiOutlinedInput-notchedOutline": {
										border: 0,
									},
								})}
							>
								{options.map((opt, i) => (
									<MenuItem key={i} value={opt}>
										{opt + 1}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item>
						<Typography component="span" variant="h6">
							of {endPage}
						</Typography>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default SelectPageActions;
