import { Line as DreiLine } from "@react-three/drei";
import { CHART_LINE_STYLE_TYPES, CHART_OBJECT_DEFAULTS } from "consts";
import { calcX, calcY } from "../helpers";
import type { ReactNode } from "react";
import type { ChartLineObject, ChartPoint, ScaledObject } from "types";

interface LineProps extends Omit<ChartLineObject, "objtype" | "points">, ScaledObject {
	points?: Array<ChartPoint>,
}

const Line = ({
	objId,
	points,
	style,
	scaleFactor,
}: LineProps): ReactNode => {
	if (!points || !scaleFactor) return;
	
	const {
		lineType,
		lineWidth,
		color,
	} = {
		...CHART_OBJECT_DEFAULTS.style,
		...style,
	};
	
	const threePoints: Array<[number, number, number]> = points.map((point) => {
		return [calcX(point.x, scaleFactor), calcY(point.y, scaleFactor), point.z || 0];
	});
	
	return (
		<>
			<DreiLine
				key={objId}
				points={threePoints}
				color={color}
				lineWidth={lineWidth}
				dashed={lineType !== CHART_LINE_STYLE_TYPES.SOLID}
				dashSize={ // We simulate dotted lines by using small dashes
					lineType === CHART_LINE_STYLE_TYPES.DOTTED ? 0.1 : 1
				}
			/>
		</>
	);
};

export default Line;
