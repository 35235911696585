import InfoIcon from "@mui/icons-material/Info";
import { Alert as MuiAlert } from "@mui/material";
import { ReactNode } from "react";

type AlertProps = {
	type: "error" | "info";
	children: ReactNode | ReactNode[];
};

const defaultProps = {
	type: "error",
};

const Alert = ({ type, children }: AlertProps) => (
	<MuiAlert
		iconMapping={{
			info: <InfoIcon fontSize="inherit" />,
			error: <InfoIcon fontSize="inherit" />,
		}}
		variant="filled"
		severity={type}
		sx={(theme) => ({ my: theme.spacing(4) })}
	>
		{children}
	</MuiAlert>
);

Alert.defaultProps = defaultProps;

export default Alert;
