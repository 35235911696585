import { type ReactNode, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
	Description as DescriptionIcon,
	ArrowDownwardRounded as DownArrow,
	ShowChart as ShowChartIcon,
	ArrowUpwardRounded as UpArrow,
} from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";

import { DataTable, Tile, TradingOverview } from "components";
import { useGetInstancesQuery } from "services";
import { formatDateTime } from "utils";

import type { ContainerProps, Instance } from "types";

const DashboardContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	const navigate = useNavigate();
	
	const { latestRuns } = useGetInstancesQuery(undefined, {
		selectFromResult: ({ data }) => ({
			latestRuns: data?.instances.filter((i) => i.latestRun) ?? [],
		}),
	});
	
	const handleViewRunClick = (instance: Instance): void => {
		const { instanceId, latestRun } = instance;
		const linkState = { instance, runId: latestRun?.runId };
		
		navigate(`/instances/${instanceId}/runs/${latestRun?.runId}`, {
			replace: false,
			state: linkState,
		});
	};
	
	useEffect(() => {
		setBreadcrumbs([{ text: "Dashboard" }]);
		setHeaderActions();
	}, [setBreadcrumbs, setHeaderActions]);
	
	const tradingOverviewItems = [
		{
			text: "Net Profit/Loss for the week",
			value: 1234,
		},
		{
			text: "Spent on transaction fees",
			value: 232,
		},
		{
			text: "Total trading volume (buy/sell) this week",
			value: 12234,
		},
	];
	
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs>
					<Tile
						heroIcon={DownArrow}
						heroText="24"
						text="Total trades this week"
						color="warning"
					/>
				</Grid>
				<Grid item xs>
					<Tile
						heroIcon={UpArrow}
						heroText="6.2%"
						text="Portfolio growth this week"
						color="success"
					/>
				</Grid>
				<Grid item xs>
					<Tile
						heroIcon={ShowChartIcon}
						heroText="24"
						text="Stocks in portfolio"
						color="primary"
					/>
				</Grid>
				<Grid item xs>
					<Tile
						heroText="0.62 billion"
						text="Total shares traded today"
					/>
				</Grid>
			</Grid>
			<Box component="div" my={6.25}>
				<TradingOverview items={tradingOverviewItems} currency="GBP" />
			</Box>
			<Box component="div" sx={{ height: "40rem", my: 4 }}>
				<Grid
					container
					justifyContent="space-between"
					alignItems="center"
					pb={2}
				>
					<Grid item height="min-content">
						<Typography variant="h2">Latest Runs</Typography>
					</Grid>
					<Grid item>
						<Button variant="contained" href="/instances">
							View all
						</Button>
					</Grid>
				</Grid>
				
				<DataTable
					getRowId={(instance: Instance) => instance.instanceId}
					search={false}
					columns={[
						{
							field: "name",
							headerName: "Name",
							flex: 4,
						},
						{
							field: "latestRun",
							headerName: "Last run",
							type: "dateTime",
							valueFormatter: ({ value }: { value: number }) =>
								formatDateTime(value),
							valueGetter: ({ row }: { row: Instance }) =>
								row.latestRun?.createdAt &&
								new Date(row.latestRun?.createdAt * 1000),
							flex: 2,
						},
						{
							field: "Status",
							flex: 2,
							sortable: false,
							renderCell: ({ row }: { row: Instance }) => (
								<Button
									aria-label={`Show latest run: ${row.latestRun?.runId}`}
									onClick={() => handleViewRunClick(row)}
									variant="contained"
								>
									View run
								</Button>
							),
						},
						{
							field: "actions",
							type: "actions",
							getActions: ({ row }: { row: Instance }) => [
								<GridActionsCellItem
									icon={<DescriptionIcon />}
									onClick={() => alert("NOT IMPLEMENTED")}
									label="Info"
									showInMenu
								/>,
							],
						},
					]}
					rows={latestRuns}
				/>
			</Box>
		</>
	);
};

export default DashboardContainer;
