export const escapeWhitespace = (
	text: string,
	separator: string = "_"
): string => {
	const sep = separator;
	
	return text.replace(/\s/gim, sep);
};

export const titleCase = (text: string): string => {
	const capital = text.substring(0, 1).toUpperCase();
	const wordRemainder = text.substring(1, text.length).toLowerCase();
	
	return `${capital}${wordRemainder}`;
};

export const truncateText = (text: string, maxLength: number = 20): string => {
	return text.length > maxLength
		? `${text.substring(0, maxLength)}...`
		: text;
};
