import {
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import type { ReactNode } from "react";

export type ToolbarAction = {
	onClick: () => void;
	text: string;
};

type DataTableToolbarProps = {
	actions: Array<ToolbarAction>;
	filter: boolean;
	search: boolean;
};

const defaultProps = {
	actions: [] as Array<ToolbarAction>,
};

const DataTableToolbar = ({
	actions,
	search,
	filter,
}: DataTableToolbarProps): ReactNode => (
	<GridToolbarContainer sx={{ justifyContent: "space-between" }}>
		<Grid container spacing={2} justifyContent="space-between">
			<Grid container item spacing={2} xs={10}>
				{filter && (
					<Grid item>
						<GridToolbarFilterButton
							componentsProps={{
								button: {
									size: "medium",
									startIcon: <AddIcon />,
									color: "outline",
									variant: "text",
								},
							}}
						/>
					</Grid>
				)}
				{actions.map(({ onClick, text }) => (
					<Grid item key={text}>
						<Button
							onClick={onClick}
							color="outline"
							variant="text"
						>
							{text}
						</Button>
					</Grid>
				))}
			</Grid>
			<Grid item xs={2} justifyContent="flex-end">
				{/* We have to add new padding here as the MUI component does naughty things */}
				{search && (
					<GridToolbarQuickFilter
						sx={{
							pb: 1,
							input: { m: 0 },
							"& > .MuiSvgIcon-root": {
								display: "none",
							},
						}}
					/>
				)}
			</Grid>
		</Grid>
	</GridToolbarContainer>
);

DataTableToolbar.defaultProps = defaultProps;

export default DataTableToolbar;
