
import { type ReactNode, useMemo } from "react";
import { DataTable, Loading } from "components";
import { Metrics } from "types";
import type { ExtendedMetrics } from "containers";

type Row = {
	id: string;
	[key: string]: string | number;
};

type LatestMetricsTableProps = {
	metrics: ExtendedMetrics,
	isLoading: boolean,
};

const LatestMetricsTable = ({
	metrics,
	isLoading,
}: LatestMetricsTableProps): ReactNode => {
	const metricsByKey : {[key: string]: Metrics } = useMemo(() => ({}), []);
	
	const latestMetrics = Object.entries(metrics || {}).map(([key, v]) => {
		const sortedMetrics = [...v];
		
		sortedMetrics.sort((a, b) => a.timestamp - b.timestamp);
		metricsByKey[key] = sortedMetrics[sortedMetrics.length - 1];
		
		return metricsByKey[key];
	});
	
	const columns = Object.keys(metrics || {});
	
	const rows: Row[] = useMemo(() => {
		const keys: string[] = [];
		const excluded = ["timestamp"];
		
		latestMetrics.forEach((m) => {
			Object.keys(m || {}).forEach((k) => {
				if (!keys.includes(k) && !excluded.includes(k)) {
					keys.push(k);
				}
			});
		});
		
		const rows: Row[] = [];
		
		keys.forEach((k) => {
			const row: Row = { id: k };
			
			columns.forEach((c) => {
				row[c] = metricsByKey[c][k as keyof Metrics];
			});
			
			rows.push(row);
		});
		
		return rows;
	}, [columns, latestMetrics, metricsByKey]);
	
	return (
		<>
			{isLoading && <Loading message="Getting metrics..." />}
			{!isLoading && (
				<DataTable
					getRowId={(row: Row) => row.id}
					columns={[
						{
							field: "Metric",
							flex: 8,
							valueGetter: ({ row }) => row.id,
						},
						...columns.map((col) => ({
							field: col,
							flex: 8,
						})),
					]}
					rows={rows}
					filter={false}
					search={false}
				/>
			)}
		</>
	);
};

export default LatestMetricsTable;
