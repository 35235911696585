
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
	Delete as DeleteIcon,
	Description as DescriptionIcon,
} from "@mui/icons-material";

import { useDeleteQueryMutation } from "services";
import { DataTable, Modal } from "components";
import { formatDateTime } from "utils";

import type { ReactNode } from "react";
import type { Query } from "types";

const LoadModal = ({
	show,
	handleClose,
	queries,
	setCurrentQuery,
}: {
	show: boolean;
	handleClose: () => void;
	queries: Query[];
	setCurrentQuery: (query: Query) => void;
}): ReactNode => {
	const [handleDeleteQuery] = useDeleteQueryMutation();
	
	const getMenuItemsForQuery = (row: Query): JSX.Element[] => {
		return [
			<GridActionsCellItem
				icon={<DescriptionIcon />}
				onClick={() => {
					setCurrentQuery(queries.find(query => query.id === row.id)! || null);
					handleClose();
				}}
				label="Load"
				showInMenu
			/>,
			<GridActionsCellItem
				icon={<DeleteIcon />}
				onClick={() => handleDeleteQuery(row.id)}
				label="Delete"
				showInMenu
			/>,
		];
	};
	
	return (
		<Modal
			title="Load previous queries"
			handleClose={handleClose}
			show={show}
		>
			<DataTable
				getRowId={(row: Query) => row.id}
				columns={[
					{
						field: "name",
						headerName: "",
						flex: 1,
						sortable: false,
						renderCell: ({ row }: { row: Query}) => row.name,
					},
					{
						field: "actions",
						type: "actions",
						getActions: ({ row }: { row: Query }) => getMenuItemsForQuery(row),
					},
				]}
				rows={queries ? queries.map((q: Query) => ({
					...q,
					name: q.name !== "unnamed.sql" ? q.name : formatDateTime(q.startedAt),
				})) : []}
				search={false}
				filter={false}
				columnHeaderHeight={0}
			/>
		</Modal>
	);
};

export default LoadModal;