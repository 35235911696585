import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";

import { Loading } from "components";

import type { CSSProperties, ReactNode } from "react";

import "./LazyLoadingList.css";

const ROW_HEIGHT = 36;

type LazyLoadingListProps<T> = {
	allItemsCount?: number;
	handleLoadNextItems: () => void;
	isLoadingNextItems: boolean;
	items: Array<T>;
	renderItemComponent: (item: T) => ReactNode;
	renderLoadingComponent: () => ReactNode;
};

const defaultProps = {
	renderLoadingComponent: (): ReactNode => {
		return <Loading spinnerSize={ROW_HEIGHT - 12} />;
	},
};

const LazyLoadingList = <T, >({
	allItemsCount,
	handleLoadNextItems,
	isLoadingNextItems,
	items,
	renderItemComponent,
	renderLoadingComponent,
}: LazyLoadingListProps<T>): ReactNode => {
	// rows considered loaded except for the final loading indicator row
	const isItemLoaded = (index: number) =>
		allItemsLoaded || index < items.length;
	
	// render an item or a loading indicator
	// inline style is created by the underlying FixedList
	const renderItem = ({
		index,
		style,
	}: {
		index: number;
		style: CSSProperties;
	}) => (
		<div style={style}>
			{isItemLoaded(index)
				? renderItemComponent(items[index])
				: renderLoadingComponent()}
		</div>
	);
	
	// if there are more items to be loaded, add an extra row for loading indicator
	const allItemsLoaded = items.length === allItemsCount;
	const listItemCount = allItemsLoaded ? items.length : items.length + 1;
	
	// to ensure only 1 page of items loaded at a time, we set loadMoreItems to
	// an empty callback if it is already loading
	const loadMoreItems = isLoadingNextItems ? () => {} : handleLoadNextItems;
	
	return (
		<AutoSizer>
			{({ height, width }) => (
				<InfiniteLoader
					isItemLoaded={isItemLoaded}
					itemCount={listItemCount}
					loadMoreItems={loadMoreItems}
				>
					{({ onItemsRendered, ref }) => (
						<FixedSizeList
							height={height}
							itemCount={listItemCount}
							itemSize={ROW_HEIGHT}
							onItemsRendered={onItemsRendered}
							ref={ref}
							width={width}
						>
							{renderItem}
						</FixedSizeList>
					)}
				</InfiniteLoader>
			)}
		</AutoSizer>
	);
};

LazyLoadingList.defaultProps = defaultProps;

export default LazyLoadingList;
