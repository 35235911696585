import {
	BookmarkBorder as BookmarkBorderIcon,
	Bookmark as BookmarkIcon,
	StopCircle as StopCircleIcon,
	Sync as SyncIcon,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import { historicalRequestsStateToStatusMap as statusMap } from "consts";
import { DataTable, Pill } from "components";
import { formatDateTime } from "utils";

import type {
	AssetIntervals,
	HistoricalReqState,
	PillColor,
} from "types";
import type {
	GridCallbackDetails,
	GridCellParams,
	GridFilterModel,
	MuiEvent,
} from "@mui/x-data-grid";
import type { Dispatch, ReactNode, SetStateAction } from "react";

type AssetsIndexTableProps = {
	assets: Array<AssetIntervals>;
	bookmarkedTickers: Array<string>;
	handelAddAssetBookmark: (asset: AssetIntervals) => void;
	handelRemoveAssetBookmarked: (asset: AssetIntervals) => void;
	loading: boolean;
	rowCount: number;
	onPageChange: Dispatch<
	SetStateAction<{
		page: number;
		pageSize: number;
	}>
	>;
	onFilterModelChange: (filterObject: GridFilterModel) => void;
	paginationModel: { page: number; pageSize: number };
};

const AssetsIndexTable = ({
	assets,
	bookmarkedTickers,
	handelAddAssetBookmark,
	handelRemoveAssetBookmarked,
	loading,
	rowCount,
	paginationModel,
	onPageChange,
	onFilterModelChange,
}: AssetsIndexTableProps): ReactNode => {
	const navigate = useNavigate();
	
	return (
		<DataTable
			getRowId={(row: AssetIntervals) => row.ticker}
			columns={[
				{
					field: "Bookmarked",
					headerName: "",
					flex: 1,
					sortable: false,
					renderCell: ({ row }: { row: AssetIntervals }) => {
						if (bookmarkedTickers.includes(row.ticker)) {
							return (
								<IconButton
									onClick={() =>
										handelRemoveAssetBookmarked(row)
									}
								>
									<BookmarkIcon />
								</IconButton>
							);
						} else {
							return (
								<IconButton
									onClick={() => handelAddAssetBookmark(row)}
								>
									<BookmarkBorderIcon />
								</IconButton>
							);
						}
					},
				},
				{
					field: "ticker",
					headerName: "Symbol",
					flex: 1,
					sortable: false,
				},
				{
					field: "name",
					headerName: "Ticker Name",
					flex: 6,
					sortable: false,
				},
				{
					field: "From (earliest)",
					type: "dateTime",
					valueFormatter: ({ value }: { value: Date | null }) =>
						value ? formatDateTime(value, "after") : "-",
					valueGetter: ({ row }: { row: AssetIntervals }) => row.dtFrom,
					flex: 3,
					sortable: false,
				},
				{
					field: "Until (latest)",
					type: "dateTime",
					valueFormatter: ({ value }: { value: Date | null }) =>
						value ? formatDateTime(value, "after") : "-",
					valueGetter: ({ row }: { row: AssetIntervals }) => row.dtTo,
					flex: 3,
					sortable: false,
				},
				{
					field: "Status",
					flex: 2,
					sortable: false,
					renderCell: ({ row }: { row: AssetIntervals }) => {
						if (!row.latestState) {
							return null;
						}
						
						let colour = "primary" as PillColor;
						let icon = undefined;
						let text = row.latestState;
						
						switch (row.latestState) {
							case "COMPLETED":
								colour = "success";
								break;
							case "STOPPED":
								colour = "error";
								icon = StopCircleIcon;
								break;
							case "HARVESTING":
								icon = SyncIcon;
								break;
							default:
								text = statusMap[row.latestState as HistoricalReqState].text;
								colour = statusMap[row.latestState as HistoricalReqState].color;
								icon = statusMap[row.latestState as HistoricalReqState].icon;
								break;
						}
						
						return <Pill
							table
							text={text}
							color={colour}
							icon={icon}
						/>;
					},
				},
				{
					field: "Price",
					valueGetter: ({ row }: { row: AssetIntervals }) => row.latestPrice || "N/A",
					renderCell: ({ row }: { row: AssetIntervals }) => {
						if (!row.latestPrice) {
							return "-";
						}
						
						return (
							<Typography
								variant="h5"
								component="span"
							>
								{row.latestPrice}
								<Typography
									variant="h6"
									component="sub"
									color="text.secondary"
									sx={{
										verticalAlign: "bottom",
										lineHeight: "unset",
									}}
								>
									{row.currencyName.toUpperCase()}
								</Typography>
							</Typography>
						);
					},
					flex: 2,
					sortable: false,
				},
				{
					field: "Change",
					renderCell: ({ row }: { row: AssetIntervals }) => {
						if (!row.priceChange) {
							return "-";
						}
						
						return (
							<Typography
								variant="h5"
								component="span"
								color={row.priceChange >= 0 ? "text.success" : "text.error"}
							>
								{row.priceChange}
							</Typography>
						);
					},
					flex: 1,
					sortable: false,
				},
				{
					field: "Change %",
					renderCell: ({ row }: { row: AssetIntervals }) => {
						if (!row.pricePercentChange) {
							return "-";
						}
						
						return (
							<Typography
								variant="h5"
								component="span"
								color={row.pricePercentChange >= 0 ? "text.success" : "text.error"}
							>
								{row.pricePercentChange.toFixed(2)}%
							</Typography>
						);
					},
					flex: 1,
					sortable: false,
				},
			]}
			rows={assets}
			onFilterModelChange={onFilterModelChange}
			hideFooter={false}
			loading={loading}
			paginationMode="server"
			rowCount={rowCount}
			pageSizeOptions={[25, 50, 100]}
			paginationModel={paginationModel}
			onPaginationModelChange={onPageChange}
			onCellClick={(
				cellParams: GridCellParams,
				event: MuiEvent,
				details: GridCallbackDetails
			): void => {
				// As long as click wasn't on a bookmark button, open relevant stock show page
				if (cellParams.field !== "Bookmarked") {
					navigate(`/stock-data/${cellParams.id}`);
				}
			}}
		/>
	);
};

export default AssetsIndexTable;
