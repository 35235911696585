import {
	Add as AddIcon,
	RemoveCircleOutlineRounded as MinusIcon,
	AddCircleOutlineRounded as PlusIcon,
} from "@mui/icons-material";
import { Button, Grid, IconButton, Stack } from "@mui/material";
import {
	ArrayHelpers,
	FieldArray,
	FieldArrayRenderProps,
	type FormikProps,
	FormikProvider,
	getIn,
} from "formik";

import { TextField } from "components";

import { CreateBacktestFormValues } from "./CreateBacktestForm";
import type { ReactNode } from "react";
import type { BacktestParametersValue } from "types";

const FormikTextField = <T, >({
	label,
	path,
	formik,
}: {
	label: string;
	path: string;
	formik: FormikProps<T>;
}) => {
	return (
		<TextField
			id={label}
			label={label}
			name={path}
			value={getIn(formik.values, path)}
			onChange={formik.handleChange}
			onBlur={formik.handleBlur}
			error={getIn(formik.touched, path) && getIn(formik.errors, path)}
			helperText={
				getIn(formik.touched, path) && getIn(formik.errors, path)
			}
		/>
	);
};

const ListParameter = <T, >({
	formik,
	item,
	index,
	pushValue,
	removeValue,
	removeParameter,
}: {
	formik: FormikProps<T>;
	item: BacktestParametersValue;
	index: number;
	pushValue: ArrayHelpers["push"];
	removeValue: ArrayHelpers["remove"];
	removeParameter: () => void;
}) => {
	return (
		<>
			{item.values.map(
				(
					value,
					valueIndex
				) => (
					<Grid key={valueIndex} item container wrap="nowrap">
						<Grid item xs={2}>
							{FormikTextField({
								formik,
								label: "Value",
								path: `params[${index}].values[${valueIndex}]`,
							})}
						</Grid>
						<Grid item container>
							<Grid item alignSelf="flex-end">
								<IconButton
									aria-label="Remove this element from run parameters"
									color="warning"
									onClick={() => {
										if (item.values.length === 1) {
											removeParameter();
										} else {
											removeValue(valueIndex);
										}
									}}
									size="large"
								>
									<MinusIcon fontSize="inherit" />
								</IconButton>
							</Grid>
							{item.values.length - 1 === valueIndex && (
								<Grid item alignSelf="flex-end">
									<IconButton
										aria-label="Add a new key-value pair to run parameters"
										color="success"
										onClick={() => pushValue("")}
										size="large"
									>
										<PlusIcon fontSize="inherit" />
									</IconButton>
								</Grid>
							)}
						</Grid>
					</Grid>
				)
			)}
		</>
	);
};

const RangeParameter = <T, >({
	formik,
	index,
	removeParameter,
}: {
	formik: FormikProps<T>;
	index: number;
	removeParameter: Function;
}) => {
	const fields = [
		{
			label: "From",
			path: `params[${index}].values.fromVal`,
		},
		{
			label: "To",
			path: `params[${index}].values.toVal`,
		},
		{
			label: "Step",
			path: `params[${index}].values.step`,
		},
	];
	
	return (
		<Grid item container wrap="nowrap">
			<Grid
				item
				container
				columnGap={0.5}
				flexWrap="nowrap"
				width="100%"
				overflow="auto"
			>
				{fields.map((field, idx) => (
					<Grid item key={idx}>
						{FormikTextField({
							...field,
							formik,
						})}
					</Grid>
				))}
			</Grid>
			<Grid item container>
				<Grid item alignSelf="flex-end">
					<IconButton
						aria-label="Remove this element"
						color="warning"
						onClick={() => removeParameter()}
						size="large"
					>
						<MinusIcon fontSize="inherit" />
					</IconButton>
				</Grid>
			</Grid>
		</Grid>
	);
};

const addRangeParameterFunc = (arrayHelpers: FieldArrayRenderProps) => {
	arrayHelpers.push({
		permutationType: "RANGE",
		parameter: "",
		values: {
			fromVal: "",
			toVal: "",
			step: "",
		},
	});
};

const addListParameterFunc = (arrayHelpers: FieldArrayRenderProps) => {
	arrayHelpers.push({
		permutationType: "LIST",
		parameter: "",
		values: [""],
	});
};

const addParameterButtons = [
	{
		label: "Add list parameter",
		addFunc: addListParameterFunc,
	},
	{
		label: "Add range parameter",
		addFunc: addRangeParameterFunc,
	},
];

const addParameterButton = ({
	label,
	onClick,
	index,
}: {
	label: string;
	onClick: Function;
	index: number;
}) => {
	return (
		<Button
			key={index}
			onClick={() => onClick()}
			startIcon={<AddIcon />}
			variant="contained"
			color="success"
		>
			{label}
		</Button>
	);
};

type ParametersFormProps<T> = {
	formik: FormikProps<T>;
};

const ParametersForm = ({
	formik,
}: ParametersFormProps<CreateBacktestFormValues>): ReactNode => {
	return (
		<>
			<FormikProvider value={formik}>
				<FieldArray
					name="params"
					render={(arrayHelpers) => (
						<Stack spacing={2}>
							<Stack>
								{formik.values.params.map(
									(item, index: number) => (
										<Grid
											container
											key={`params ${index}`}
											columnGap={2}
											flexWrap="nowrap"
										>
											<Grid item>
												{FormikTextField({
													formik,
													label: "Key",
													path: `params[${index}].parameter`,
												})}
											</Grid>
											<Grid
												item
												container
												wrap="nowrap"
												flexDirection="column"
											>
												<FieldArray
													name={`params[${index}].values`}
												>
													{({
														push: pushValue,
														remove: removeValue,
													}) =>
														item.permutationType ===
														"LIST"
															? ListParameter({
																formik,
																item: (item as BacktestParametersValue),
																index,
																pushValue,
																removeValue,
																removeParameter:
																		() =>
																			arrayHelpers.remove(
																				index
																			),
															  })
															: RangeParameter({
																formik,
																index,
																removeParameter:
																		() =>
																			arrayHelpers.remove(
																				index
																			),
															  })
													}
												</FieldArray>
											</Grid>
										</Grid>
									)
								)}
							</Stack>
							<Stack direction="row" spacing={2}>
								{addParameterButtons.map((details, idx) =>
									addParameterButton({
										...details,
										onClick: () =>
											details.addFunc(arrayHelpers),
										index: idx,
									})
								)}
							</Stack>
						</Stack>
					)}
				/>
			</FormikProvider>
		</>
	);
};

export default ParametersForm;
