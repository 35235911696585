import { Box, useTheme } from "@mui/material";

import { PROVIDERS } from "consts";
import { ExternalLink } from "components";
import { DarkLogo, LightLogo } from "assets";

import type { ReactNode } from "react";
import type { ProviderId } from "types";

type ProviderLogoProps = {
	providerId: ProviderId,
};

const ProviderLogo = ({ providerId }: ProviderLogoProps): ReactNode => {
	const theme = useTheme();
	const { url, name } = PROVIDERS[providerId];
	
	return url ? (
		<ExternalLink url={url}>
			<Box component="div" sx={{ display: "flex", alignItems: "center" }}>
				<img
					src={theme.palette.mode === "light" ? LightLogo : DarkLogo}
					alt={`${name} logo`}
				/>
			</Box>
		</ExternalLink>
	) : (
		<img
			src={theme.palette.mode === "light" ? LightLogo : DarkLogo}
			alt={`${name} logo`}
		/>
	);
};

export default ProviderLogo;
