import {
	type ReactNode,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useLocation } from "react-router-dom";
import DocumentIcon from "@mui/icons-material/LibraryBooksOutlined";

import {
	BigCard,
	CreateAndEditInstanceForm,
	InstancesIndexTable,
	Loading,
	Modal,
} from "components";
import {
	useDeleteInstanceMutation,
	useGetInstancesQuery,
	useUpdateInstanceRunStateMutation,
} from "services";
import type {
	ContainerProps,
	Instance,
} from "types";

const InstancesContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	const location = useLocation();
	const scriptId = location.state?.scriptId;
	
	const handleConfirmDeleteInstance = (instance: Instance) => {
		setInstanceToDelete(instance);
		setIsDeleteConfimationOpen(true);
	};
	
	// STATE
	const [isCreating, setIsCreating] = useState(scriptId ? true : false);
	const [editingInstance, setEditingInstance] = useState<Instance | null>(null);
	
	// RTK QUERY
	const { instances, isLoadingInstances } = useGetInstancesQuery(undefined, {
		selectFromResult: ({ data, isLoading }) => ({
			instances: data?.instances ?? [],
			isLoadingInstances: isLoading,
		}),
	});
	
	const [updateRunState] = useUpdateInstanceRunStateMutation();
	const [handleDeleteInstance] = useDeleteInstanceMutation();
	
	// REACT HOOKS
	const toggleEdit = useCallback(() => {
		setEditingInstance(null);
	}, []);
	
	const [isDeleteConfimationOpen, setIsDeleteConfimationOpen] = useState(false);
	const [instanceToDelete, setInstanceToDelete] = useState<Instance | null>(
		null
	);
	
	const toggleCreate = useCallback(() => {
		setIsCreating(!isCreating);
	}, [isCreating]);
	
	useEffect(() => {
		setBreadcrumbs([{ text: "Instances" }]);
		setHeaderActions({
			create: [
				{
					label: "Create new instance",
					onClick: toggleCreate,
					createIcon: true,
				},
			],
		});
	}, [setBreadcrumbs, setHeaderActions, toggleCreate]);
	
	return (
		<>
			{isLoadingInstances && <Loading message="Loading instances..." />}
			{instances.length === 0 && !isLoadingInstances && (
				<BigCard
					ctaLinkText="Create one now"
					handleClick={toggleCreate}
					icon={DocumentIcon}
					message="You don't have any instances."
				/>
			)}
			{instances.length > 0 && !isLoadingInstances && (
				<InstancesIndexTable
					instances={instances}
					handleEditInstance={(inst) => setEditingInstance(inst)}
					handleStartInstance={(inst) =>
						updateRunState({
							instanceId: inst.instanceId,
							action: "start",
						})
					}
					handleStopInstance={(inst) =>
						updateRunState({
							instanceId: inst.instanceId,
							action: "stop",
						})
					}
					handleDeleteInstance={handleConfirmDeleteInstance}
				/>
			)}
			<CreateAndEditInstanceForm
				handleFormClose={editingInstance ? toggleEdit : toggleCreate}
				scriptId={scriptId}
				instance={editingInstance ? editingInstance : undefined}
				show={isCreating || editingInstance !== null}
			/>
			<Modal
				title="Are you sure you want to delete"
				dynamicTitle={`${instanceToDelete?.name}?`}
				handleClose={() => setIsDeleteConfimationOpen(false)}
				onNegative={() =>
					handleDeleteInstance(instanceToDelete?.instanceId ?? "")
						.unwrap()
						.then(() => setIsDeleteConfimationOpen(false))
				}
				negativeButtonText="Delete"
				show={isDeleteConfimationOpen}
				children={<></>}
			/>
		</>
	);
};

export default InstancesContainer;
