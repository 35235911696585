import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import {
	Add as AddIcon,
	LibraryBooks as LibraryBooksIcon,
	Mode as ModeIcon,
} from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";

import { DataTable } from "components";
import { formatDateTime, truncateText } from "utils";
import type { Script } from "types";

type ScriptsIndexTableProps = {
	scripts: Array<Script>;
	handleShowScript: (script: Script) => void;
};

const ScriptsIndexTable = ({
	scripts,
	handleShowScript,
}: ScriptsIndexTableProps): ReactNode => {
	const navigate = useNavigate();
	
	return (
		<DataTable
			getRowId={(row: Script) => row.scriptId}
			columns={[
				{
					field: "name",
					headerName: "Name",
					flex: 5,
				},
				{
					field: "lastEditedAt",
					headerName: "Last edited",
					type: "dateTime",
					valueFormatter: ({ value }: { value: number }) =>
						formatDateTime(value),
					valueGetter: ({
						value,
					}: {
						value: Script["lastEditedAt"];
					}) => value && new Date(value * 1000),
					flex: 2,
				},
				{
					field: "description",
					headerName: "Info",
					valueFormatter: ({
						value,
					}: {
						value: Script["description"];
					}) => truncateText(value, 40),
					flex: 3,
				},
				{
					field: "actions",
					type: "actions",
					getActions: ({ row }: { row: Script }) => [
						<GridActionsCellItem
							icon={<AddIcon />}
							onClick={() =>
								navigate(`/instances`, {
									state: { scriptId: row.scriptId },
								})
							}
							label="Create a new instance"
							showInMenu
						/>,
						<GridActionsCellItem
							icon={<ModeIcon />}
							onClick={() =>
								navigate(`/scripts/${row.scriptId}/edit`)
							}
							label="Edit draft"
							showInMenu
						/>,
						<GridActionsCellItem
							icon={<LibraryBooksIcon />}
							onClick={() => handleShowScript(row)}
							label="Show version history"
							showInMenu
						/>,
					],
				},
			]}
			rows={scripts}
		/>
	);
};

export default ScriptsIndexTable;
