import { HTMLAttributeAnchorTarget, ReactNode } from "react";

type ExternalLinkProps = {
	children?: ReactNode | React.ReactNode[];
	newTab: boolean;
	url: string;
};

const defaultProps = {
	newTab: true,
};

const ExternalLink = ({
	children,
	newTab,
	url,
}: ExternalLinkProps): ReactNode => {
	const rel = `nofollow${newTab ? " noopener noreferrer" : ""}`;
	const target: HTMLAttributeAnchorTarget | undefined = newTab
		? "_blank"
		: undefined;
	
	return (
		<a className="external-link" href={url} rel={rel} target={target}>
			{children}
		</a>
	);
};

ExternalLink.defaultProps = defaultProps;

export default ExternalLink;
