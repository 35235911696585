import { type ReactNode, useMemo, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { Alert, KeyValuePairForm } from "components";

import type { KeyValuePair, RunParams } from "types";

/*
	Params on the script are maintained by the parent container -
	they are received from API along with rest of script data as
	an object.
	
	This component tracks user-defined key-value pairs added by
	way of the KeyValuePairForm, and is also responsible for
	validating them (ensuring no duplicated keys	etc) before
	it will allow the script to be run
*/
type KeyErrors = {
	key: string;
};

type ScriptRunnerType = {
	isExecuting: boolean;
	isWaiting: boolean;
	runParams: RunParams;
	start: () => void;
	stop: () => void;
	updateRunParams: (runParams: RunParams) => void;
	codeChanges: boolean;
};

const ScriptRunner = ({
	isExecuting,
	isWaiting,
	runParams,
	start,
	stop,
	updateRunParams,
	codeChanges,
}: ScriptRunnerType): ReactNode => {
	const [pairErrors, setPairErrors] = useState<Array<KeyErrors | {}>>([]);
	const [showFormError, setShowFormError] = useState(false);
	
	const handleRunClicked = () => {
		const isValid = handleValidatePairs();
		
		setShowFormError(!isValid);
		
		if (isValid) {
			start();
		}
	};
	
	const handleUpdatePairs = (p: Array<KeyValuePair>): void => {
		const paramsObj = p.reduce((prev, { key, value }) => {
			prev[key as keyof KeyValuePair] = value;
			
			return prev;
		}, {} as KeyValuePair);
		
		updateRunParams(paramsObj);
	};
	
	const handleValidatePairs = (): boolean => {
		let isValid = true;
		const uniqueKeys: string[] = [];
		const errors: Array<KeyErrors | {}> = pairs.map(({ key, value }) => {
			if (key === "") {
				isValid = false;
				
				return { key: "Key is required" };
			}
			if (uniqueKeys.indexOf(key) !== -1) {
				isValid = false;
				
				return { key: "Duplicate key" };
			}
			uniqueKeys.push(key);
			
			return {};
		});
		
		setPairErrors(errors);
		
		return isValid;
	};
	
	const pairs = useMemo(() => {
		return Object.keys(runParams).map(
			(key) => {
				return { key, value: runParams[key] };
			}
		);
	}, [runParams]);
	
	return (
		<Stack alignItems="center" sx={{ height: "100%", py: 4, pl: 4, pr: 2 }}>
			<Typography variant="h1" sx={{ alignSelf: "flex-start", mb: 3 }}>
				Test run parameters
			</Typography>
			{showFormError && (
				<Alert>Please correct errors in your script parameters</Alert>
			)}
			<KeyValuePairForm
				errors={pairErrors}
				pairs={pairs}
				onUpdatePairs={handleUpdatePairs}
			/>
			<Grid
				container
				justifyContent="flex-end"
				alignItems="center"
				pt={2}
				pr={2}
			>
				<Grid item>
					{isExecuting ? (
						<Button
							color="warning"
							onClick={stop}
							variant="contained"
						>
							Stop
						</Button>
					) : (
						<LoadingButton
							color="success"
							onClick={handleRunClicked}
							variant="contained"
							loading={isWaiting}
							disabled={!codeChanges}
						>
							Save &amp; Run
						</LoadingButton>
					)}
				</Grid>
			</Grid>
		</Stack>
	);
};

export default ScriptRunner;
