import { MutableRefObject, RefObject } from "react";
import { checkOverlap, getDayTimestamp } from "utils";
import type { OrbitControls as OrbitControlsImpl } from "three-stdlib";
import type { AggLevelType, RunEvent } from ".";

export type ChartScale = {
	x: number,
	y: number,
	z: number,
};

export type ChartRotation = {
	x: number,
	y: number,
	z: number,
};

export type ChartPoint = {
	x: number,
	y: number,
	z?: number,
	[key: string]: number | undefined,
};

export type ChartAxis = {
	enabled: boolean,
	timestamps: boolean,
	label?: string,
};

export interface ChartCommandStyle {
	color?: string,
	opacity?: number,
	borderColor?: string,
	borderWidth?: number,
	backgroundImage?: string,
	backgroundGradient?: string,
	lineGradient?: string,
};

export interface ChartAnnotationStyle extends ChartCommandStyle {
	font?: string,
	fontSize?: number,
};

export type ChartLineStyleType = "SOLID" | "DASHED" | "DOTTED"

export interface ChartLineStyle extends ChartCommandStyle {
	lineWidth?: number,
	lineType?: ChartLineStyleType,
};

export interface CandleStyle extends ChartCommandStyle {
	negativeCandleColor: string, // Candle specific
	positiveCandleColor: string, // Candle specific
	wickWidthScale: number, // Candles specific
};

// A ChartPos set of references needed to render the chart
export type ChartPos = {
	height: number,                    // General metrics re the chart
	width: number,
	vWidth: number,                    // The viewport width to get where we are in world space
	x: number,
	y: number,
	zoom: number,
	mints: number,                     // Stores the x axis bounds of the data we can request
	maxts: number,
	minval: number,                    // Stores the y axis bounds of the data we can request
	maxval: number,
	minX: number,                      // Defines the x axis window that is being viewed
	maxX: number,
	minXOffset?: number,               // Offsets values for the min/max values on the x axis
	maxXOffset?: number,
	minY: number,                      // Defines the y axis window that is being viewed
	maxY: number,
	low: number,                       // Min/max volumes trade values - used in the volumes display
	maxvol: number,
	windows: { [key:string]: number }, // The number of "windows" within the data that can be viewed
	timespan: number,                  // The reference timestamp that will trigger a load
	fetcher?: Fetcher,                 // An RTK query based trigger that will get data based on min/max timestamps
	offsetDict?: TimescaleOffsetDict,  // A lookup to workout chart object offsets
	timescale?: Timescale[],
	aggLevel?: AggLevelType,
};

// Common

export type ScaleFactor = {
	translateX: number,
	translateY: number,
	scaleX: number,
	scaleY: number,
};

export interface MousePos extends MouseEvent {
	layerX: number,
	layerY: number,
};

export type FetchedData = {
	data: Array<RunEvent>,
	minY: number,
	maxY: number,
	minLow: number,
	maxVol: number,
};

export type Fetcher = (mints: number, maxts: number) => Promise<FetchedData>;

// Timeline

// Represents visible x positions within a min/max timestamp value that
// can then be used to link to offsets for those dates depending on,
// for example the current position of the camera.
export type Timescale = {
	start: number;
	end: number;
	offset: number;
	openTs: number;
	endTs: number;
};

// Represents a quick lookup for a "day" that can be used to work out the
// offsets for chart objects - if we look up the day that the object is
// on, we can then work out how much to offset it by.
export type TimescaleOffsetDict = {
	[key: string]: number;
};

/*
	Chart Object Types
*/

export type ChartCommandType = "DRAW_OBJ";

export type ChartObjectType =
	| "BOX"
	| "CANDLE"
	| "ANNOTATION"
	| "POINT"
	| "SYMBOL"
	| "LINE"
	| "POLYGON"
	| "DOT";

export interface ScaledObject {
	scaleFactor: ScaleFactor,
	chartPos?: ChartPos,
};

type MinMaxYTuple = [number, number] | void;

export interface ChartObject {
	objtype: ChartObjectType,
	objId: string,
	dtFrom(): number,
	dtTo(): number,
	day(): number,
	adjustDates(offset: number): void,
	calcObjBounds(minX: number, maxX: number): MinMaxYTuple,
};

export type ChartCmd = {
	cmdtype: ChartCommandType,
	obj: ChartCmdObj,
};

export type ChartCmdObj =
	| ChartCandleObject
	| ChartAnnotationObject
	| ChartBoxObject
	| ChartLineObject
	| ChartPolygonObject
	| ChartSymbolObject
	| ChartDotObject;

export type ChartPointBasedObj =
	| ChartAnnotationObject
	| ChartSymbolObject
	| ChartDotObject;

export interface ChartAnnotationObject extends ChartObject {
	text: string,
	position: ChartPoint,
	style?: ChartAnnotationStyle,
	rotation?: ChartRotation,
	zoom?: number,
};

export interface ChartDotObject extends ChartObject {
	size: number,
	position: ChartPoint,
	style: ChartCommandStyle,
};

export interface ChartBoxObject extends ChartObject {
	height: number,
	width: number,
	depth: number,
	position: ChartPoint,
	scale?: ChartScale,
	rotation?: ChartRotation,
	style: ChartCommandStyle,
	startPoint: ChartPoint,
};

export interface ChartLineObject extends ChartObject {
	points: Array<ChartPoint>,         // Fixed set of points added for a line by a users script
	append_points?: Array<ChartPoint>, // Allows points to be added to a line based on it's ID
	style: ChartLineStyle,
	getPoints(): Array<ChartPoint>,    // Accessor for getting all the offset points
};

export interface ChartPolygonObject extends ChartObject {
	position: ChartPoint,
	scale: ChartScale,
	rotation: ChartRotation,
	points: Array<ChartPoint>,      // Points set by the user in the event
	style: ChartCommandStyle,
	getPoints(): Array<ChartPoint>, // Accessor for getting all the offset points
};

export interface ChartSymbolObject extends ChartObject {
	position: ChartPoint,
	symbol: string, // A Font Awesome glyph
	style: ChartCommandStyle,
};

export interface ChartSvgObject extends ChartObject {
	position: ChartPoint,
	svg: string, // Either an url or raw SVG string
	scale: number,
	style: ChartCommandStyle,
};

export type Candle = {
	symbol: string;
	agg_level: string;
	o: number;
	h: number;
	l: number;
	c: number;
	v: number;
	vw: number;
	dt_from: string;
	dt_to: string;
	n: number;
	synthetic: boolean;
	dt_from_ms: number;
	dt_to_ms: number;
};

export interface ChartCandleObject extends ChartObject {
	candle: Candle,
	aggLevel: AggLevelType;
};

/* Camera */

export type ChartCameraProps = {
	camRef: MutableRefObject<any>,
	chartPos: MutableRefObject<ChartPos>,
	orbitalRef: RefObject<OrbitControlsImpl>,
};

/* Gridlines */

export type GridLinesProps = {
	maxXSteps: number,
	maxYSteps: number,
	chartPos:  MutableRefObject<ChartPos>,
};

/* Significant Points */

export type SigPointsInput = {
	minV: number,
	maxV: number,
	maxDivisions: number,
};

export type CalcBoundsInput = {
	cmds: Array<ChartCmd>,
	lowerX?: number | null,
	upperX?: number | null,
};

export type ChartData = {
	eventType: string,
	timestamp: number,
	params: { chartcmd: ChartCmd },
	checked(): void,
	isChecked(): boolean,
}

// Factories for generating a ChartData type - always use this rather
// than typing the JSON data and using it directly from RTK query.
export const CreateChartData = (event: RunEvent): ChartData => {
	const chartcmd = event.params?.chartcmd
		? createChartCmd(event.params.chartcmd)
		: {} as ChartCmd;
	
	let checked = false;
	
	return {
		eventType: event.eventType,
		timestamp: event.timestamp,
		params: { chartcmd },
		checked: (): void => { checked = true; },
		isChecked: (): boolean => checked,
	} as ChartData;
};

// Chooses what factory type we need to generate.
const createChartCmd = (cmd: ChartCmd): ChartCmd => {
	let obj: ChartObject = cmd.obj;
	
	switch (cmd.obj.objtype) {
		case "ANNOTATION":
		case "DOT":
		case "SYMBOL":
			obj = createChartPointBasedObject(obj);
			break;
		case "BOX":
			obj = createChartBoxObject(obj);
			break;
		case "CANDLE":
			obj = createChartCandleObject(obj);
			break;
		case "LINE":
			obj = createChartLineObject(obj);
			break;
		case "POLYGON":
			obj = createChartPolygonObject(obj);
			break;
	};
	
	return {
		cmdtype: cmd.cmdtype,
		obj,
	} as ChartCmd;
};

// Factory to generate a ChartAnnotationObject.
const createChartPointBasedObject = (cmdObj: ChartObject): ChartPointBasedObj => {
	const { position: { x, y } } = cmdObj as ChartPointBasedObj;
	
	let to = x;
	
	return {
		...cmdObj,
		dtTo: (): number => to,
		day: (): number => getDayTimestamp(x),
		adjustDates: (offset: number): void => {
			to += offset;
		},
		calcObjBounds: (minX: number, maxX: number): MinMaxYTuple => {
			if (x < minX || x > maxX) return;
			
			return [y, y];
		},
	} as ChartPointBasedObj;
};

// Factory to generate a ChartBoxObject.
const createChartBoxObject = (cmdObj: ChartObject): ChartBoxObject => {
	const { startPoint: { x, y } } = cmdObj as ChartBoxObject;
	
	let to = x;
	
	return {
		...cmdObj,
		dtTo: (): number => to,
		day: (): number => getDayTimestamp(x),
		adjustDates: (offset: number): void => {
			to += offset;
		},
		calcObjBounds: (minX: number, maxX: number): MinMaxYTuple => {
			if (x < minX || x > maxX) return;
			
			// Is this right, how do we get the actual "value" of
			// what the hight and pos represents?
			
			return [y, y];
		},
	} as ChartBoxObject;
};

// Factory to generate a ChartCandleObject.
const createChartCandleObject = (cmdObj: ChartObject): ChartCandleObject => {
	const {
		dt_from_ms: dtFrom,
		dt_to_ms: dtTo,
		agg_level: aggLevel,
		o, c, h, l,
	} = (cmdObj as ChartCandleObject).candle;
	
	const from = dtFrom / 1000;
	const to =  dtTo / 1000;
	
	let offsetFrom = from;
	let offsetTo = to;
	
	return {
		...cmdObj,
		aggLevel: aggLevel as AggLevelType,
		dtFrom: (): number => offsetFrom,
		dtTo: (): number => offsetTo,
		day: (): number => getDayTimestamp(from),
		adjustDates: (offset: number): void => {
			offsetFrom += offset;
			offsetTo += offset;
		},
		calcObjBounds: (minX: number, maxX: number): MinMaxYTuple => {
			if (!checkOverlap(from, to, minX, maxX)) {
				return;
			}
			
			return [Math.min(o, c, l), Math.max(o, c, h)];
		},
	} as ChartCandleObject;
};

// Factory to generate a ChartLineObject.
const createChartLineObject = (cmdObj: ChartObject): ChartLineObject => {
	const { points, append_points: append } = cmdObj as ChartLineObject;
	
	const linePoints = [...(points ?? append ?? [])] as ChartPoint[];
	const to = reduceXPoints(linePoints);
	let offsetPoints = [...linePoints];
	
	return {
		...cmdObj,
		dtTo: (): number => to,
		day: (): number => getDayTimestamp(to),
		adjustDates: (offset: number): void => {
			offsetPoints = offsetPoints.map((p) => ({ ...p, x: p.x + offset }));
		},
		getPoints: (): Array<ChartPoint> => offsetPoints,
		calcObjBounds: (minX: number, maxX: number): MinMaxYTuple => {
			if (to < minX || to > maxX) return;
			
			return reduceYPoints(linePoints);
		},
	} as ChartLineObject;
};

// Factory to generate a ChartPolygonObject.
const createChartPolygonObject = (cmdObj: ChartObject): ChartPolygonObject => {
	const { points } = cmdObj as ChartPolygonObject;
	
	const to = reduceXPoints(points);
	let offsetPoints = [...points];
	
	return {
		...cmdObj,
		dtTo: (): number => to,
		day: (): number => getDayTimestamp(to),
		adjustDates: (offset: number): void => {
			offsetPoints = offsetPoints.map((p) => ({ ...p, x: p.x + offset }));
		},
		getPoints: (): Array<ChartPoint> => offsetPoints,
		calcObjBounds: (minX: number, maxX: number): MinMaxYTuple => {
			if (to < minX || to > maxX) return;
			
			return reduceYPoints(points);
		},
	} as ChartPolygonObject;
};

// Reduce the x point to a single max value from an array of
// chart points.
const reduceXPoints = (points: ChartPoint[]): number =>
	points.reduce((acc, point: ChartPoint) => {
		const { x } = point;
		
		return Math.max(acc, x);
	}, 0);

// Gets the min/max Y values of an array of chart points.
const reduceYPoints = (points: ChartPoint[]): MinMaxYTuple => {
	let minY = points[0].y || 0;
	let maxY = points[0].y || 0;
	
	return points.reduce((acc, point: ChartPoint) => {
		const { y } = point;
		const [minY, maxY] = acc ?? [];
		
		if (minY === undefined || maxY === undefined) return acc;
		
		return [Math.min(minY, y), Math.max(maxY, y)];
	}, [minY, maxY] as MinMaxYTuple);
};
