import { AggLevelType } from "types";
import { calcX } from ".";
import type { MutableRefObject } from "react";
import type {
	ChartData,
	ChartPos,
	MarketHour,
	ScaleFactor,
	Timescale,
	TimescaleOffsetDict,
} from "types";

// This file contains a set of timeline analysis files that generate and perform
// moves on the x axis according to a series of "gaps" in the data that we may want
// to remove - for example, in the case of trading data, on an x axis that represents
// time, because trading time is short, charts would show more "gap" than actual data
// so it is advantageous to remove these gaps to aid analysis.
//
// The functions are:
//   - updateTimelineOffsets
//     Use to perform offsetting on the x axis that data as it comes into the
//     ChartContainer component.
//
//   - generateTimescaleOffsetDict
//     Takes the market hours data from the API, and generates an offset value
//     dictionary look up for that day.
//
//   - generateTimescale
//     Creates a look up for the x axis world values that will enable the setting
//     of an offset - e.g. for axis display, or the x axis offsets for the LHS/RHS
//     bounds of the chart.
//
//   - setXAxisOffsets
//     Helper to be used in the camera, and main ChartContainer to setup on load
//     any offsets that needs to be set on the chartpos object.

// Checks each of the chart objects once, and offsets it's x pos based on the
// the value set in the offsetDict.
export const updateTimelineOffsets = (
	data: ChartData[],
	chartPos: MutableRefObject<ChartPos>
): ChartData[] => {
	const { offsetDict } = chartPos.current;
	
	if (!offsetDict) return data;
	
	// We work backwards through the chart object - this seemed like the
	// the right idea, in-as-much as when we change "day", we can look back
	// at what the last day was, and offset the points that are "before" this
	// one.
	//
	// Of course, this means that the points need to be added in the correct
	// order at least - which will need to be done by the ChartContainer.
	for (let i = data.length - 1; i >= 0; i--) {
		const datam = data[i];
		
		// If we have already checked this object, then there is no need to
		// check it again. If we never get market hours for these dates, then
		// we have to skip any checks as we have no way of determining if
		// the the "day" of the object has changed, and therefore there is no
		// possible way to work out an offset.
		if (datam.isChecked()) continue;
		
		const obj = datam.params.chartcmd.obj;
		
		// Check if there is any offset for the "day" that this object is
		// on that is set in the timeline analysis - if so, we can then offset
		// the value - this will only happen once when the object is
		// appended to the main char object array.
		obj.adjustDates(offsetDict[obj.day()] ?? 0);
		datam.checked();
	}
	
	return data;
};

// Tries to generate a lookup for the chart objects in updateTimelineOffsets,
// making it super trivial to generate an offset that can be used to offset
// the object when we're trying to remove gaps from data.
//
// You will notice here that we are checking the offset for each agg level, as
// we don't want to remove gaps that are the same size as that aggregate.
export const generateTimescaleOffsetDict = (
	marketHours: MarketHour[],
	chartPos: MutableRefObject<ChartPos>
): TimescaleOffsetDict => {
	let offsetDict: TimescaleOffsetDict = {};
	
	const { aggLevel } = chartPos.current;
	
	if (!aggLevel) return offsetDict;
	
	// This is attempting to have a unified approach that makes
	// is possible to set an offset depending on agg level we're
	// given, which by default will be one second - up to a
	// full day.
	let offset = 0;
	let factor = 1;
	
	switch (aggLevel) {
		case AggLevelType.MIN:
			factor = 60;
			break;
		case AggLevelType.HOUR:
			factor = 60 * 60;
			break;
		case AggLevelType.DAY:
			factor = 24 * 60 * 60;
			break;
	}
	
	for (let i = marketHours.length - 1; i >= 0 ?? 0; i--) {
		const { dayTs, endTs } = marketHours[i];
		const openTs = marketHours[i + 1]?.openTs ?? endTs;
		
		// Is the diff between the market hours is greater than the
		// resolution? We need to set an offset - so for example, a
		// weekend for "days" will get shifted, but between days will
		// not - but they will for minutes.
		if (openTs - endTs > factor) {
			offset += Math.floor((openTs - endTs) / factor) * factor;
		}
		
		offsetDict[dayTs] = offset;
	}
	
	return offsetDict;
};

// If we have a timescale here, then we are able to work out what, in
// world space the actual min/max X values are as we are try to remove
// any gaps in the data that may be there due to time series not being
// contiguous. This allows us to calculate alternative min/max offset
// values.
export const setXAxisOffsets = (chartPos: MutableRefObject<ChartPos>): void => {
	const { timescale, aggLevel, x, vWidth, minX, maxX } = chartPos.current;
	
	if (!timescale || !vWidth || !aggLevel) return;
	
	const visibleStart = x - vWidth / 2; // LHS of the chart
	const visibleEnd = x + vWidth / 2;   // RHS of the chart
	
	const startDay = timescale.find(
		(ts) => ts.start <= visibleStart && ts.end >= visibleStart
	);
	const endDay = timescale.find(
		(ts) => ts.start <= visibleEnd && ts.end >= visibleEnd
	);
	
	chartPos.current.minXOffset = minX - (startDay?.offset || 0);
	chartPos.current.maxXOffset = maxX - (endDay?.offset || 0);
};

// Generates a timeline scale that can then be used by components
// to check of offsets in world space - so it uses the x value
// calculated by calcX to generate a lookup that can be used to
// match the mouse pos as the user browses, or the chart bounds
// as in the case of setXAxisOffsets.
export const generateTimescale = (
	marketHours: MarketHour[],
	scaleFactor: ScaleFactor,
	chartPos: MutableRefObject<ChartPos>
): Timescale[] => {
	const { offsetDict, aggLevel } = chartPos.current;
	
	if (!scaleFactor || !offsetDict || !aggLevel) return [];
	
	return marketHours.reduce((acc, { dayTs, openTs, endTs }) => {
		const offset = offsetDict[dayTs];
		
		// This calculates what the open and closing times would be if
		// plotted on the chart.
		let start = calcX(openTs + offset, scaleFactor);
		let end = calcX(endTs + offset, scaleFactor);
		
		// When we have days, we have to change the what we consider
		// the "day" for calculations, because an aggregate in the DB for a
		// day goes between 04:00 to 04:00 on that days. The assumption
		// is that this is to cover out of hours trading.
		if (aggLevel === AggLevelType.DAY) {
			openTs = dayTs - 20 * 60 * 60;
			endTs = dayTs + 4 * 60 * 60;
			start = calcX(openTs + offset, scaleFactor);
			end = calcX(endTs + offset, scaleFactor);
		}
		
		acc.push({
			start,
			end,
			offset,
			openTs,
			endTs,
		});
		
		return acc;
	}, [] as Timescale[]);
};

// This will try and work out a nice windowing approach - based on matching
// a relationship between the total duration and some common window views
export const calculateTimespan = (
	startTimestamp: number,
	endTimestamp: number
): number => {
	if (startTimestamp >= endTimestamp) {
		throw new Error("Start timestamp must be before end timestamp");
	}
	
	const duration = endTimestamp - startTimestamp;
	
	// Common time intervals in seconds
	const windowSizes = [
		1, 5, 15, 30,               // seconds
		60, 300, 900, 1800,         // minutes
		3600, 7200, 21600, 43200,   // hours
		86400, 604800,              // days/weeks
		2592000, 7776000, 31536000, // months/years
	];
	
	// Find the largest window size that divides duration into at least 8-10
	// segments, which is a massive guess for now. This ensures we get enough
	// detail while still keeping time windows sensible.
	const targetMinSegments = 10;
	
	for (let i = windowSizes.length - 1; i >= 0; i--) {
		const segments = duration / windowSizes[i];
		
		if (segments >= targetMinSegments) {
			return windowSizes[i];
		}
	}
	
	return windowSizes[0]; // Fallback to smallest window
};