import type { MutableRefObject } from "react";
import type { ChartData, ChartPos, ScaleFactor } from "types";

export const EmptyChartPos: ChartPos = {
	height: 0,
	width: 0,
	vWidth: 0,
	x: 0,
	y: 0,
	zoom: 0,
	mints: 0,
	maxts: 0,
	minval: 0,
	maxval: 0,
	minX: 0,
	maxX: 0,
	minY: 0,
	maxY: 0,
	low: 0,
	maxvol: 0,
	windows: {},
	timespan: 0,
};

// Helpful functions for calculating positions in space from
// both values and timestamps.
export const calcX = (x: number, scaleFactor: ScaleFactor) =>
	scaleFactor.translateX + scaleFactor.scaleX * x;
export const calcY = (y: number, scaleFactor: ScaleFactor) =>
	scaleFactor.translateY + scaleFactor.scaleY * y;

export const invertX = (x: number, scaleFactor: ScaleFactor) =>
	(x - scaleFactor.translateX) / scaleFactor.scaleX;
export const invertY = (y: number, scaleFactor: ScaleFactor) =>
	(y - scaleFactor.translateY) / scaleFactor.scaleY;

// Calculates the main normalising properties to plot points
// in within the chart environment.
export const calculateScaleFactor = (chartPos: MutableRefObject<ChartPos>): ScaleFactor | void => {
	if (!chartPos) return;
	
	const { x, width, height, zoom, minX, maxX, minY, maxY } = chartPos.current;
	
	if (!width || !height || minY === null || maxY === null) return;
	
	const scaleX = width / (maxX - minX) / zoom;
	const translateX = -((maxX + minX) / 2) * scaleX + x;
	
	const scaleY = (height / zoom) / (maxY - minY);
	const translateY = (-(minY + maxY) * scaleY) / 2;
	
	return {
		scaleX,
		translateX,
		scaleY,
		translateY,
	};
};

// This should allow us to recalculate the scaleFactor from what
// the user is currently look at, at the moment - i.e. the visible
// window currently in view.
export const updateScaleFactorFromFromVisibleData = (
	cmds: ChartData[],
	chartPos: MutableRefObject<ChartPos>
): ScaleFactor | void => {
	if (!chartPos || cmds.length === 0) return;
	
	const { minX, minXOffset, maxX, maxXOffset } = chartPos.current;
	
	const minV = minXOffset || minX;
	const maxV = maxXOffset || maxX;
	
	const [minY, maxY] = cmds.reduce(
		(acc, cmd) => {
			const obj = cmd.params.chartcmd.obj;
			
			// We have to include the min/max X offset here to ensure that we
			// get all the objects that we can see.
			const [objMinY, objMaxY] = obj.calcObjBounds(minV, maxV) ?? [];
			
			if (objMinY === undefined || objMaxY === undefined) return acc;
			
			const [minY, maxY] = acc;
			
			return [
				minY === 0 ? objMinY : Math.min(minY, objMinY),
				maxY === 0 ? objMaxY : Math.max(maxY, objMaxY),
			];
		},
		[0, 0] as [number, number]
	);
	
	if (minY === 0 && maxY === 0) return;
	
	// If we have updated min/max Y values, then we can recalculate the
	// scaleFactor.
	chartPos.current.minY = minY;
	chartPos.current.maxY = maxY;
	
	return calculateScaleFactor(chartPos);
};

